import { store } from "@seval-portal/client-store";
import type {
  ClientFeatureFlag,
  ClientFeatureFlagSets,
} from "@seval-portal/shared";
import { mutatorAction } from "satcheljs";

export const updateIsFeatureEnabled = mutatorAction(
  "updateIsFeatureEnabled",
  (feature: ClientFeatureFlag, isEnabled: boolean) => {
    store.features.overrides = {
      ...store.features.overrides,
      [feature]: isEnabled,
    };
  },
);

export const resetFeatureFlagOverrides = mutatorAction(
  "resetFeatureFlagOverrides",
  () => {
    store.features.overrides = {};
  },
);

export const updateFeatureFlagFlights = mutatorAction(
  "updateFeatureFlagFlights",
  (flights: ClientFeatureFlagSets) => {
    store.features.flights = flights;
  },
);
