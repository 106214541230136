import {
  Field,
  InfoLabel,
  Input,
  makeStyles,
  Persona,
  shorthands,
  Switch,
  Text,
  tokens,
} from "@fluentui/react-components";
import { Search16Regular } from "@fluentui/react-icons";
import { perfWrapper } from "@seval-portal/client-utils";
import type { CachedConversationSearchRequestData } from "@seval-portal/shared";
import { CompeteSydneyEngine } from "@seval-portal/shared";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { getCachedConversation } from "../../../../../helpers/apiHelper";
import { PaginationTable } from "../../../../ShadowAB/components/common/PaginationTable";
import { updatePropValueActionV2 } from "../../../actions/jobActions";
import type { CachedConversation } from "../../../models/CachedConversation";
import { getValue } from "../../../selectors/getJobPropV2";

const useStyles = makeStyles({
  block: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    width: "100%",
    ...shorthands.gap("8px"),
  },
  blockTitle: {
    display: "flex",
    alignItems: "center",
    fontFamily: tokens.fontFamilyBase,
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  dropdownMenu: {
    backgroundColor: "#F8F7F9",
  },
  listbox: {
    maxHeight: "300px",
  },
  input: {
    width: "355px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: "16px",
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  list: {
    marginTop: "24px",
    paddingBottom: "24px",
    overflowX: "auto",
  },
  tableContainer: {
    backgroundColor: "#fff",
    borderRadius: tokens.borderRadiusMedium,
    minHeight: "257px",
    width: "100%",
  },
  truncateContent: {
    width: "100%",
    overflow: "hidden",
  },
});

export const CachedConversationTableSelector = observer(
  (props: {
    selected?: CachedConversation;
    querySet?: string;
    onChange: (cache?: CachedConversation) => void;
  }) => {
    const styles = useStyles();
    const { selected: cachedConversation, querySet, onChange } = props;
    const [enableAA, setEnableAA] = React.useState<boolean>(true);
    const [results, setResults] = React.useState<CachedConversation[]>([]);
    const [listLoading, setListLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(5);
    const [totalNum, setTotalNum] = React.useState(0);
    const [showSearchName, setShowSearchName] = React.useState("");
    const [searchName, setSearchName] = React.useState("");

    const handlerAAEvaluationChange = (enabled: boolean) => {
      updatePropValueActionV2({
        prop: `enable_aa_evaluation`,
        newData: enabled,
      });
      const expConfigs = getValue(`compete.exp_configs`) ?? [];
      const cacheResultExpIndex = expConfigs.findIndex(
        (v) => v.exp_name === "cache_result",
      );
      updatePropValueActionV2({
        prop: `compete.exp_configs[${cacheResultExpIndex}].scraping_result`,
        newData: enabled ? undefined : "latest",
      });
      if (!enabled) {
        onChange(undefined);
        setShowSearchName("");
        setSearchName("");
      }
    };

    const loadResults = React.useCallback(() => {
      const params: CachedConversationSearchRequestData = {
        ActivationStatus: "Activated",
        ScraperService: "gpt",
        pageSize: String(pageSize),
        pageNumber: String(page),
      };
      if (searchName !== "") {
        params.DisplayName = searchName;
      }
      if (querySet) {
        setListLoading(true);
        params.QuerySet = querySet;
        perfWrapper(
          "LoadCachedConversationList",
          getCachedConversation(params)
            .then((data) => {
              setListLoading(false);
              setResults(data.results);
              setTotalNum(data.totalCount);
            })
            .catch(() => {
              setListLoading(false);
              setResults([]);
              setTotalNum(0);
            }),
        );
      }
    }, [querySet, searchName, page, pageSize]);

    React.useEffect(() => {
      if (enableAA) {
        loadResults();
      }
    }, [loadResults, enableAA]);

    React.useEffect(() => {
      // Default set job setting value to false
      handlerAAEvaluationChange(true);
    }, []);

    const columns = React.useMemo(
      () => [
        {
          key: "DisplayName",
          title: "Cached conversation name",
          minWidth: 200,
          width: 240,
          render: (data: CachedConversation) => (
            <Text
              className={styles.truncateContent}
              truncate={true}
              title={data.DisplayName}
              wrap={false}
              block={true}
            >
              {data.DisplayName}
            </Text>
          ),
        },
        {
          key: "Config",
          title: "Model",
          minWidth: 160,
          width: 180,
          render: (data: CachedConversation) => {
            try {
              if (!data.Config) {
                return "";
              }
              const config = CompeteSydneyEngine(
                JSON.parse(data.Config),
                "competeExp",
              );
              return config?.gpt?.model ?? "";
            } catch (error) {
              return <></>;
            }
          },
        },
        {
          key: "CreatedAt",
          title: "Created At",
          minWidth: 160,
          width: 180,
          render: (data: CachedConversation) =>
            new Date(data.CreatedAt).toLocaleString(),
        },
        {
          key: "CreatedBy",
          title: "Created By",
          minWidth: 160,
          width: 180,
          render: (data: CachedConversation) => (
            <Persona
              textAlignment="center"
              name={data.CreatedBy}
              size="small"
            />
          ),
        },
      ],
      [styles],
    );

    return (
      <div className={styles.block}>
        <InfoLabel
          className={styles.blockTitle}
          info={
            "The AA evaluation will compare the newly scraped results with the previously validated ones to ensure the new cache is suitable for use"
          }
        >
          <Switch
            label="AA evaluation"
            checked={enableAA}
            disabled={!querySet}
            onChange={(e) => {
              setEnableAA(e.target.checked);
              handlerAAEvaluationChange(e.target.checked);
            }}
          />
        </InfoLabel>
        {enableAA && (
          <>
            <div>
              Choose a previously validated cached conversation to compare
              against the newly scraped results.
            </div>
            <Input
              placeholder="Search a conversation name"
              className={styles.input}
              value={showSearchName}
              onChange={(_e, data) => {
                setShowSearchName(data.value);
              }}
              onKeyDown={(ev) => {
                if (ev.key === "Enter") {
                  setSearchName(showSearchName);
                  setPage(1);
                }
              }}
              contentAfter={<Search16Regular />}
            />
            <div className={styles.list}>
              <PaginationTable<CachedConversation>
                data-testid="cached-conversation-table"
                header={columns}
                data={results}
                keyName="ID"
                loading={listLoading}
                pagination={{
                  pageSize,
                  totalNum,
                  page,
                }}
                options={{
                  selection: true,
                  innerBorder: true,
                  containerStyle: styles.tableContainer,
                }}
                selectionKeys={[String(cachedConversation?.ID)]}
                onRowClick={(row) => {
                  onChange(row);
                }}
                onPageChange={setPage}
                onPageSizeChange={(newPageSize: number) => {
                  setPage(1);
                  setPageSize(newPageSize);
                }}
              />
              {!cachedConversation && (
                <Field
                  validationState="error"
                  validationMessage="Should select a cached conversation when enabled AA evaluation."
                ></Field>
              )}
            </div>
          </>
        )}
      </div>
    );
  },
);
