import type { JobTemplate } from "@seval-portal/client-models";

import type {
  EvaluationType,
  JobConfigurationDeleteAction,
  JobConfigurationReplaceAction,
  JobConfigurationUpdateAction,
} from "@seval-portal/client-models";
import { action } from "satcheljs";

export const updateJobNameAction = action(
  "updateJobNameAction",
  (newName: string) => ({
    newName,
  }),
);

export const updateProductFilterAction = action(
  "updateProductFilterAction",
  (productId: string) => ({ productId }),
);

export const updateSelectedTemplateAction = action(
  "updateSelectedTemplateAction",
  (newTemplate: JobTemplate, isLimited?: boolean) => ({
    newTemplate,
    isLimited,
  }),
);

export const updateDataSetsStringAction = action(
  "updateDataSetsStringAction",
  (dataSetsStr: string) => ({ dataSetsStr }),
);

export const updateJsonStringAction = action(
  "updateJsonStringAction",
  (jsonStr: string) => ({ jsonStr }),
);

export const updatePropValueActionV2 = action(
  "updatePropValueActionV2",
  (update: JobConfigurationUpdateAction) => ({ update }),
);

export const deletePropValueActionV2 = action(
  "deletePropValueActionV2",
  (update: JobConfigurationDeleteAction) => ({ update }),
);

export const updatePropKeyActionV2 = action(
  "updatePropKeyActionV2",
  (update: JobConfigurationReplaceAction) => ({ update }),
);

export const updateSelectedEvaluationTypeAction = action(
  "updateSelectedEvaluationTypeAction",
  (newEvaluationType: EvaluationType) => ({ newEvaluationType }),
);

export const executeJobPreVerification = action(
  "executeJobPreVerification",
  () => ({}),
);

export const generateRefreshTokenAction = action(
  "generateRefreshToken",
  (callback?: () => void) => ({ callback }),
);

export const scrapingServiceTokenInitAction = action(
  "scrapingServiceTokenInitAction",
  (callback?: () => void) => ({ callback }),
);

export const updateUnifiedExpConfigWithScenarioAction = action(
  "updateUnifiedExpConfigWithScenarioAction",
  () => ({}),
);
