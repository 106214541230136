import {
  isUserProfileErrorResponse,
  isUserProfileSuccessResponse,
  Job,
} from "@seval-portal/client-models";
import { getAppEnv } from "@seval-portal/client-utils";
import {
  isJobSupportShare,
  isRestrictedSharingUser,
} from "@seval-portal/shared";
import { computed } from "mobx";
import { getUserAlias } from "../../../helpers/accountHelper";
import { contributionStore } from "../../../store/contributionStore";
import { isEditGroupJob } from "../helpers/creationDataHelper";
import { groupDetailStore } from "../store/groupDetailStore";
import { jobListStore } from "../store/jobListStore";
import { jobShareStore } from "../store/jobShareStore";
import { jobStore } from "../store/jobStore";

import { isFeatureEnabled } from "@seval-portal/client-store";

export const getShareLink = computed(() => {
  const currentScenario = getCurrentScenario();
  const id = jobShareStore.currentJob?.ID;
  const groupId = getGroupNameBySubJob.get()?.ID;
  const host = getAppEnv().webEndpoint;
  switch (currentScenario) {
    case "create":
      return `${host}/jobs`;

    case "list":
      if (id === undefined) {
        return `${host}/jobs`;
      }
      return `${host}/job/${id}`;

    case "groupCreate":
      return `${host}/?mode=group`;

    case "groupList":
      if (groupId === undefined) {
        return `${host}/?mode=group`;
      }
      return `${host}/group/${groupId}`;

    default:
      return `${host}${contributionStore.currentPath}`;
  }
});

export const getIsRestrictedSharing = computed(() => {
  const userAlias = getUserAlias();
  const userProfile = jobShareStore.userProfile;
  if (userProfile === undefined) {
    return false;
  }
  if (isUserProfileErrorResponse(userProfile)) {
    return true;
  }
  if (isUserProfileSuccessResponse(userProfile)) {
    return isRestrictedSharingUser(userAlias, userProfile);
  }
  return true;
});

export const getCurrentScenario = () => {
  const path = contributionStore.currentPath;
  if (path === "/create") {
    return "create";
  }
  if (path.includes("jobs") || path === "/?mode=job" || path === "/") {
    return "list";
  }
  if (path === "/?mode=group") {
    return "groupList";
  }
  if (path.includes("job") || path.includes("detail")) {
    return "job";
  }
  if (path.includes("/group/")) {
    return "group";
  }
  if (path.includes("/groupCreate")) {
    return "groupCreate";
  }
  return "other";
};

export const getIsGroupShareDialog = () => {
  const scenario = getCurrentScenario();
  if (scenario === "group" && jobShareStore.currentJob === undefined) {
    return true;
  }
  return false;
};

export const getSharedRecordId = computed(() => {
  const currentScenario = getCurrentScenario();
  if (currentScenario === "group") {
    if (jobShareStore.currentJob) {
      return jobShareStore.currentJob.ID.toString();
    }
    return groupDetailStore.group?.ID.toString();
  }
  if (jobShareStore.currentJob) {
    return jobShareStore.currentJob.ID.toString();
  }
  return undefined;
});

export const getGroupNameBySubJob = computed(() => {
  const currentScenario = getCurrentScenario();
  if (currentScenario !== "groupList") {
    return undefined;
  }

  const sharedJob = jobShareStore.currentJob;
  if (sharedJob === undefined) {
    return undefined;
  }
  return jobListStore.groups.find((group) =>
    group.Jobs?.find((job) => Job(job, "Job").ID === sharedJob?.ID),
  );
});

export const getGroupJobIdsNeedShare = computed(() => {
  const currentScenario = getCurrentScenario();
  if (currentScenario !== "group") {
    return undefined;
  }
  const groupJobs = groupDetailStore.jobs;
  const jobsNeedShare = groupJobs.filter(
    (job) => job.Properties?.HasSharePermission === true,
  );
  return jobsNeedShare.map((job) => job.ID.toString());
});

export const getSharedJobName = computed(() => {
  const currentScenario = getCurrentScenario();
  if (
    currentScenario === "job" ||
    currentScenario === "list" ||
    currentScenario === "groupList" ||
    currentScenario === "group"
  ) {
    return jobShareStore.currentJob?.JobName;
  }
  if (currentScenario === "create" || currentScenario === "groupCreate") {
    return jobStore.jobName;
  }
  return undefined;
});

export const checkHasSharePermission = computed(() => {
  const currentScenario = getCurrentScenario();
  if (currentScenario === "job") {
    const isQuerySetGenerator =
      jobShareStore.currentJob?.ExperimentName?.includes(
        "Query-Set-Generator",
      ) ||
      jobShareStore.currentJob?.ExperimentName?.includes(
        "Bing_Test_Set_Generator",
      );
    if (isQuerySetGenerator) {
      return false;
    }
    return jobShareStore.currentJob?.Properties?.HasSharePermission === true;
  }
  if (currentScenario === "create") {
    const isShareJob = isJobSupportShare(
      jobStore.configurationJsonString,
      jobStore.selectedTemplate?.Type ?? "",
      jobStore.selectedEvaluationType,
      jobStore.selectedTemplate?.ExperimentName ?? "",
    );
    return isShareJob;
  }
  if (currentScenario === "groupCreate") {
    const isAddOrEditJob = isEditGroupJob.get();
    const currentEditJob =
      isAddOrEditJob &&
      isJobSupportShare(
        jobStore.configurationJsonString,
        jobStore.selectedTemplate?.Type ?? "",
        jobStore.selectedEvaluationType,
        jobStore.selectedTemplate?.ExperimentName ?? "",
      );
    if (currentEditJob) {
      return true;
    }
  }

  const isJobGroupShareEnabled = isFeatureEnabled("job-group-share-enabled");
  if (currentScenario === "group" && isJobGroupShareEnabled) {
    // Check if any of the jobs in the group have share permission
    const groupJobs = groupDetailStore.jobs;
    for (const jobItem of groupJobs) {
      if (jobItem && jobItem.Properties?.HasSharePermission === true) {
        return true;
      }
    }
    return false;
  }
  return false;
});
