import { Portal, Spinner } from "@fluentui/react-components";
import { isFeatureEnabled } from "@seval-portal/client-store";
import { TemplateType } from "@seval-portal/shared";
import { observer } from "mobx-react-lite";
import { ResponsiveRow } from "../../../../../components/Responsive/ResponsiveRow";
import { isJobCreationEditDisabled } from "../../../helpers/jobCreationHelper";
import {
  getTemplateType,
  hideJsonConfiguration,
  supportEvaluationType,
} from "../../../selectors/getTemplateType";
import { jobCreationStore } from "../../../store/jobCreationStore";
import { JobDataSetView } from "../components/JobDataSetView";
import { JobErrorToast } from "../components/JobErrorToast";
import { JobEvaluationTemplateView } from "../components/JobEvaluationTemplateView";
import { JobEvaluationTypeView } from "../components/JobEvaluationTypeView";
import { JobJsonConfigurationView } from "../components/JobJsonConfigurationView";
import { JobNameView } from "../components/JobNameView";
import { JobPreValidationToast } from "../components/JobPreValidationToast";
import { JobQuickFilterView } from "../components/JobQuickFilterView";
import { JobSetAsPriorityView } from "../components/JobSetAsPriorityView";
import { JobWarningView } from "../components/JobWarningView";
import { BizChatFlightReviewRender } from "./BizChatFlightReviewRender";
import { CWCRender } from "./CWCRender";
import { GeneralSkipScrapingRender } from "./GeneralSkipScrapingRender";
import { JsonTemplateRender } from "./JsonTemplateRender";
import { NormalTemplateRender } from "./NormalTemplateRender";
import { OPGTemplateRender } from "./OPGTemplateRender";
import { ScrapingOnlyRender } from "./ScrapingOnlyRender";
import { UnifiedBizChatRender } from "./UnifiedBizChatRender";
import { UniversalSearchTemplateRender } from "./UniversalSearchTemplateRender";
import { useJobCreationStyles } from "./useJobCreationStyles";

const renderTemplateByType = () => {
  switch (getTemplateType.get()) {
    case TemplateType.JSON:
      return <JsonTemplateRender />;
    case TemplateType.AIHub:
      return <UniversalSearchTemplateRender />;
    case TemplateType.Normal:
      return <NormalTemplateRender />;
    case TemplateType.BingV2:
    case TemplateType.CWC:
      return <CWCRender />;
    case TemplateType.OPG:
      return <OPGTemplateRender />;
    case TemplateType.GeneralSkipScraping:
      return <GeneralSkipScrapingRender />;
    case TemplateType.ScrapingOnly:
      return <ScrapingOnlyRender />;
    case TemplateType.BizChatFlightReview:
      return <BizChatFlightReviewRender />;
    case TemplateType.UnifiedBizChat:
    case TemplateType.UnifiedBizChatFlightReview:
      return isFeatureEnabled("UnifiedBizChatTemplate") ? (
        <UnifiedBizChatRender />
      ) : (
        <JsonTemplateRender />
      );
  }
};
export const MainRender = observer(() => {
  const styles = useJobCreationStyles();
  if (jobCreationStore.isLoading) {
    return (
      <div className={styles.headerContainer}>
        <Spinner
          className={styles.spinner}
          labelPosition="below"
          label="Loading Job Templates..."
        />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <JobWarningView />
      {isFeatureEnabled("job-priority") && <JobSetAsPriorityView />}
      <JobQuickFilterView />
      <JobNameView />
      <ResponsiveRow
        maxColumnCount={2}
        maxColumnCountSmall={1}
        rowGap={20}
        columnGap={20}
      >
        <JobEvaluationTemplateView />
        {supportEvaluationType.get() && <JobEvaluationTypeView />}
      </ResponsiveRow>
      <JobDataSetView />
      {renderTemplateByType()}
      {!hideJsonConfiguration.get() && <JobJsonConfigurationView />}
      <JobErrorToast />
      <JobPreValidationToast />
      <Portal>
        {isJobCreationEditDisabled.get() && <div className={styles.overlay} />}
      </Portal>
    </div>
  );
});
