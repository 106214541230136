import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Button,
  Link,
  ProgressBar,
  Spinner,
  Toast,
  ToastBody,
  ToastFooter,
  ToastTitle,
  ToastTrigger,
  Toaster,
  makeStyles,
  mergeClasses,
  useId,
  useToastController,
} from "@fluentui/react-components";
import { CheckmarkCircle20Filled } from "@fluentui/react-icons";
import { isFeatureEnabled } from "@seval-portal/client-store";
import { telemetryHelper } from "@seval-portal/client-utils";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { SharedToasterPrefix } from "../../../../../components/Wrappers/ResponsiveProvider";
import { contributionStore } from "../../../../../store/contributionStore";
import { executeJobPreVerification } from "../../../actions/jobActions";
import {
  updateStatusIsTokenDialogOpen,
  updateTokenInitDialogType,
} from "../../../mutators/jobCreationMutators";
import {
  updateJobPreValidationIsCopyLinkClicked,
  updateJobPreValidationIsDispatched,
  updateJobPreValidationResolve,
} from "../../../mutators/jobPreValidationMutators";
import {
  getJobPreValidationIsCopyLinkClicked,
  getJobPreValidationIsDispatched,
  getJobPreValidationIsFinishedVerifing,
  getJobPreValidationProgressbarValue,
  getJobPreValidationSteps,
  getJobPreValidationToastBody,
  getJobPreValidationToastTitle,
} from "../../../selectors/getJobPreValidation";
import { jobPreValidationStore } from "../../../store/jobPreValidationStore";

const useStyles = makeStyles({
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  clamp: {
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  toastfooter: {
    display: "flex",
    alignItems: "flex-start",
    gap: "14px",
    flexDirection: "column",
  },
  centeredToast: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "50%",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  largeToastTitle: {
    marginTop: "16px",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    position: "absolute",
    right: "16px",
    bottom: "16px",
    gap: "16px",
  },
  instruction: { maxHeight: "16vh", overflow: "auto" },
});

export const JobPreValidationToast = observer(() => {
  const styles = useStyles();
  const toasterId = useId(SharedToasterPrefix, "JobPreValidationToaster");
  const toastId = useId("JobPreValidationToast");
  const { dispatchToast, updateToast, dismissToast } =
    useToastController(toasterId);
  const isCopyLinkClicked = getJobPreValidationIsCopyLinkClicked.get();
  const jobPreValidationSteps = getJobPreValidationSteps.get();
  const toastTitle = getJobPreValidationToastTitle.get();
  const toastBody = getJobPreValidationToastBody.get();
  const progressbarValue = getJobPreValidationProgressbarValue.get();
  const isDispatched = getJobPreValidationIsDispatched.get();
  const isFinishedVerifing = getJobPreValidationIsFinishedVerifing.get();
  const rowclamp = mergeClasses(styles.row, styles.clamp);

  const clickDismiss = () => {
    jobPreValidationStore.JobPreValidationResolve?.(false);
    updateJobPreValidationResolve(undefined);
  };

  const renderFailedToast = () =>
    isFeatureEnabled("scraping-service-migration") ? (
      <Toast className={styles.centeredToast}>
        <ToastTitle media={null} className={styles.largeToastTitle}>
          Validation failed
        </ToastTitle>
        <ToastBody style={{ gridColumn: "1/3" }}>
          <Accordion collapsible={true} defaultOpenItems={[0]}>
            {jobPreValidationSteps &&
              jobPreValidationSteps.length > 0 &&
              jobPreValidationSteps.map((step, index) => (
                <AccordionItem
                  style={{ wordBreak: "break-all" }}
                  key={index}
                  value={index}
                >
                  <AccordionHeader>
                    {step.stepName} {step.status}
                  </AccordionHeader>
                  <AccordionPanel>
                    <div className={rowclamp}>
                      {step.results.map((result, resultIndex) => (
                        <React.Fragment key={resultIndex}>
                          {`user: ${result.scrap_user}, status code: ${result.statusCode}, client request id: ${result.client_request_id}, error message: ${result.responseBody}`}
                          <br />
                        </React.Fragment>
                      ))}
                    </div>

                    <div className={styles.row}>
                      <Link
                        onClick={() => {
                          const results = step.results
                            .map(
                              (result) =>
                                `user: ${result.scrap_user}, status code: ${result.statusCode}, client request id: ${result.client_request_id}, error message: ${result.responseBody}, request: ${JSON.stringify(
                                  result.request,
                                )}`,
                            )
                            .join("\n");
                          navigator.clipboard.writeText(results);
                          updateJobPreValidationIsCopyLinkClicked(
                            isCopyLinkClicked.map((_clicked, i) => i === index),
                          );
                        }}
                      >
                        Copy full text
                      </Link>
                      {isCopyLinkClicked[index] && (
                        <CheckmarkCircle20Filled primaryFill={"#26a03b"} />
                      )}
                    </div>
                  </AccordionPanel>
                </AccordionItem>
              ))}
          </Accordion>
        </ToastBody>
        <ToastFooter className={styles.toastfooter}>
          <Link
            href="https://eng.ms/docs/experiences-devices/m365-core/microsoft-search-assistants-intelligence-msai/msai-stca/sydney-evaluation-platform/copilot-evaluation-platform/seval-manual-book/setup-job/bizchat-job-setup/debug-the-pre-submit-validation-failure"
            target="_blank"
          >
            How to debug the Pre Submit Validation Failure
          </Link>
          <div className={styles.instruction}>
            <h4>Common issues</h4>
            <p>
              In general, you can try to reproduce the problem locally by
              testing the HTTP API by referring to the appendix section at the
              bottom.
            </p>
            <ul>
              <li>
                <strong>Not Found (404):</strong> It is because the Sydney URL
                can't be reached. Please get the correct URL first.
              </li>
              <li>
                <strong>Forbidden (403):</strong> The URL exists but somehow it
                is blocked by auth. Please contact the BizChatDRI IcM team.
              </li>
              <li>
                <strong>Unauthorized (401):</strong> The user being used for
                scraping doesn't have permission to call Sydney. Please check if
                the user has a Copilot license.
              </li>
              <li>
                <strong>
                  For issues persisting without any response from Sydney:
                </strong>{" "}
                Kindly initiate an incident with Eval DRI, including the steps
                to reproduce the problem.
              </li>
              <li>
                <strong>
                  For consistent failures accompanied by a Sydney error (such as
                  5xx):
                </strong>{" "}
                If assistance is needed to understand the issue, please contact
                the BizChatDRI IcM team.
              </li>
            </ul>
          </div>
        </ToastFooter>
        <div className={styles.buttonContainer}>
          {isFeatureEnabled("bypass-jobprevalidation") && (
            <Button
              onClick={() => {
                telemetryHelper.logUserActionEvent("PreSubmitJobSkip");
                jobPreValidationStore.JobPreValidationResolve?.(true);
                updateJobPreValidationResolve(undefined);
              }}
            >
              Skip and continue submit the job
            </Button>
          )}
          <Button
            onClick={() => {
              updateJobPreValidationIsDispatched(false);
              clickDismiss();
            }}
          >
            Close
          </Button>
        </div>
      </Toast>
    ) : (
      <Toast>
        <ToastTitle>verification failed</ToastTitle>
        <ToastBody style={{ gridColumn: "1/3" }}>
          <Accordion collapsible={true}>
            {jobPreValidationSteps &&
              jobPreValidationSteps.length > 0 &&
              jobPreValidationSteps.map((step, index) => (
                <AccordionItem
                  style={{ wordBreak: "break-all" }}
                  key={index}
                  value={index}
                >
                  <AccordionHeader>
                    {step.stepName} {step.status}
                  </AccordionHeader>
                  <AccordionPanel>
                    <div className={rowclamp}>
                      {step.results.map((result, resultIndex) => (
                        <React.Fragment key={resultIndex}>
                          {`user: ${result.scrap_user}, status code: ${result.statusCode}, response: ${result.responseBody}`}
                          <br />
                        </React.Fragment>
                      ))}
                    </div>

                    <div className={styles.row}>
                      <Link
                        onClick={() => {
                          const results = step.results
                            .map(
                              (result) =>
                                `user: ${result.scrap_user}, status code: ${result.statusCode}, response: ${result.responseBody}, request: ${JSON.stringify(
                                  result.request,
                                )}`,
                            )
                            .join("\n");
                          navigator.clipboard.writeText(results);
                          updateJobPreValidationIsCopyLinkClicked(
                            isCopyLinkClicked.map((_clicked, i) => i === index),
                          );
                        }}
                      >
                        Copy full text
                      </Link>
                      {isCopyLinkClicked[index] && (
                        <CheckmarkCircle20Filled primaryFill={"#26a03b"} />
                      )}
                    </div>
                  </AccordionPanel>
                </AccordionItem>
              ))}
          </Accordion>
        </ToastBody>
        <ToastFooter className={styles.toastfooter}>
          <Link
            href="https://eng.ms/docs/experiences-devices/m365-core/microsoft-search-assistants-intelligence-msai/msai-stca/sydney-evaluation-platform/copilot-evaluation-platform/seval-manual-book/setup-job/bizchat-job-setup/debug-the-pre-submit-validation-failure"
            target="_blank"
          >
            How to debug the Pre Submit Validation Failure
          </Link>
          {isFeatureEnabled("bypass-jobprevalidation") && (
            <Link
              onClick={() => {
                telemetryHelper.logUserActionEvent("PreSubmitJobSkip");
                jobPreValidationStore.JobPreValidationResolve?.(true);
                updateJobPreValidationResolve(undefined);
              }}
            >
              Skip and continue submit the job
            </Link>
          )}
          <ToastTrigger>
            <Link
              onClick={() => {
                updateJobPreValidationIsDispatched(false);
                clickDismiss();
              }}
            >
              Close and confirm the job settings
            </Link>
          </ToastTrigger>
        </ToastFooter>
      </Toast>
    );

  const validationEnd = () => {
    //check if all the step in jobPreValidationStore.jobPreValidationSteps are passed
    const allStepsPassed = jobPreValidationSteps.every(
      (step) => step.statusCode === 200,
    );
    const filteredJobPreValidationSteps = jobPreValidationSteps.map(
      ({ statusCode, sydney_url, stepName, exp_config, results }) => ({
        statusCode,
        sydney_url,
        stepName,
        exp_config,
        results: results.map(
          ({
            statusCode: resultStatusCode,
            client_request_id,
            responseBody,
          }) => ({
            statusCode: resultStatusCode,
            client_request_id,
            responseBody: resultStatusCode !== 200 ? responseBody : undefined,
          }),
        ),
      }),
    );
    const isTokenMissingOrExpired = jobPreValidationSteps.some((step) =>
      step.results.some((result) => result.statusCode === 401),
    );
    if (
      isFeatureEnabled("scraping-service-migration") &&
      isTokenMissingOrExpired
    ) {
      updateTokenInitDialogType("TokenMissingOrExpired");
      updateStatusIsTokenDialogOpen(true);
      telemetryHelper.logUserActionEvent("PreSubmitJobSkip");
      jobPreValidationStore.JobPreValidationResolve?.(false);
      updateJobPreValidationResolve(undefined);
    } else {
      if (!allStepsPassed) {
        telemetryHelper.logUserActionEvent("PreSubmitJobFailure", {
          JobSteps: filteredJobPreValidationSteps,
        });
        updateToast({
          toastId,
          content: renderFailedToast(),
          timeout: -1,
          intent: "warning",
        });
      } else {
        telemetryHelper.logUserActionEvent("PreSubmitJobSuccess", {
          JobSteps: filteredJobPreValidationSteps,
        });
        jobPreValidationStore.JobPreValidationResolve?.(true);
        updateJobPreValidationResolve(undefined);
      }
    }
  };

  React.useEffect(() => {
    if (isCopyLinkClicked.includes(true)) {
      updateToast({
        toastId,
        content: renderFailedToast(),
        timeout: -1,
        intent: "warning",
      });
    }
  }, [isCopyLinkClicked]);

  React.useEffect(() => {
    if (isFinishedVerifing === true) {
      validationEnd();
    }
  }, [isFinishedVerifing]);

  React.useEffect(() => {
    if (progressbarValue >= 0) {
      updateToast({
        toastId,
        content: renderToast(),
      });
    }
  }, [progressbarValue]);

  const renderToast = () => (
    <Toast>
      <ToastTitle media={<Spinner size="tiny" />}>{toastTitle}</ToastTitle>
      <ToastBody>
        <div style={{ marginBottom: "5px" }}>{toastBody}</div>
        <ProgressBar value={progressbarValue} max={100} />
      </ToastBody>
    </Toast>
  );
  const notify = () => {
    if (isDispatched) {
      updateToast({
        toastId,
        content: renderToast(),
      });
      return;
    }
    dispatchToast(renderToast(), {
      timeout: -1,
      toastId,
    });
  };

  const location = contributionStore.currentPath;
  useEffect(() => {
    if (location !== "/create") {
      updateJobPreValidationResolve(undefined);
    }
  }, [location]);

  useEffect(() => {
    if (jobPreValidationStore.JobPreValidationResolve !== undefined) {
      telemetryHelper.logUserActionEvent("PreSubmitJob");
      executeJobPreVerification();
      notify();
      updateJobPreValidationIsDispatched(true);
    } else {
      dismissToast(toastId);
      updateJobPreValidationIsDispatched(false);
    }
  }, [jobPreValidationStore.JobPreValidationResolve]);

  return <Toaster toasterId={toasterId} />;
});
