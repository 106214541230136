import type { PriorityJobRequiredInfo } from "@seval-portal/client-models";
import { createStore } from "satcheljs";

export type ADOLinkValidationStatus =
  | "valid"
  | "invalid"
  | "warning"
  | "empty"
  | "pending";

interface IJobPriorityStore {
  isPriorityJob: boolean;
  isJobSetAsPriorityDialogOpen: boolean;
  isRemoveJobPriorityDialogOpen: boolean;
  jobPriorityADOLink: string | undefined;
  ADOLinkValidationStatus: ADOLinkValidationStatus | undefined;
  jobPriorityDescription: string | undefined;
  managerInfo: PriorityJobRequiredInfo | undefined;
  currentJobId: number | undefined;
}

export const initJobPriorityStore: IJobPriorityStore = {
  isPriorityJob: false,
  isJobSetAsPriorityDialogOpen: false,
  isRemoveJobPriorityDialogOpen: false,
  jobPriorityADOLink: "",
  ADOLinkValidationStatus: undefined,
  jobPriorityDescription: "",
  managerInfo: undefined,
  currentJobId: undefined,
};

export const jobPriorityStore = createStore<IJobPriorityStore>(
  "jobPriorityStore",
  Object.assign({}, initJobPriorityStore),
)();
