import {
  isUserProfileErrorResponse,
  isUserProfileSuccessResponse,
} from "@seval-portal/client-models";
import type { UserProfileResult } from "@seval-portal/shared";
import { isRestrictedSharingUser } from "@seval-portal/shared";

export const isRestrictedSharing = (
  userAlias: string,
  response: UserProfileResult,
) => {
  if (isUserProfileErrorResponse(response)) {
    return true;
  }
  if (isUserProfileSuccessResponse(response)) {
    return isRestrictedSharingUser(userAlias, response);
  }
  return true;
};
