import {
  none,
  RenameJobRouterParserRequestData,
  str,
} from "@seval-portal/shared";
import { createRequest } from "./createRequest";

export const getAriaToken = () =>
  createRequest({
    api: "/getAriaToken",
    requestData: {},
    typeGuard: str,
    dataBag: {
      skipTelemetry: true,
    },
  });

export const renameJob = (params: RenameJobRouterParserRequestData) =>
  createRequest({
    api: "/renamejob",
    requestData: params,
    typeGuard: none,
  });
