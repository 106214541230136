import { anyObject, object, optional, str } from "@seval-portal/shared";

const BingGenExpRules = object({
  url: str,
  option_sets: str,
  options: anyObject,
});

const BingExpRules = object({
  querygen_rules: anyObject,
  sydney: optional(BingGenExpRules),
});

export const BingGenerateTestSet = object({
  plugin_id: optional(str),
  plugin_version: optional(str),
  plugin_source: optional(str),
  singleturn: optional(BingExpRules),
  multiturn: optional(BingExpRules),
});

export type BingGenerateTestSet = ReturnType<typeof BingGenerateTestSet>;
