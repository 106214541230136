import * as React from "react";

import {
  Dropdown,
  Input,
  Option,
  shorthands,
  Switch,
  tokens,
} from "@fluentui/react-components";

import { isFeatureEnabled } from "@seval-portal/client-store";
import {
  JobStatusOptions,
  productOptions,
  telemetryHelper,
} from "@seval-portal/client-utils";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { ResponsiveRow } from "../../../../components/Responsive/ResponsiveRow";
import { makeResponsiveStyles } from "../../../../components/Responsive/makeResponsiveStyles";
import { updateCurrentPath } from "../../../../mutators/updateContributions";
import { refreshProductSettings } from "../../actions/productSettingsActions";
import { updateJobListViewMode } from "../../mutators/jobListMutators";
import {
  getAllProducts,
  PRODUCT_ID_BING,
  PRODUCT_ID_CWC,
  PRODUCT_ID_M365_CHAT,
  PRODUCT_ID_OPG,
} from "../../selectors/productSelectors";
import { jobListStore } from "../../store/jobListStore";
import { productSettingsStore } from "../../store/productSettingsStore";
import { resultStore } from "../../store/resultStore";
import { ProductSelectDialog } from "../Dialog/ProductSelectDialog";
import { JobSetAsPriorityDialog } from "../JobCreation/components/JobSetAsPriorityDialog";
import { RemoveJobPriorityDialog } from "../JobCreation/components/RemoveJobPriorityDialog";
import { ShareButton } from "../JobDetail/components/ShareButton";
import { CreateGroupButton } from "../JobGroup/components/CreateGroupButton";
import { GroupsTable } from "./GroupsTable";
import { JobFilterView } from "./JobFilterView";
import { JobsTable } from "./JobsTable";

const useStyles = makeResponsiveStyles(
  {
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      boxSizing: "border-box",
      minHeight: "100%",
      overflowX: "auto",
    },
    columnContainer: {
      display: "flex",
      flexDirection: "column",
    },
    rowContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      ...shorthands.gap("8px"),
    },
    label: {
      ...shorthands.margin("5px", "0", "5px", "0"),
      fontFamily: tokens.fontFamilyBase,
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "16px",
      fontStyle: "normal",
      color: "#605E5C",
    },
    functionBarContainer: {
      width: "100%",
      ...shorthands.margin("15px", "0", "20px", "0"),
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flexWrap: "wrap-reverse",
      justifyContent: "space-between",
    },
    newEvaluationButton: {
      ...shorthands.padding("10px", "12px"),
      ...shorthands.borderRadius(tokens.borderRadiusLarge),
      width: "171px",
      height: "44px",
      color: "white",
      marginLeft: "auto",
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "22px",
    },
    icon: {
      opacity: 0.5,
      color: "#fff",
    },
    dialogContainer: {
      height: "90vh",
      width: "90vw",
      maxWidth: "90vw",
      ...shorthands.overflow("auto", "auto"),
    },
  },
  {
    xs: {
      rowContainer: {
        width: "70%",
      },
    },
    sm: {
      rowContainer: {
        width: "70%",
      },
    },
    md: {
      rowContainer: {
        width: "70%",
      },
    },
    lg: {
      rowContainer: {
        maxWidth: "70%",
      },
    },
  },
);

export const getFiltersByProductId = (productId: string) => {
  switch (productId) {
    case "All":
      return { targetProductType: "All", targetExperimentPrefix: "" };
    case PRODUCT_ID_M365_CHAT:
      return { targetProductType: "BizChat", targetExperimentPrefix: "" };
    case PRODUCT_ID_BING:
      return { targetProductType: "Bing", targetExperimentPrefix: "" };
    case PRODUCT_ID_CWC:
      return { targetProductType: "CWC", targetExperimentPrefix: "" };
    case PRODUCT_ID_OPG:
      return { targetProductType: "OPG", targetExperimentPrefix: "" };
    default:
      break;
  }

  // Custom product
  const targetProduct = productSettingsStore.products.find(
    (_) => _.id === productId,
  );

  if (targetProduct === undefined) {
    return { targetProductType: "", targetExperimentPrefix: "" };
  } else {
    return {
      targetProductType: "",
      targetExperimentPrefix: `Product_${targetProduct.displayName.replace(
        " ",
        "",
      )}_`,
    };
  }
};

const JobTypes = ["Single Job", "Group Job"] as const;
export const JobListView = observer(() => {
  const [searchParams] = useSearchParams();
  const styles = useStyles();
  const [selectedStatus, setSelectedStatus] = React.useState(
    JobStatusOptions[0],
  );
  const [jobNameInput, setJobNameInput] = React.useState("");
  const [creatorInput, setCreatorInput] = React.useState("");

  const [jobNameKeyword, setJobNameKeyword] = React.useState("");
  const [creatorKeyword, setCreatorKeyword] = React.useState("");
  const [jobFilterType, setJobFilterType] = React.useState("all");
  const [productType, setProductType] = React.useState<string>(
    productOptions[0],
  );
  const [experimentPrefix, setExperimentPrefix] = React.useState("");
  const [showPriorityJobOnly, setShowPriorityJobOnly] = React.useState(false);

  React.useEffect(() => {
    refreshProductSettings();
  }, []);

  React.useEffect(() => {
    if (searchParams.get("mode") === "group") {
      updateJobListViewMode("group");
    } else {
      updateJobListViewMode("job");
    }
  }, [searchParams]);

  React.useEffect(() => {
    // Log view render when the status is changed.
    telemetryHelper.logDiagnosticEvent("ViewDataChanged", {
      panelType: "Evaluation",
      panelUri: "Evaluation.JobList",
      message: "JobListView updated with status change",
    });
  }, [
    selectedStatus,
    jobNameKeyword,
    creatorKeyword,
    jobFilterType,
    productType,
    experimentPrefix,
  ]);

  const handleProductIdChange = (productId: string) => {
    const { targetProductType, targetExperimentPrefix } =
      getFiltersByProductId(productId);

    setProductType(targetProductType);
    setExperimentPrefix(targetExperimentPrefix);
  };

  const isShowJobGroup = computed(() => jobListStore.mode === "group").get();

  return (
    <div className={styles.root}>
      <JobFilterView
        type={jobFilterType}
        onClickType={(type) => {
          setJobFilterType(type);
          if (type !== "all") {
            setCreatorInput("");
            setCreatorKeyword("");
          } else {
            setJobNameKeyword(jobNameInput);
            setCreatorKeyword(creatorInput);
          }
        }}
      />
      <div className={styles.functionBarContainer}>
        <div className={styles.rowContainer}>
          <ResponsiveRow
            maxColumnCount={5}
            maxColumnCountSmall={1}
            columnGap={8}
          >
            {!isShowJobGroup && (
              <div className={styles.columnContainer}>
                <label className={styles.label}>Product</label>

                <Dropdown
                  style={{ minWidth: "100px" }}
                  aria-label="Product Filering Dropdown"
                  defaultValue={"All"}
                  defaultSelectedOptions={["All"]}
                  onOptionSelect={(_, data) => {
                    handleProductIdChange(data.optionValue ?? "All");
                  }}
                >
                  <Option key={"All"}>{"All"}</Option>
                  {getAllProducts.get().map((product) => (
                    <Option key={product.id} value={product.id}>
                      {product.displayName}
                    </Option>
                  ))}
                </Dropdown>
              </div>
            )}
            {!isShowJobGroup && (
              <div className={styles.columnContainer}>
                <label className={styles.label}>Status</label>
                <Dropdown
                  style={{ minWidth: "100px" }}
                  aria-label="Status Filering Dropdown"
                  defaultValue={JobStatusOptions[0]}
                  defaultSelectedOptions={[JobStatusOptions[0]]}
                  onOptionSelect={(_, data) => {
                    setSelectedStatus(data.optionValue ?? JobStatusOptions[0]);
                  }}
                >
                  {JobStatusOptions.map((option) => (
                    <Option key={option}>{option}</Option>
                  ))}
                </Dropdown>
              </div>
            )}
            <div className={styles.columnContainer}>
              <label className={styles.label}>
                {isShowJobGroup ? "Group Name" : "Job Name"}
              </label>
              <Input
                style={{ minWidth: "100px" }}
                appearance="outline"
                placeholder={
                  isShowJobGroup ? "Type Group Name" : "Type Job Name"
                }
                value={jobNameInput}
                onKeyDown={(ev) => {
                  if (ev.key === "Enter") {
                    setJobNameKeyword(jobNameInput);
                    setCreatorKeyword(creatorInput);
                  }
                }}
                onChange={(_, data) => {
                  setJobNameInput(data.value);
                }}
              />
            </div>
            {jobFilterType === "all" && (
              <div className={styles.columnContainer}>
                <label className={styles.label}>Created By</label>
                <Input
                  style={{ minWidth: "100px" }}
                  appearance="outline"
                  placeholder="Type job creator"
                  value={creatorInput}
                  onKeyDown={(ev) => {
                    if (ev.key === "Enter") {
                      setCreatorKeyword(creatorInput);
                      setJobNameKeyword(jobNameInput);
                    }
                  }}
                  onChange={(_, data) => {
                    setCreatorInput(data.value);
                  }}
                />
              </div>
            )}
            {isFeatureEnabled("enable-job-group") && (
              <div className={styles.columnContainer}>
                <label className={styles.label}>Job type</label>
                <Dropdown
                  style={{ minWidth: "100px" }}
                  aria-label="Job type Filering Dropdown"
                  value={isShowJobGroup ? JobTypes[1] : JobTypes[0]}
                  selectedOptions={[isShowJobGroup ? JobTypes[1] : JobTypes[0]]}
                  onOptionSelect={(_, data) => {
                    const currentJobType =
                      data.optionValue === JobTypes[1] ? "group" : "job";
                    updateCurrentPath(`/?mode=${currentJobType}`);
                  }}
                >
                  {JobTypes.map((option) => (
                    <Option key={option}>{option}</Option>
                  ))}
                </Dropdown>
              </div>
            )}
            {isFeatureEnabled("job-priority") && jobFilterType === "my" && (
              <div className={styles.columnContainer}>
                <Switch
                  checked={showPriorityJobOnly}
                  label={"View priority job only"}
                  onChange={() => {
                    setShowPriorityJobOnly(!showPriorityJobOnly);
                  }}
                />
              </div>
            )}
          </ResponsiveRow>
        </div>
        <CreateGroupButton />
      </div>
      {!isShowJobGroup && (
        <JobsTable
          selectedStatus={selectedStatus}
          jobNameKeyword={jobNameKeyword}
          creator={creatorKeyword}
          filter={jobFilterType}
          product={productType}
          experimentPrefix={experimentPrefix}
          showPriorityJobOnly={showPriorityJobOnly}
        />
      )}
      {isShowJobGroup && (
        <GroupsTable
          jobNameKeyword={jobNameKeyword}
          creator={creatorKeyword}
          filter={jobFilterType}
        />
      )}
      {isFeatureEnabled("job-priority") &&
        (resultStore.resultJob?.IsPriorityJob ? (
          <RemoveJobPriorityDialog />
        ) : (
          <JobSetAsPriorityDialog />
        ))}

      <ShareButton />
      <ProductSelectDialog />
    </div>
  );
});
