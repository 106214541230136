import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Dropdown,
  Field,
  Input,
  Option,
} from "@fluentui/react-components";
import {
  AvailableFakeTenantEvaluationTypes,
  SEVALAvailableFakeTenantEvaluationTypes,
  type FakeTenantUploadTokenType,
} from "@seval-portal/client-models";
import { isFeatureEnabled } from "@seval-portal/client-store";
import { telemetryHelper } from "@seval-portal/client-utils";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { useToast } from "../../../../components/Wrappers/ToasterProvider";
import type { FeatureSettingsDialogContributionProps } from "../../../../contribution/FeatureSettingsContribution";
import {
  getRefreshTokenKey,
  getTestAccountPasswordName,
} from "../../../../helpers/accountHelper";
import { uploadToken } from "../../actions/uploadToken";

type UploadFakeTenantInfoDialogProps =
  FeatureSettingsDialogContributionProps & {
    type: FakeTenantUploadTokenType;
  };

export const UploadFakeTenantInfoDialog = observer(
  (props: UploadFakeTenantInfoDialogProps) => {
    const [fakeTenantAccountName, setFakeTenantAccountName] =
      React.useState<string>("");
    const [secretValue, setSecretValue] = React.useState<string>("");
    const [tokenTypeValue, setTokenTypeValue] =
      React.useState<FakeTenantUploadTokenType>(props.type);
    const toast = useToast();

    const computedSecretNameMessage = computed(() => {
      if (fakeTenantAccountName.trim() === "") {
        return "account can not be empty";
      }
      if (fakeTenantAccountName.includes("@microsoft.com")) {
        return "account should be test tenant account";
      }
      return undefined;
    });

    const computedSecretValueMessage = computed(() => {
      if (secretValue.trim() === "") {
        return `${tokenTypeValue} value can not be empty`;
      }
      return undefined;
    });

    const computedSecretName = computed(() => {
      switch (tokenTypeValue) {
        case "password":
          return getTestAccountPasswordName(fakeTenantAccountName);
        default:
          return getRefreshTokenKey(fakeTenantAccountName, tokenTypeValue);
      }
    });

    const handleUploadToken = () => {
      toast.onToastStart("Uploading token...");

      telemetryHelper.logUserActionEvent("SetToken", {
        inputType: tokenTypeValue,
        source: "UploadFakeTenantInfoDialog",
      });

      uploadToken(
        tokenTypeValue,
        computedSecretName.get(),
        secretValue,
        () => {
          telemetryHelper.logUserActionEvent("SetTokenSuccess", {
            inputType: tokenTypeValue,
            source: "UploadFakeTenantInfoDialog",
          });

          toast.onToastSuccess("Token uploaded successfully");
          props.close();
        },
        (error) => {
          telemetryHelper.logUserActionEvent("SetTokenFailure", {
            inputType: tokenTypeValue,
            message: error.message,
            source: "UploadFakeTenantInfoDialog",
          });

          toast.onToastFailure(
            `Token upload failed with message: ${error.message}`,
          );
          props.close();
        },
      );
    };

    const computedTitle = computed(() => {
      switch (tokenTypeValue) {
        case "password":
          return "Upload Test Tenant Password";
        default:
          return "Upload Test Tenant " + tokenTypeValue;
      }
    });

    const onDialogClose = () => {
      props.close();
    };

    React.useEffect(() => {
      if (props.isOpen) {
        setFakeTenantAccountName("");
        setSecretValue("");
      }
    }, [props.isOpen]);

    const onTokenTypeSelect = (data: string) => {
      const findTokenType = [
        ...AvailableFakeTenantEvaluationTypes,
        ...SEVALAvailableFakeTenantEvaluationTypes,
      ].find((type) => type === data);
      if (findTokenType) {
        setTokenTypeValue(findTokenType);
      }
    };

    const currentAvailableFakeTenantEvaluationTypes = isFeatureEnabled(
      "generate-refresh-token",
    )
      ? SEVALAvailableFakeTenantEvaluationTypes
      : AvailableFakeTenantEvaluationTypes;
    return (
      <Dialog
        modalType="alert"
        open={props.isOpen}
        onOpenChange={() => {
          props.close();
        }}
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{computedTitle.get()}</DialogTitle>
            <DialogContent>
              <Field
                required
                label={<>Select the token type you want to upload.</>}
              >
                <Dropdown
                  data-testid="sevice-token-type"
                  placeholder="Select a service"
                  value={tokenTypeValue}
                  onOptionSelect={(e, data) =>
                    onTokenTypeSelect(data.optionValue ?? "")
                  }
                >
                  {currentAvailableFakeTenantEvaluationTypes.map((option) => (
                    <Option key={option}>{option}</Option>
                  ))}
                </Dropdown>
              </Field>
              <Field
                required
                label={
                  <Body1>{"Please provide your test tenant account: "}</Body1>
                }
                validationState={
                  computedSecretNameMessage.get() ? "error" : "success"
                }
                validationMessage={computedSecretNameMessage.get()}
              >
                <Input
                  data-testid="fake-tenant-account-name"
                  style={{ width: "100%" }}
                  size="medium"
                  value={fakeTenantAccountName}
                  onChange={(_, data) => {
                    setFakeTenantAccountName(data.value);
                  }}
                />
              </Field>

              <Field
                required
                label={
                  <>
                    <Body1>{`You can input your ${tokenTypeValue} here.`}</Body1>
                    {fakeTenantAccountName && (
                      <>
                        <br />
                        <Body1>{`This ${tokenTypeValue} will be stored as secret with the name below in Azure Key Vault.`}</Body1>
                        <br />
                        <strong>{`${computedSecretName.get()}`}</strong>
                      </>
                    )}
                  </>
                }
                validationState={
                  computedSecretValueMessage.get() ? "error" : "success"
                }
                validationMessage={computedSecretValueMessage.get()}
              >
                <Input
                  style={{ width: "100%" }}
                  size="medium"
                  value={secretValue}
                  onChange={(_, data) => {
                    setSecretValue(data.value);
                  }}
                />
              </Field>
            </DialogContent>

            <DialogActions>
              <Button
                disabled={
                  computedSecretNameMessage.get() !== undefined ||
                  computedSecretValueMessage.get() !== undefined
                }
                onClick={() => {
                  handleUploadToken();
                }}
                appearance="primary"
              >
                Confirm
              </Button>
              <Button onClick={onDialogClose} appearance="secondary">
                Close
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  },
);
