import { isFeatureEnabled } from "@seval-portal/client-store";
import { observer } from "mobx-react-lite";
import { refreshCustomQuerySets } from "../../../helpers/jobCreationHelper";
import { updateCustomizedQuerySets } from "../../../mutators/jobCreationFileMutators";
import { jobCreationFileStore } from "../../../store/jobCreationFileStore";
import { jobStore } from "../../../store/jobStore";
import { JobConfigure3pPluginsBlockView } from "../components/Job3pPluginsConfigurationBlockView";
import { JobGPTIdentifierView } from "../components/JobGPTIdentifierView";
import { JobSbsExpContainer } from "../components/JobSBSExpContainer";
import { JobSelectedFileView } from "../components/JobSelectedFileView";
import { JobSimplifyPairExperimentationView } from "../components/JobSimplifyPairExperimentationView";
import { JobUserIdView } from "../components/JobUserIdView";

export const BizChatFlightReviewRender = observer(() => {
  const selectedTemplate = jobStore.selectedTemplate;
  if (selectedTemplate === undefined) {
    return <></>;
  }
  return (
    <>
      <JobSelectedFileView
        customQuerySets={jobCreationFileStore.customQuerySets}
        setCustomQuerySets={updateCustomizedQuerySets}
        refreshCustomQuerySets={refreshCustomQuerySets}
      />
      <JobUserIdView />
      <JobSimplifyPairExperimentationView />
      {!isFeatureEnabled("gpt-agent-list") && <JobGPTIdentifierView />}
      <JobSbsExpContainer />
      <JobConfigure3pPluginsBlockView />
    </>
  );
});
