import type { Job } from "@seval-portal/client-models";
import { perfWrapper, RenderManifest } from "@seval-portal/client-utils";
import {
  getExpNames,
  isMultiServiceTemplate,
  parseJsonStrOptional,
  TemplateType,
} from "@seval-portal/shared";
import { computed } from "mobx";
import {
  getJob,
  getJobOutput,
  getJobPerfMetric,
  getJobSBSExp,
  getJobTemplates,
  getLMChecklistAssertions,
  getLMChecklistDetails,
  getLMChecklistIcms,
  getMetricsByJobId,
  getOPGRAIResult,
  getPrivacyFilterData,
} from "../../../helpers/apiHelper";
import { getOutputBlobSource } from "../helpers/bingResultHelper";
import { calculateSbsExpLinks } from "../helpers/JobSBSExpResultHelper";
import { getPassFailRatesByQuery } from "../helpers/lmchecklistMetricsHelper";
import type { MetricsData } from "../helpers/metricsHelper";
import { converPerfMetricsData } from "../helpers/perfMetricsDataHelper";
import { JobSBSExpResult } from "../models/JobSBSExpResult";
import {
  updatedpfRatesByQuery,
  updateGeneralMetricsResponse,
  updateLMChecklistAssertionsResponse,
  updateLMChecklistDetailsResponse,
  updateLMChecklistIcmsResponse,
  updatePrivacyFilterResponse,
} from "../mutators/jobDetailsMutators";
import { updateAvailableTemplates } from "../mutators/jobMutators";
import {
  addLoadingMessage,
  removeLoadingMessage,
  updateBingJobOutputfiles,
  updateConciseTable,
  updateManifest,
  updatePerfMetricsData,
  updatePerfStatus,
  updateResultJob,
  updateSBSLinks,
  updateSummaryTable,
  updateTemplateType,
} from "../mutators/jobResultMutators";
import { updateCurrentShareJob } from "../mutators/jobShareMutators";
import { jobDetailStore } from "../store/jobDetailStore";
import { resultStore } from "../store/resultStore";

const hasNoReadPermission = computed(
  () => resultStore.resultJob?.Properties?.HasReadPermission === false,
);

export const disableDiagnosisView = computed(() => {
  return hasNoReadPermission.get();
});

export const showNoPermissionDialog = computed(() => {
  if (resultStore.scenario === "Scorecard Comparison") {
    return false;
  }
  return hasNoReadPermission.get();
});

export const isMultiturnJob = computed(() => {
  return resultStore.resultJob?.ExperimentName?.toLocaleLowerCase().includes(
    "multi_turn",
  );
});

export const initMChatDetailData = (loadedJob: Job) => {
  if (loadedJob.JobId === undefined) {
    return;
  }

  // Start Non-Blocking API Calls
  const JobId = loadedJob.JobId;

  addLoadingMessage("Loading LM Checklist");
  // Incident Link: https://portal.microsofticm.com/imp/v3/incidents/incident/447761203/summary
  // Current solution is to catch the error and ignore it to make sure the page can be loaded
  // TODO: Improve the LMCheckList Performance and remove the catch
  perfWrapper(
    "LoadMChatMetricDiagnosisLMChecklist",
    Promise.all([
      getLMChecklistDetails({ JobId })
        .catch(() => undefined)
        .then((response) => {
          updateLMChecklistDetailsResponse(response);
        }),
      getLMChecklistAssertions({ JobId })
        .catch(() => undefined)
        .then((response) => {
          updateLMChecklistAssertionsResponse(response);
        }),
      getLMChecklistIcms()
        .catch(() => undefined)
        .then((response) => {
          updateLMChecklistIcmsResponse(response);
        }),
    ]),
  )
    .then(() => {
      const assertions = jobDetailStore.lmchecklistAssertionsResponse;
      const lmchecklistDetailsResponse =
        jobDetailStore.lmchecklistDetailsResponse ?? [];
      if (assertions) {
        const pfMap = lmchecklistDetailsResponse.map((record) =>
          getPassFailRatesByQuery(assertions, record),
        );
        updatedpfRatesByQuery(pfMap);
      }
    })
    .finally(() => {
      removeLoadingMessage("Loading LM Checklist");
    });

  addLoadingMessage("Loading Privacy Filter Query Set");
  perfWrapper(
    "LoadPrivacyFilterFilterdQuerySet",
    getPrivacyFilterData(JobId)
      .then((response) => {
        updatePrivacyFilterResponse(response);
      })
      .catch(() => {
        updatePrivacyFilterResponse([]);
      }),
  ).finally(() => {
    removeLoadingMessage("Loading Privacy Filter Query Set");
  });

  getSBSResult(loadedJob);
};

export const initM365Data = (loadedJob: Job) => {
  if (loadedJob.JobId === undefined) {
    return;
  }

  const JobId = loadedJob.JobId;
  addLoadingMessage("Loading General Metrics");
  perfWrapper(
    "LoadMChatScorecardComparison",
    getMetricsByJobId({ JobId }).then((response) => {
      updateGeneralMetricsResponse(response);
    }),
  ).finally(() => {
    removeLoadingMessage("Loading General Metrics");
  });

  const shouldDisableMChatDiagnosis = disableDiagnosisView.get();

  if (!shouldDisableMChatDiagnosis) {
    initMChatDetailData(loadedJob);
  }
};

export const initBingData = (loadedJob: Job) => {
  if (loadedJob?.Status === "Completed" && loadedJob?.StudioUrl) {
    const id = loadedJob?.StudioUrl.split("?")[0].split("runs/")[1];
    const blobSource = getOutputBlobSource(loadedJob);
    addLoadingMessage("Loading Bing Job Output Files");
    perfWrapper(
      "LoadBingScorecardComparison",
      getJobOutput(id, blobSource)
        .then((result) => {
          const jobFiles = result.items.map((item, index) => {
            const splits = item.split("/");
            const fileName = splits[splits.length - 1];
            const folderName = splits[splits.length - 2];
            return {
              folderName: folderName,
              fileName: fileName,
              fullPath: item,
              sizeInBytes: result.sizeInBytes[index],
            };
          });
          updateBingJobOutputfiles(jobFiles);
        })
        .catch(() => {
          updateBingJobOutputfiles(undefined);
        }),
    ).finally(() => {
      removeLoadingMessage("Loading Bing Job Output Files");
    });
  }
};

export const getRenderManifest = (loadedJob: Job) => {
  const templateName = loadedJob.ExperimentName;
  return RenderManifest.find((manifest) => manifest.template === templateName);
};

export const getSBSResult = (loadedJob: Job) => {
  addLoadingMessage("Loading SBS Experiment");
  getJobSBSExp({ JobId: loadedJob.JobId ?? "" })
    .then((sbsResponse) => {
      if (sbsResponse.length > 0) {
        const result = parseJsonStrOptional(
          sbsResponse[0].experiments,
          JobSBSExpResult,
        );

        if (!result) {
          return;
        }
        const SBSExpResult = calculateSbsExpLinks(result);
        updateSBSLinks(SBSExpResult);
      }
    })
    .finally(() => {
      removeLoadingMessage("Loading SBS Experiment");
    });
};

export const initPerfMetrics = () => {
  const expNames = getExpNames(resultStore.resultJob?.Settings ?? "");
  const jobID = resultStore.resultJob?.JobId;
  const SourceA = `eval__${expNames.control}_${jobID}`;
  const SourceB = `eval__${expNames.experiment}_${jobID}`;
  updatePerfMetricsData([]);
  updatePerfStatus("Begin");
  perfWrapper(
    "LoadPerfMetricsData",
    getJobPerfMetric({ SourceA, SourceB })
      .then((result) => {
        let metricResult: MetricsData[] = [];
        result.forEach((item) => {
          const oneResult = item.tables.find(
            (table) => table.name === "PrimaryResult",
          );
          const convertResult = converPerfMetricsData(
            item.metric,
            oneResult?.data ?? [],
          );
          metricResult = [...metricResult, ...convertResult];
        });
        updatePerfMetricsData(metricResult);
        updatePerfStatus("Done");
      })
      .catch(() => {
        updatePerfStatus("Failure");
      }),
  );
};
export const initJobDetailPage = (jobIdNumber: number) => {
  getJobTemplates().then((templates) => {
    updateAvailableTemplates(templates);
  });
  return perfWrapper(
    "LoadJobInfo",
    getJob({ jobId: jobIdNumber.toString() }).then((result) => {
      const loadedJob = result[0];
      if (loadedJob) {
        updateResultJob(loadedJob);
        updateCurrentShareJob(loadedJob);
        updateTemplateType(loadedJob.JobTemplateType);
        if (
          loadedJob.JobTemplateType === "BingV2" ||
          loadedJob.JobTemplateType === "CWC" ||
          loadedJob.JobTemplateType === "ScrapingOnly"
        ) {
          initBingData(loadedJob);
        } else {
          const renderManifest = getRenderManifest(loadedJob);
          if (!renderManifest) {
            initM365Data(loadedJob);

            const isCompletedMChatJob = () => {
              return (
                loadedJob.JobTemplateType !== "BingV2" &&
                loadedJob.JobTemplateType !== "CWC" &&
                loadedJob.JobTemplateType != "OPG" &&
                loadedJob.JobTemplateType != TemplateType.AIHub &&
                loadedJob.JobTemplateType != "BingGenerator" &&
                loadedJob.Status === "Completed"
              );
            };
            if (
              isCompletedMChatJob() ||
              isMultiServiceTemplate(loadedJob.ExperimentName ?? "")
            ) {
              initPerfMetrics();
            }
          } else {
            updateManifest(renderManifest);
            getOPGRAIResult({
              JobId: loadedJob.JobId ?? "",
              Manifest: JSON.stringify(renderManifest),
            }).then((response) => {
              updateSummaryTable(response.summary);
              updateConciseTable(response.concise);
            });
          }
        }
      }
    }),
  );
};
