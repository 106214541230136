import { store } from "@seval-portal/client-store";

export const checkBingUserId = (value: string | undefined, path: string) => {
  if (value === undefined) {
    return { path, message: "user_id should not be undefined!" };
  }

  if (value === "") {
    return { path, message: "user_id name should not be empty!" };
  }

  return value !== store.account?.username
    ? {
        path,
        message: value + ": This field is not match with current user!",
      }
    : undefined;
};
