import { store } from "@seval-portal/client-store";
import { mutatorAction } from "satcheljs";

export const updateTokenRefreshError = mutatorAction(
  "updateTokenRefreshError",
  (error: string) => {
    store.tokenRefreshError = error;
  },
);

export const resetTokenRefreshError = mutatorAction(
  "resetTokenRefreshError",
  () => {
    store.tokenRefreshError = undefined;
  },
);
