import type { Job } from "@seval-portal/client-models";
import { store } from "@seval-portal/client-store";
import {
  parseJsonStrOptional,
  type ScheduledJob,
  type UpdateScheduleJobRequestData,
} from "@seval-portal/shared";
import { computed } from "mobx";
import { getJob } from "../../../helpers/apiHelper";
import { updateCurrentPath } from "../../../mutators/updateContributions";
import { isDRI, isSchedulerManager } from "../../../selectors/userPermission";
import {
  maxActiveScheduler,
  maxInterval,
  minInterval,
  minIntervalForSchedulerManager,
} from "../components/ScheduledJob/constants";
import { ScheduleJobAdditionInfo } from "../models/SchedulerData";
import {
  updateErrorMessage,
  updateSelectedScheduleJob,
} from "../mutators/scheduleJobMutators";
import { scheduleJobStore } from "../store/scheduleJobStore";

export const getScheduleJobSubmitContent = computed(
  (): UpdateScheduleJobRequestData | undefined => {
    const { pageMode, activeScheduleJobs, selectedScheduledJob } =
      scheduleJobStore;
    if (selectedScheduledJob === undefined) {
      return undefined;
    }

    const sourceJob = activeScheduleJobs.filter(
      (job) => job.Id === selectedScheduledJob.Id,
    );
    if (pageMode === "Edit" && sourceJob[0]) {
      let emptyContent = {};
      const sourceEntries = Object.entries(sourceJob[0]);
      Object.entries(selectedScheduledJob).forEach(([key, value]) => {
        if (value !== sourceEntries.find((entry) => entry[0] === key)?.[1]) {
          emptyContent = {
            ...emptyContent,
            [key]: value,
          };
        }
      });

      if (
        sourceJob[0].EndTime !== undefined &&
        selectedScheduledJob.EndTime === undefined
      ) {
        emptyContent = {
          ...emptyContent,
          EndTime: "",
        };
      }
      if (Object.keys(emptyContent).length === 0) {
        return undefined;
      }

      return {
        Id: selectedScheduledJob.Id,
        Content: emptyContent,
      };
    }
  },
);

export const getScheduleJobNameError = computed(() => {
  const { selectedScheduledJob } = scheduleJobStore;

  if (
    selectedScheduledJob?.Name === undefined ||
    selectedScheduledJob?.Name === "<Create New Scheduled Job>" ||
    selectedScheduledJob?.Name.trim() === ""
  ) {
    return "Please input job name";
  }

  const hasDuplicateName = scheduleJobStore.activeScheduleJobs.find(
    (job) =>
      job.Name === selectedScheduledJob?.Name &&
      job.Id !== selectedScheduledJob?.Id,
  );
  if (hasDuplicateName) {
    return "Job Name already exists!";
  }

  const regexStr = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
  if (!regexStr.test(selectedScheduledJob?.Name ?? "")) {
    return "Job Name should start with a letter or an underscore and contain only letters, numbers, and underscores!";
  }
  return undefined;
});

export const getStartTimeError = computed(() => {
  const { selectedScheduledJob } = scheduleJobStore;

  if (selectedScheduledJob?.StartTime.trim() === "") {
    return "Please input start time";
  }
  return undefined;
});

export const getIntervalsError = computed(() => {
  const { selectedScheduledJob } = scheduleJobStore;

  if (selectedScheduledJob?.IntervalInHours === undefined) {
    return "Please input interval";
  }

  const currentMinInterval = isSchedulerManager.get()
    ? minIntervalForSchedulerManager
    : minInterval;
  if (
    selectedScheduledJob?.IntervalInHours < currentMinInterval ||
    selectedScheduledJob?.IntervalInHours > maxInterval
  ) {
    return `Interval should range from ${currentMinInterval} hours to ${maxInterval} hours (${
      maxInterval / 24
    } days)`;
  }
  return undefined;
});

export const getNoChangeError = computed(() => {
  const content = getScheduleJobSubmitContent.get();
  const noChangeDetect = content === undefined;

  if (noChangeDetect) {
    return "No Change detected!";
  }
  return undefined;
});

export const getSchedulerQuotaError = computed(() => {
  // Only scheduler manager can create scheduler without limit
  if (isSchedulerManager.get()) {
    return undefined;
  }
  if (scheduleJobStore.ownedSchedulerCount >= maxActiveScheduler) {
    return "You have reached the maximum number of schedulers!";
  }
  return undefined;
});

export const getSubmitScheduleJobRequest = computed(() => {
  const { selectedScheduledJob, pageMode } = scheduleJobStore;

  const jobNameError = getScheduleJobNameError.get();

  if (jobNameError) {
    return {
      isDisabled: true,
      text: jobNameError,
    };
  }
  if (pageMode === "Create") {
    const createError = [
      getStartTimeError.get(),
      getIntervalsError.get(),
      getSchedulerQuotaError.get(),
    ].filter((error) => error !== undefined);
    if (createError.length > 0) {
      return {
        isDisabled: true,
        text: createError.join("\n"),
      };
    }
    return {
      isDisabled: false,
      text: JSON.stringify(selectedScheduledJob, null, 2),
    };
  }

  const editError = [
    jobNameError,
    getNoChangeError.get(),
    getIntervalsError.get(),
  ].filter((error) => error !== undefined);
  const isDisabled = editError.length > 0;

  const content = getScheduleJobSubmitContent.get();
  const contentText = isDisabled
    ? editError.join("\n")
    : JSON.stringify(content, null, 2);

  return {
    isDisabled: isDisabled,
    text: contentText,
  };
});

export const quickCreateScheduleJob = (job: Job) => {
  const additionalInfo: ScheduleJobAdditionInfo = {
    referJobId: job.ID,
  };
  const newEmptyJob: ScheduledJob = {
    Id: -1,
    Name: "<Create New Scheduled Job>",
    Settings: job.Settings ?? "",
    Status: "Active",
    IntervalInHours: 24,
    CreatedBy: store.account?.name ?? "",
    StartTime: "",
    EndTime: "",
    LastExecuteTime: "",
    LastUpdateBy: "",
    TemplateName: job.ExperimentName,
    Type: "Customized",
    EmailSubscriptionList: store.account?.username ?? "",
    Message: JSON.stringify(additionalInfo),
  } as const;

  updateSelectedScheduleJob(newEmptyJob);
  updateCurrentPath(`/scheduler/Create`);
  updateErrorMessage(undefined);
};

export const getReferedJob = computed(async () => {
  const { selectedScheduledJob } = scheduleJobStore;
  if (selectedScheduledJob === undefined) {
    return undefined;
  }

  const referJobId =
    parseJsonStrOptional(selectedScheduledJob.Message, ScheduleJobAdditionInfo)
      ?.referJobId ?? undefined;
  if (referJobId === undefined) {
    return undefined;
  }
  const referJob = await getJob({ jobId: referJobId?.toString() ?? "" });
  return referJob;
});

export const hasEditSchedulerAccess = (job: ScheduledJob) => {
  return (
    isDRI.get() ||
    job.EmailSubscriptionList?.includes(store.account?.username ?? "")
  );
};

export const hasActivateSchedulerAccess = (job: ScheduledJob) => {
  return job.EmailSubscriptionList?.includes(store.account?.username ?? "");
};

export const mapSchedulerStatus = (status: string | undefined) => {
  switch (status) {
    case "Canceled":
      return "Paused";
    default:
      return status;
  }
};
