import {
  Avatar,
  Body1,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import type { Job } from "@seval-portal/client-models";
import { observer } from "mobx-react-lite";
import { Tip } from "../../../../../components/Shared/Tip";

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("8px"),
  },
  body: {
    whiteSpace: "nowrap",
    ...shorthands.overflow("hidden"),
    textOverflow: "ellipsis",
  },
});

type JobCreatorCellProps = {
  job: Job;
};

export const JobCreatorCell = observer((props: JobCreatorCellProps) => {
  const styles = useStyles();
  const name = props.job.CreatedBy ?? "";

  return (
    <div className={styles.root}>
      <Avatar name={name} size={24} />
      <Tip withArrow content={name} relationship="label">
        <Body1 className={styles.body}>{name}</Body1>
      </Tip>
    </div>
  );
});
