import { computed } from "mobx";

import type { AccountInfo } from "@azure/msal-browser";
import type { Permission } from "@seval-portal/client-models";
import type { ClientFeatureFlagSets } from "@seval-portal/shared";
import { ClientFeatureFlagDefaults } from "@seval-portal/shared";
import { createStore } from "satcheljs";
import { v4 as uuidv4 } from "uuid";

export type HumanCorrelationStudyScenario = {
  path: string;
};

export const Themes = ["Light", "Dark"] as const;
export type Theme = (typeof Themes)[number];

export interface IStoreFeatures {
  default: ClientFeatureFlagSets;
  flights: ClientFeatureFlagSets;
  overrides: ClientFeatureFlagSets;
}

interface IStore {
  account: AccountInfo | undefined;
  tokenRefreshError: string | undefined;
  permission: Permission | undefined;
  consented: boolean | undefined;
  sessionId: string;
  features: IStoreFeatures;
  sidePane: {
    render: (() => JSX.Element) | undefined;
    size: "narrow" | "wide";
  };
  develop: {
    isSettingSubstrateToken: boolean;
    isSettingScrapingServiceToken: boolean;
    isSettingHeronAccessToken: boolean;
    isSettingAvalonAccessToken: boolean;
    tokenResolver: ((token: string) => void) | undefined;
  };
}

export const initialStore: IStore = {
  account: undefined,
  tokenRefreshError: undefined,
  permission: undefined,
  consented: undefined,
  sessionId: uuidv4(),
  features: {
    default: ClientFeatureFlagDefaults,
    flights: {},
    overrides: {},
  },
  sidePane: {
    render: undefined,
    size: "wide",
  },
  develop: {
    isSettingSubstrateToken: false,
    isSettingScrapingServiceToken: false,
    isSettingHeronAccessToken: false,
    isSettingAvalonAccessToken: false,
    tokenResolver: undefined,
  },
};

export const store = createStore<IStore>(
  "store",
  Object.assign({}, initialStore),
)();

export const computedUserId = computed(() => store.account?.idTokenClaims?.oid);
