import { store } from "@seval-portal/client-store";
import { mutatorAction } from "satcheljs";

export const openSidePane = mutatorAction(
  "openSidePane",
  (render: () => JSX.Element, size: "narrow" | "wide" = "wide") => {
    store.sidePane.render = render;
    store.sidePane.size = size;
  },
);

export const closeSidePane = mutatorAction("closeSidePane", () => {
  store.sidePane.render = undefined;
});
