import type { FeatureContribution } from "@seval-portal/client-models";
import { getRandomUUID } from "@seval-portal/client-utils";
import type { ContributionIdentity } from "./ContributionIdentity";
import type {
  FeatureDebugContribution,
  FeatureDebugGroupContribution,
} from "./FeatureDebugContribution";
import type { FeatureSettingsContribution } from "./FeatureSettingsContribution";

type ContributionRegistry = FeatureContribution & ContributionIdentity;
type SettingsContributionRegistry = FeatureSettingsContribution &
  ContributionIdentity;
type DebugContributionRegistry = FeatureDebugContribution &
  ContributionIdentity;
type DebugGroupContributionRegistry = {
  isEnabled: () => boolean;
  groupName: string;
  debugContributions: DebugContributionRegistry[];
} & ContributionIdentity;

export const registeredContributions: ContributionRegistry[] = [];
export const registeredSettingsContribution: SettingsContributionRegistry[] =
  [];
export const registeredDebugGroupContributions: DebugGroupContributionRegistry[] =
  [];

export const registerContribution = (contribution: FeatureContribution) => {
  registeredContributions.push({
    ...contribution,
    guid: getRandomUUID(),
  });
};

export const registerSettingsContribution = (
  contributions: FeatureSettingsContribution[],
) => {
  contributions.forEach((contribution) => {
    registeredSettingsContribution.push({
      ...contribution,
      guid: getRandomUUID(),
    });
  });
};

export const registerDebugGroupContribution = (
  contributions: FeatureDebugGroupContribution[],
) => {
  contributions.forEach((contribution) => {
    registeredDebugGroupContributions.push({
      ...contribution,
      debugContributions: contribution.debugContributions.map((_) => ({
        ..._,
        guid: getRandomUUID(),
      })),
      guid: getRandomUUID(),
    });
  });
};
