import type { AccountInfo } from "@azure/msal-browser";
import { store } from "@seval-portal/client-store";
import { mutatorAction } from "satcheljs";

export const updateAccountInfo = mutatorAction(
  "updateAccountInfo",
  (account: AccountInfo) => {
    store.account = account;
  },
);
