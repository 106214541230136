import {
  Body1,
  Button,
  Text,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import {
  ArrowDownloadFilled,
  Delete24Filled,
  DocumentTs16Regular,
  ErrorCircle16Regular,
} from "@fluentui/react-icons";

import {
  fileUrlPrefix_CWCQuerySet_new,
  getAppEnv,
  getSpecificWebEndpoint,
  telemetryHelper,
} from "@seval-portal/client-utils";
import { observer } from "mobx-react-lite";
import { Tip } from "../../../../components/Shared/Tip";
import { useToast } from "../../../../components/Wrappers/ToasterProvider";
import {
  deleteQuerySet,
  getAzureMLFileContent,
  getQuerySetContent,
} from "../../../../helpers/apiHelper";
import { downloadFile, getFileBasename } from "../../helpers/downloadHelper";
import { generateFormattedDateStringForQuerySet } from "../../helpers/formatHelper";
import { getCreatorName } from "../../helpers/querySetHelper";
import type { QuerySetData, QuerySetDataSource } from "../../models/QuerySets";
import { GeneralTable } from "../JobList/GeneralTable";
import { QuerySetStatusView } from "./QuerySetStatusView";

const useStyles = makeStyles({
  body: {
    whiteSpace: "nowrap",
    ...shorthands.overflow("hidden"),
    textOverflow: "ellipsis",
  },
  querySetNameCellBody: {
    maxLines: 2,
    ...shorthands.overflow("hidden"),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textOverflow: "ellipsis",
    textAlign: "start",
    width: "100%",
    height: "24px",
    ":hover Button": {
      color: tokens.colorBrandBackground,
    },
    ":not(:hover) Button": {
      color: "transparent",
    },
  },
  querySetName: {
    ...shorthands.overflow("hidden"),
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "flex",
  },
  downloadButton: {
    flexShrink: 0,
    marginLeft: "auto",
  },
  baseText: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    fontStyle: "normal",
  },
  documentIcon: {
    flexShrink: 0,
    ...shorthands.margin("0px", "15px", "-3px", "10px"),
  },
  generationJob: {
    color: tokens.colorBrandForeground1,
    ...shorthands.textDecoration("underline"),
    cursor: "pointer",
  },
  errorName: {
    ...shorthands.padding("0px", "5px", "0px", "5px"),
    color: tokens.colorPaletteRedForeground1,
  },
});

interface QuerySetsTableProps {
  querySets: QuerySetData[];
  onRefreshQuerySets: () => void;
}

export const QuerySetsTable = observer((props: QuerySetsTableProps) => {
  const styles = useStyles();
  const toast = useToast();

  const handleDownloadButtonClick = (filename: string, source: string) => {
    toast.onToastStart("Fetching file...");
    telemetryHelper.logUserActionEvent("DownloadQuerySet", { filename });

    if (source === "Bing") {
      getAzureMLFileContent(`${fileUrlPrefix_CWCQuerySet_new}/${filename}`)
        .then((response) => {
          downloadFile(response, getFileBasename(filename));
          toast.onToastSuccess("File fetched successfully, start downloading");
          telemetryHelper.logUserActionEvent("DownloadQuerySetSuccess", {
            filename,
          });
        })
        .catch((error) => {
          toast.onToastFailure(
            `File fetched failed with message: ${error.message}`,
          );
          telemetryHelper.logUserActionEvent("DownloadQuerySetFailure", {
            filename,
            message: error.message,
          });
        });
    } else {
      getQuerySetContent({ filename })
        .then((response) => {
          downloadFile(response, getFileBasename(filename));
          toast.onToastSuccess("File fetched successfully, start downloading");
          telemetryHelper.logUserActionEvent("DownloadQuerySetSuccess", {
            filename,
          });
        })
        .catch((error) => {
          toast.onToastFailure(
            `File fetched failed with message: ${error.message}`,
          );
          telemetryHelper.logUserActionEvent("DownloadQuerySetFailure", {
            filename,
            message: error.message,
          });
        });
    }
  };

  const getSource = (itemSource: QuerySetDataSource) => {
    switch (itemSource) {
      case "bingGeneration":
      case "bing":
        return "Bing";

      default:
        return "Seval";
    }
  };

  const handleDeleteButtonClick = (
    filename: string,
    itemSource: QuerySetDataSource,
  ) => {
    toast.onToastStart("Deleting file...");
    telemetryHelper.logUserActionEvent("DeleteQuerySet", { filename });
    deleteQuerySet({
      file: "queryset/" + filename,
      source: getSource(itemSource),
    })
      .then(() => {
        toast.onToastSuccess("File deleted successfully");
        telemetryHelper.logUserActionEvent("DeleteQuerySetSuccess", {
          filename,
        });
        props.onRefreshQuerySets();
      })
      .catch((error) => {
        toast.onToastFailure(
          `File deletion failed with message: ${error.message}`,
        );
        telemetryHelper.logUserActionEvent("DeleteQuerySetFailure", {
          filename,
          message: error.message,
        });
      });
  };

  const shouldShowLink = (item: QuerySetData) => {
    switch (item.source) {
      case "bingGeneration":
      case "bizchatGeneration":
        return true;

      case "bing":
      case "bizchat":
      case "public":
        return false;
    }
  };

  const shouldShowDownloadButton = (item: QuerySetData) => {
    switch (item.source) {
      case "bingGeneration":
        return false;

      case "bizchatGeneration":
        return item.file.status === "Completed";

      case "bing":
      case "bizchat":
      case "public":
        return true;
    }
  };

  const shouldShowDeleteButton = (item: QuerySetData) => {
    switch (item.source) {
      case "public":
      case "bingGeneration":
      case "bizchatGeneration":
        return false;

      case "bing":
      case "bizchat":
        return true;
    }
  };

  const getCreator = (item: QuerySetData) => {
    switch (item.source) {
      case "bingGeneration":
        return "Generation Job";

      case "bizchatGeneration":
      case "bing":
      case "bizchat":
      case "public":
        return getCreatorName(item.file.name);
    }
  };

  const invalidQuerySetNameMessage = `Invalid File Name, may cause job failure. Please re-upload the file with a valid name.`;
  const isValidBingQuerySetName = (item: QuerySetData) => {
    if (!shouldShowLink(item)) {
      if (item.source === "bing") {
        const regex = /^(.+)\/([^/]+)$/;
        const match = item.file.name.match(regex);
        if (match) {
          const fileName = match[2].replaceAll(".tsv", "");
          const regexStr = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
          if (!regexStr.test(fileName)) {
            return false;
          }
          return true;
        }
        return false;
      }
    }
    return true;
  };

  const getFileName = (option: string) => {
    const regex = /^(.+)\/([^/]+)$/;
    const match = option.match(regex);
    if (match) {
      const fileName = match[2].replaceAll(".tsv", "");
      const regexStr = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
      if (!regexStr.test(fileName)) {
        return (
          <div className={styles.querySetName}>
            <div className={styles.querySetName}>{fileName}</div>
            <ErrorCircle16Regular className={styles.errorName} />
          </div>
        );
      }
      return <div className={styles.querySetName}>{fileName}</div>;
    }
    return <div className={styles.querySetName} />;
  };

  const renderFileName = (item: QuerySetData) => {
    if (!shouldShowLink(item)) {
      if (item.source === "bing") {
        return getFileName(item.file.name);
      }
      return <div className={styles.querySetName}>{item.file.name}</div>;
    }

    return <></>;
  };

  const renderTable = () => (
    <GeneralTable
      loadMore={() => {
        return;
      }}
      hasMore={false}
      rows={props.querySets}
      columns={[
        {
          title: "QuerySetName",
          render: (item) => (
            <div
              className={mergeClasses(
                styles.querySetNameCellBody,
                styles.baseText,
              )}
            >
              <DocumentTs16Regular className={styles.documentIcon} />
              <Tip
                content={
                  isValidBingQuerySetName(item)
                    ? item.file.name
                    : invalidQuerySetNameMessage
                }
                relationship="label"
              >
                <div style={{ overflow: "hidden" }}>
                  {renderFileName(item)}
                  {shouldShowLink(item) && (
                    <div
                      className={styles.generationJob}
                      onClick={() => {
                        const endpoint = (() => {
                          if (item.file.type !== undefined) {
                            return getSpecificWebEndpoint(item.file.type);
                          } else {
                            return getAppEnv().webEndpoint;
                          }
                        })();

                        window.open(
                          `${endpoint}/detail/${item.file.id}`,
                          "_blank",
                        );
                      }}
                    >{`${item.file.name}`}</div>
                  )}
                </div>
              </Tip>
              <div className={styles.downloadButton}>
                <>
                  {shouldShowDownloadButton(item) && (
                    <Button
                      aria-label="Download Query Set"
                      className={styles.downloadButton}
                      appearance="transparent"
                      icon={<ArrowDownloadFilled />}
                      onClick={() =>
                        handleDownloadButtonClick(
                          item.file.name,
                          getSource(item.source),
                        )
                      }
                    />
                  )}
                  {shouldShowDeleteButton(item) && (
                    <Button
                      aria-label="Delete Query Set"
                      className={styles.downloadButton}
                      appearance="transparent"
                      icon={<Delete24Filled />}
                      onClick={() =>
                        handleDeleteButtonClick(item.file.name, item.source)
                      }
                    />
                  )}
                </>
              </div>
            </div>
          ),
          weight: 5,
          minWidth: "600px",
        },
        {
          title: "CreatedBy",
          render: (item) => {
            const creator = getCreator(item);
            return (
              <Tip content={creator} relationship="label">
                <Body1 className={styles.body}>{creator}</Body1>
              </Tip>
            );
          },
          weight: 2,
          minWidth: "100px",
        },
        {
          title: "Date",
          render: (item) => {
            const timeString = item.file.lastModified ?? item.file.createdOn;

            if (!timeString) {
              return <></>;
            }

            const date = new Date(timeString);
            const dateString = generateFormattedDateStringForQuerySet(date);

            return (
              <Tip content={dateString} relationship="label">
                <Body1 className={styles.body}>{dateString}</Body1>
              </Tip>
            );
          },
          weight: 1,
          minWidth: "200px",
        },
        {
          title: "",
          render: (item) => {
            return <QuerySetStatusView status={item.file.status} />;
          },
          weight: 1,
          minWidth: "100px",
        },
      ]}
    />
  );

  const renderEmptyTable = () => (
    <div>
      <Text>No query sets matching found</Text>
    </div>
  );

  return <>{props.querySets.length > 0 ? renderTable() : renderEmptyTable()}</>;
});
