import {
  Button,
  Field,
  Input,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import {
  CopyAddRegular,
  Delete16Regular,
  Edit16Regular,
} from "@fluentui/react-icons";
import { store } from "@seval-portal/client-store";
import { observer } from "mobx-react-lite";
import { v4 as uuidv4 } from "uuid";
import {
  onAddPipelineClick,
  onDeletePipelineClick,
  onDupPipelineClick,
  onEditPipelineClick,
  onProductNameChange,
} from "../../actions/productSettingsActions";
import {
  getCurrentPipelinesError,
  getProductNameError,
} from "../../selectors/productSelectors";
import { productSettingsStore } from "../../store/productSettingsStore";
import { GeneralTable } from "../JobList/GeneralTable";
import { useStyle } from "./sharedStyles";

export const ProductEditView = observer(() => {
  const styles = useStyle();
  const scenario = productSettingsStore.scenario;

  if (
    scenario.scenarioType !== "EditingProduct" ||
    scenario.currentPipeline !== undefined
  ) {
    return <></>;
  }

  return (
    <div className={styles.card}>
      <Field
        required
        label={<div className={styles.fieldLabel}>Product Name</div>}
        validationMessage={getProductNameError.get()}
        validationState={getProductNameError.get() ? "error" : undefined}
      >
        <Input
          className={styles.input}
          size="medium"
          value={scenario.currentProductName}
          onChange={(_, data) => onProductNameChange.bind(null, data.value)()}
        />
      </Field>
      <Field
        label={
          <div className={styles.fieldLabelRow}>
            <div className={styles.fieldLabel}>Templates</div>
            <Menu>
              <MenuTrigger>
                <Button>Add Pipeline</Button>
              </MenuTrigger>
              <MenuPopover className={styles.menu}>
                <MenuList>
                  {productSettingsStore.templates.map((pipeline) => {
                    return (
                      <MenuItem
                        key={pipeline.Id}
                        onClick={onAddPipelineClick.bind(null, {
                          id: uuidv4(),
                          displayName: pipeline.ExperimentName,
                          pipelineExpName: pipeline.ExperimentName,
                          createdTime: new Date().toISOString(),
                          creatorId: store.account?.localAccountId ?? "",
                          lastUpdatedTime: new Date().toISOString(),
                          isActive: true,
                        })}
                      >
                        {pipeline.ExperimentName}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </MenuPopover>
            </Menu>
          </div>
        }
        validationMessage={getCurrentPipelinesError.get()}
        validationState={getCurrentPipelinesError.get() ? "error" : undefined}
      >
        <div className={styles.table}>
          <GeneralTable
            loadMore={() => {
              return;
            }}
            hasMore={false}
            rows={scenario.currentPipelines}
            columns={[
              {
                title: "Name",
                render: (pipeline) => {
                  return <div>{pipeline.displayName}</div>;
                },
                weight: 6,
                minWidth: "0px",
              },
              {
                title: "",
                render: (pipeline) => {
                  return (
                    <div className={styles.pipelineButtonsRow}>
                      <Button
                        aria-label="Dup Template"
                        appearance="transparent"
                        size="small"
                        onClick={onDupPipelineClick.bind(null, pipeline)}
                        icon={<CopyAddRegular />}
                      />
                      <Button
                        aria-label="Edit Template SettingsOverride"
                        appearance="transparent"
                        size="small"
                        onClick={onEditPipelineClick.bind(null, pipeline)}
                        icon={<Edit16Regular />}
                      />
                      <Button
                        aria-label="Delete Template"
                        appearance="transparent"
                        size="small"
                        onClick={onDeletePipelineClick.bind(null, pipeline)}
                        icon={<Delete16Regular />}
                      />
                    </div>
                  );
                },
                weight: 1,
                minWidth: "0px",
              },
            ]}
          />
        </div>
      </Field>
    </div>
  );
});
