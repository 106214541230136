import type { Permission } from "@seval-portal/client-models";
import { store } from "@seval-portal/client-store";
import { mutatorAction } from "satcheljs";

export const updatePermission = mutatorAction(
  "updatePermission",
  (permission: Permission) => {
    store.permission = permission;
  },
);
