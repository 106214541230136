import {
  JobPreValidationResult,
  JobPreValidationResults,
} from "@seval-portal/client-models";
import { isFeatureEnabled } from "@seval-portal/client-store";
import type {
  BizChatExp,
  PreSubmitJobRouterRequestData,
} from "@seval-portal/shared";
import {
  checkOnlineQuerySetContent,
  preSubmitJob,
} from "../../../helpers/apiHelper";
import { JobPreValidationResponse } from "../models/JobPreValidationResponse";
import { updateJobValidationStepStatus } from "../mutators/jobPreValidationMutators";
import { getInputFile, getUserId } from "../selectors/getJobPropV2";
import { jobPreValidationStore } from "../store/jobPreValidationStore";

export const getUserIds = async () => {
  let userIds: string[] = [];
  const inputFile = getInputFile.get();
  // ignore the input file if it's start with data/ as it's comes from sydneyEval repo
  if (inputFile && !inputFile.startsWith("data/")) {
    await checkOnlineQuerySetContent({
      FileName: inputFile,
      UserId: getUserId.get(),
      TemplateName: jobPreValidationStore.jobTemplateName,
    })
      .then((response) => {
        userIds = response.userIds;
      })
      .catch((error) => {
        throw new Error(
          `The query set content validation failed with ${error}`,
        );
      });
  }
  return userIds;
};

const validateJob = async (
  preSubmitJobParams: PreSubmitJobRouterRequestData,
) => {
  return await preSubmitJob(preSubmitJobParams);
};

export const getOptionSets = (config: BizChatExp): string | undefined => {
  if (config.sydney?.url) {
    return config.sydney?.option_sets;
  }
  return "enterprise_flux_web,enterprise_flux_work,enterprise_toolbox_with_skdsstore,disable_action_confirmation";
};

export const validateJobAsync = (
  config: BizChatExp,
  userId: string,
  optionSets?: string,
) => {
  const preSubmitJobParams: PreSubmitJobRouterRequestData = {
    experimentId: config.exp_name ?? "",
    userid: userId,
    optionSets: optionSets,
    serviceSettings: config,
  };

  return Promise.resolve(validateJob(preSubmitJobParams));
};

export const handleValidationError = (stepIndex: number, error: string) => {
  const responseResults = JobPreValidationResults([], "responseResultsParser");
  const validationResult = {
    responseBody: error,
  };
  const validationResultParser = JobPreValidationResult(
    validationResult,
    "validationResultParser",
  );
  responseResults.push(validationResultParser);
  updateJobValidationStepStatus(stepIndex, "scrapping failed", responseResults);
};

export const skipValidationStep = (stepIndex: number, message: string) => {
  const responseResults = JobPreValidationResults([], "responseResultsParser");
  const validationResult = {
    responseBody: message,
  };
  const validationResultParser = JobPreValidationResult(
    validationResult,
    "validationResultParser",
  );
  responseResults.push(validationResultParser);
  updateJobValidationStepStatus(stepIndex, "skipped", responseResults);
};

export const processResult = (
  result: PromiseSettledResult<object>,
  responseResults: JobPreValidationResult[],
): boolean => {
  if (result.status === "fulfilled") {
    const JobPreValidationResponseParser = JobPreValidationResponse(
      result.value,
      "JobPreValidationResponseParser",
    );
    const response = JobPreValidationResponseParser;
    if (response && typeof response === "object") {
      const validationResult = {
        statusCode: response.verificationStatusCode,
        responseBody: response.response ?? "",
        sydney_url: response.sydney_url ?? "",
        scrap_user_id: response.oid ?? "",
        scrap_user: response.user ?? "",
        client_request_id: response.client_request_id ?? "",
        request: response.request ?? {},
      };
      const validationResultParser = JobPreValidationResult(
        validationResult,
        "validationResultParser",
      );
      responseResults.push(validationResultParser);
    }
    if (isFeatureEnabled("scraping-service-migration")) {
      return [200, 401].includes(
        JobPreValidationResponseParser.verificationStatusCode,
      );
    }
    return JobPreValidationResponseParser.verificationStatusCode === 200;
  }
  return false;
};
