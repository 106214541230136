import type {
  AccordionToggleEvent,
  RadioGroupOnChangeData,
} from "@fluentui/react-components";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Button,
  Field,
  InfoLabel,
  Input,
  Radio,
  RadioGroup,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { Copy16Regular, Delete16Regular } from "@fluentui/react-icons";
import { unifiedMetaData } from "@seval-portal/client-models";
import type { BizChatExp } from "@seval-portal/shared";
import { observer } from "mobx-react-lite";
import React from "react";
import { ResponsiveRow } from "../../../../../components/Responsive/ResponsiveRow";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { generateDefaultExpConfig } from "../../../../../helpers/configConvertHelper";
import { updatePropValueActionV2 } from "../../../actions/jobActions";
import { getJobErrorByPath } from "../../../selectors/creationError/getJobCreationError";
import { getValue } from "../../../selectors/getJobPropV2";
import { JobEnginesJsonFieldView } from "../components/JobEnginesJsonFieldView";

const useStyles = makeResponsiveStyles(
  {
    root: {},
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    experimentPairBlock: {
      ...shorthands.gap("20px"),
      ...shorthands.padding("16px", "16px", "16px", "16px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
  },
  {
    xs: {
      root: {},
    },
  },
);

interface ExperimentBlockProps {
  experiment: BizChatExp;
  index: number;
}

const ExperimentBlock: React.FC<ExperimentBlockProps> = ({
  experiment,
  index,
}) => {
  const [serviceType, setServiceType] = React.useState<"work" | "web">("work");
  const nameError = getJobErrorByPath(`exp_configs_unified[${index}].exp_name`);
  const urlError = getJobErrorByPath(
    `exp_configs_unified[${index}].sydney.url`,
  );
  const isControl = index % 2 === 0;
  const styles = useStyles();

  const onChangeServiceType = (
    e: React.FormEvent<HTMLDivElement>,
    data: RadioGroupOnChangeData,
  ) => {
    setServiceType(data.value as "work" | "web");
    const scenarioList =
      getValue("queries.query_sets")?.map(
        (querySet) => querySet.querySet_type || "",
      ) || [];
    const newDefaultConfig = generateDefaultExpConfig(
      scenarioList,
      unifiedMetaData,
      data.value as "work" | "web",
    );
    updatePropValueActionV2({
      prop: `exp_configs_unified[${index}].sydney`,
      newData: newDefaultConfig,
    });
    updatePropValueActionV2({
      prop: `exp_configs_unified[${index}].sydney.service_type`,
      newData: data.value,
    });
  };

  return (
    <div className={styles.block}>
      <div
        className={styles.blockTitle}
        style={{ display: "flex", alignItems: "center" }}
      >
        <span style={{ marginRight: "16px" }}>
          {isControl ? "Control" : "Treatment"}
        </span>
        <RadioGroup
          layout="horizontal"
          value={serviceType}
          onChange={onChangeServiceType}
        >
          <Radio value="work" label="Work" />
          <Radio value="web" label="Web" />
        </RadioGroup>
      </div>
      <Field
        validationState={nameError?.message ? "error" : "none"}
        validationMessage={nameError?.message}
      >
        <InfoLabel required info="Name of the experiment.">
          Exp_name:
        </InfoLabel>
        <Input
          value={experiment.exp_name}
          onChange={(_, data) => {
            const path = `exp_configs_unified[${index}].exp_name` as const;
            updatePropValueActionV2({ prop: path, newData: data.value });
          }}
        />
      </Field>
      <Field
        validationState={urlError?.message ? "error" : "none"}
        validationMessage={urlError?.message}
      >
        <InfoLabel required info="URL used for scraping.">
          URL:
        </InfoLabel>
        <Input
          value={experiment.sydney?.url}
          onChange={(_, data) => {
            const path = `exp_configs_unified[${index}].sydney.url` as const;
            updatePropValueActionV2({ prop: path, newData: data.value });
          }}
        />
      </Field>
      <InfoLabel info="Option sets for the experiment.">Option_sets:</InfoLabel>
      <Input
        value={experiment.sydney?.option_sets}
        onChange={(_, data) => {
          const path =
            `exp_configs_unified[${index}].sydney.option_sets` as const;
          updatePropValueActionV2({ prop: path, newData: data.value });
        }}
      />
      {serviceType === "work" && (
        <>
          <InfoLabel info="Variants for the experiment.">Variants:</InfoLabel>
          <Input
            value={experiment.sydney?.variants}
            onChange={(_, data) => {
              const path =
                `exp_configs_unified[${index}].sydney.variants` as const;
              updatePropValueActionV2({ prop: path, newData: data.value });
            }}
          />
        </>
      )}
      <JobEnginesJsonFieldView
        fieldLabel="Plugins"
        fieldInfor="Plugins configuration for the experiment in JSON format"
        initValue={JSON.stringify(experiment.sydney?.plugins || {})}
        path={`exp_configs_unified[${index}].sydney.plugins` as const}
      />
    </div>
  );
};

interface JobMultiPairExperimentationViewProps {
  limitPair?: number;
  defaultOpenItems?: number[];
}

export const JobMultiPairExperimentationView = observer(
  ({
    limitPair = 1,
    defaultOpenItems,
  }: JobMultiPairExperimentationViewProps) => {
    const styles = useStyles();
    const experiments = (getValue("exp_configs_unified") as BizChatExp[]) || [];

    const [openPairs, setOpenPairs] = React.useState<number[]>(
      defaultOpenItems || [],
    );

    const handleAccordionToggle = (
      _event: AccordionToggleEvent,
      data: { openItems: number[] },
    ) => {
      setOpenPairs(data.openItems);
    };

    const querySetListList =
      getValue("queries.query_sets")?.map(
        (querySet) => querySet.querySet_type || "",
      ) || [];

    const handleAddExperimentPair = () => {
      const newExperimentPair: BizChatExp[] = [
        {
          exp_name: "control",
          is_baseline: true,
          sydney: generateDefaultExpConfig(
            querySetListList,
            unifiedMetaData,
            "work",
          ),
        },
        {
          exp_name: "treatment",
          is_baseline: false,
          sydney: generateDefaultExpConfig(
            querySetListList,
            unifiedMetaData,
            "work",
          ),
        },
      ];

      updatePropValueActionV2({
        prop: "exp_configs_unified",
        newData: [...experiments, ...newExperimentPair],
      });
    };

    const handleDeletePair = (pairStartIndex: number) => {
      const newExperiments = [...experiments];
      newExperiments.splice(pairStartIndex, 2);
      updatePropValueActionV2({
        prop: "exp_configs_unified",
        newData: newExperiments,
      });
    };

    const handleDuplicatePair = (pairStartIndex: number) => {
      // Check if adding a new pair would exceed the limit
      if (experiments.length >= limitPair * 2) {
        return;
      }
      // Duplicate the experiment pair
      const duplicatedPair = experiments.slice(
        pairStartIndex,
        pairStartIndex + 2,
      );
      updatePropValueActionV2({
        prop: "exp_configs_unified",
        newData: [...experiments, ...duplicatedPair],
      });
    };

    const renderExperimentPairs = () => {
      const pairs = [];
      for (let i = 0; i < experiments.length; i += 2) {
        const pairKey = i / 2;
        pairs.push(
          <AccordionItem key={pairKey} value={pairKey}>
            <AccordionHeader>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <span>Experiment Pair {i / 2 + 1}</span>
                <div style={{ display: "flex" }}>
                  {experiments.length < limitPair * 2 && (
                    <Copy16Regular
                      aria-label="Duplicate experiment pair"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDuplicatePair(i);
                      }}
                      style={{ cursor: "pointer", marginLeft: "8px" }}
                    />
                  )}
                  <Delete16Regular
                    aria-label="Delete experiment pair"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeletePair(i);
                    }}
                    style={{ cursor: "pointer", marginLeft: "8px" }}
                  />
                </div>
              </div>
            </AccordionHeader>
            <AccordionPanel>
              <div className={styles.experimentPairBlock}>
                <ResponsiveRow
                  maxColumnCount={2}
                  maxColumnCountSmall={1}
                  columnGap={32}
                  rowGap={20}
                >
                  <ExperimentBlock experiment={experiments[i]} index={i} />
                  {experiments.length > i + 1 && (
                    <ExperimentBlock
                      experiment={experiments[i + 1]}
                      index={i + 1}
                    />
                  )}
                </ResponsiveRow>
              </div>
            </AccordionPanel>
          </AccordionItem>,
        );
      }
      return pairs;
    };

    return (
      <div className={styles.block}>
        <InfoLabel className={styles.blockTitle} required>
          Experiment
        </InfoLabel>
        <Accordion
          openItems={openPairs}
          onToggle={handleAccordionToggle}
          collapsible
          multiple
        >
          {renderExperimentPairs()}
        </Accordion>
        {experiments.length < limitPair * 2 && (
          <div>
            <Button appearance="primary" onClick={handleAddExperimentPair}>
              Add Experiment
            </Button>
          </div>
        )}
      </div>
    );
  },
);
