import { Checkbox, Field, Input } from "@fluentui/react-components";
import type { JobTemplate } from "@seval-portal/client-models";
import { store } from "@seval-portal/client-store";
import { observer } from "mobx-react-lite";
import { v4 as uuidv4 } from "uuid";
import { ResponsiveRow } from "../../../../components/Responsive/ResponsiveRow";
import { Tip } from "../../../../components/Shared/Tip";
import { onProductNameChange } from "../../actions/productSettingsActions";
import { updateProductSettingScenario } from "../../mutators/updateProductSettings";
import {
  getCurrentPipelinesError,
  getProductNameError,
} from "../../selectors/productSelectors";
import { productSettingsStore } from "../../store/productSettingsStore";
import { useStyle } from "./sharedStyles";

export const ProductCreateView = observer(() => {
  const styles = useStyle();
  const scenario = productSettingsStore.scenario;
  switch (scenario.scenarioType) {
    case "Creating":
      break;
    default:
      return <></>;
  }

  const templates = productSettingsStore.templates;

  const isSelected = (pipeline: JobTemplate) => {
    return (
      scenario.currentPipelines.find(
        (_) => _.pipelineExpName === pipeline.ExperimentName,
      ) !== undefined
    );
  };

  return (
    <div className={styles.card}>
      <Field
        required
        label={<div className={styles.fieldLabel}>Product Name</div>}
        validationMessage={getProductNameError.get()}
        validationState={getProductNameError.get() ? "error" : undefined}
      >
        <Input
          className={styles.input}
          size="medium"
          value={scenario.currentProductName}
          onChange={(_, data) => onProductNameChange.bind(null, data.value)()}
        />
      </Field>
      <Field
        label={<div className={styles.fieldLabel}>Pipeline to Use</div>}
        validationMessage={getCurrentPipelinesError.get()}
        validationState={getCurrentPipelinesError.get() ? "error" : undefined}
      >
        <div className={styles.borderContainer}>
          <ResponsiveRow maxColumnCount={2} rowGap={12} columnGap={16}>
            {templates.map((template) => {
              return (
                <Checkbox
                  className={styles.checkbox}
                  id={`${template.Id}`}
                  key={template.Id}
                  checked={isSelected(template)}
                  onChange={(_, data) => {
                    if (data.checked) {
                      updateProductSettingScenario({
                        ...scenario,
                        currentPipelines: [
                          ...scenario.currentPipelines,
                          {
                            id: uuidv4(),
                            pipelineExpName: template.ExperimentName,
                            displayName: template.ExperimentName,
                            createdTime: new Date().toISOString(),
                            creatorId: store.account?.localAccountId ?? "",
                            isActive: true,
                            lastUpdatedTime: new Date().toISOString(),
                          },
                        ],
                      });
                    } else {
                      updateProductSettingScenario({
                        ...scenario,
                        currentPipelines: scenario.currentPipelines.filter(
                          (p) => p.pipelineExpName !== template.ExperimentName,
                        ),
                      });
                    }
                  }}
                  label={
                    <Tip
                      content={template.ExperimentName}
                      relationship={"label"}
                    >
                      <div>{template.ExperimentName}</div>
                    </Tip>
                  }
                />
              );
            })}
          </ResponsiveRow>
        </div>
      </Field>
    </div>
  );
});
