import { store } from "@seval-portal/client-store";
import { CONSENT_VERSION } from "@seval-portal/client-utils";
import { mutatorAction } from "satcheljs";

export const updateConsentStatus = mutatorAction(
  "updateConsentStatus",
  (version: number) => {
    store.consented = version >= CONSENT_VERSION;
  },
);
