import { store } from "@seval-portal/client-store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useToast } from "../../../../components/Wrappers/ToasterProvider";
import type { FeatureSettingsDialogContributionProps } from "../../../../contribution/FeatureSettingsContribution";
import { UploadingFileDialog } from "./UploadingFileDialog";

interface UploadCWCQuerySetSuccess {
  success?: () => void;
}

type UploadCWCQuerySetDialogProps = UploadCWCQuerySetSuccess &
  FeatureSettingsDialogContributionProps;

export const UploadCWCQuerySetDialog = observer(
  (props: UploadCWCQuerySetDialogProps) => {
    const toast = useToast();

    const [uploadFolderPath, setUploadFolderPath] = React.useState<string>("");

    useEffect(() => {
      if (!store.account?.localAccountId) {
        return;
      }
      setUploadFolderPath(store.account.localAccountId);
    }, [store.account]);

    return (
      <UploadingFileDialog
        type="CWC"
        subFolder={uploadFolderPath}
        isOpen={props.isOpen}
        onCancel={() => {
          props.close();
        }}
        onStart={() => {
          props.close();
          toast.onToastStart("Uploading file...");
        }}
        onSuccess={() => {
          if (props.success) {
            props.success();
          } else {
            toast.onToastSuccess("File uploaded successfully");
          }
        }}
        onFailure={(error) => {
          toast.onToastFailure(
            `File upload failed with message: ${error.message}`,
          );
        }}
      />
    );
  },
);
