import type { Job } from "@seval-portal/client-models";
import { type ScheduledJob } from "@seval-portal/shared";
import { createStore } from "satcheljs";

interface IScheduleStore {
  pageMode: "View" | "Edit" | "Create" | "ReadOnly";
  isLoading: boolean;
  searchCriteria: Map<string, string>;
  activeScheduleJobs: ScheduledJob[];
  selectedScheduledJob: ScheduledJob | undefined;
  errorMessage: string | undefined;
  displayJobListLength: number;
  recentCompletedJobs: Job[];
  ownedSchedulerCount: number;
}

export const initScheduleJobStore: IScheduleStore = {
  pageMode: "View",
  isLoading: true,
  activeScheduleJobs: [],
  selectedScheduledJob: undefined,
  errorMessage: undefined,
  displayJobListLength: 20,
  searchCriteria: new Map<string, string>(),
  recentCompletedJobs: [],
  ownedSchedulerCount: 0,
};

export const scheduleJobStore = createStore<IScheduleStore>(
  "scheduleJobStore",
  Object.assign({}, initScheduleJobStore),
)();
