import type { AccordionToggleEventHandler } from "@fluentui/react-components";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Button,
  Checkbox,
  Field,
  InfoLabel,
  Input,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import React, { useEffect } from "react";

import { Delete16Regular } from "@fluentui/react-icons";
import { telemetryHelper } from "@seval-portal/client-utils";
import type { BingSydneyEngine, BingSydneyExp } from "@seval-portal/shared";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { updatePropValueActionV2 } from "../../../actions/jobActions";
import { getJobErrorByPath } from "../../../selectors/creationError/getJobCreationError";
import { getBingJobEngines } from "../../../selectors/getJobPropV2";
import { jobStore } from "../../../store/jobStore";
import { JobEnginesJsonFieldView } from "./JobEnginesJsonFieldView";

const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    rowWithSmallerGap: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      flexWrap: "wrap",
      ...shorthands.gap("8px"),
    },
    rowWithSmallGap: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      ...shorthands.gap("16px"),
    },
    columnWithSmallerGap: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      ...shorthands.gap("8px"),
    },
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    enginesContainer: {
      flexBasis: "44%",
      ...shorthands.gap("8px"),
      ...shorthands.borderRadius("8px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.padding("10px", "12px"),
      ...shorthands.margin("8px"),
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    engineRemoveButton: {
      marginLeft: "10px",
    },
    addEnginesContainer: {
      ...shorthands.borderRadius("8px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.padding("10px", "12px"),
      ...shorthands.margin("8px"),
      minWidth: "40%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    experimentationFieldContainer: {
      display: "flex",
      flexDirection: "row",
      ...shorthands.gap("8px"),
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    experimentationFieldTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      width: "110px",
    },
    note: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "13px",
      lineHeight: "20px",
      fontWeight: 400,
    },
    highlight: {
      ...shorthands.borderRadius("20px"),
      ...shorthands.padding("3px", "6px"),
      backgroundColor: tokens.colorBrandBackground2,
    },
    textBoxHeight: {
      height: "100px",
    },
    fieldStyle: { flexGrow: 1 },
    checkboxContainer: {
      flexBasis: "1",
      flexGrow: "1",
      display: "flex",
      flexDirection: "row",
      ...shorthands.gap("12px"),
      fontFamily: tokens.fontFamilyBase,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
    },
    checkbox: {
      accentColor: "#773CFC",
      '> input[type="checkbox"]:checked': {
        backgroundColor: "red",
        color: "red",
      },
    },
    baselineContainer: {
      ...shorthands.borderRadius("8px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.padding("10px", "12px"),
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

type UnionType = Omit<BingSydneyExp & BingSydneyEngine, "sydney">;
type UpdateAction = {
  [T in keyof UnionType]: {
    index: number;
    prop: T;
    newData: UnionType[T];
  };
}[keyof UnionType];

export const JobEnginesView = observer(() => {
  const styles = useStyles();

  const [openItems, setOpenItems] = React.useState([""]);
  const handleToggle: AccordionToggleEventHandler<string> = (event, data) => {
    setOpenItems(data.openItems);
  };

  // Reset open items when template changes
  useEffect(() => {
    setOpenItems([""]);
  }, [jobStore.selectedTemplate]);

  const engines = getBingJobEngines.get() ?? [];

  const addEngine = () => {
    const index = engines.length;
    let firstEngineSydney = undefined;
    if (engines.length > 0) {
      firstEngineSydney = engines[0].sydney;
    }
    const newEngine = {
      exp_name: "new engine",
      baseline_names: [],
      sydney: firstEngineSydney ?? {
        url: "https://<Your URL HERE>",
        option_sets: "",
        extra_headers: {},
        extra_params: {},
        config_params: {},
        plugins: [{}],
        options: {},
      },
    };

    updatePropValueActionV2({
      prop: `exp_configs[${index}]`,
      newData: newEngine,
    });

    setOpenItems(["engine_" + index]);
    telemetryHelper.logUserActionEvent("ClickAddEngine");
  };

  const removeEngine = (index: number) => {
    const targetEngine = engines[index];
    const currentEngines = get(jobStore.configuration, "exp_configs", []);
    if (!currentEngines) {
      return;
    }

    const newEngines = currentEngines.filter(
      (_, idx) => idx !== index,
    ) as BingSydneyEngine[];
    updatePropValueActionV2({
      prop: `exp_configs`,
      newData: newEngines,
    });
    newEngines.forEach((item, idx) => {
      const baseline_names = item.baseline_names ?? [];
      const newBaselineNames = baseline_names.filter(
        (value) => value !== targetEngine.exp_name,
      );

      updatePropValueActionV2({
        prop: `exp_configs[${idx}].baseline_names`,
        newData: newBaselineNames,
      });
    });

    setOpenItems([]);
    telemetryHelper.logUserActionEvent("ClickRemoveEngine");
  };

  const updateEnginesValue = (action: UpdateAction) => {
    if (action === undefined) {
      return;
    }
    const index = action.index;

    switch (action.prop) {
      case "exp_name":
        engines.forEach((engine, idx) => {
          const baseline_names = engine.baseline_names ?? [];
          if (
            idx !== index &&
            baseline_names.includes(engines[index].exp_name)
          ) {
            const newBaselineNames = baseline_names.map((name) =>
              name === engines[index].exp_name ? action.newData : name,
            );

            updatePropValueActionV2({
              prop: `exp_configs[${idx}].baseline_names`,
              newData: newBaselineNames,
            });
          }
        });
        updatePropValueActionV2({
          prop: `exp_configs[${index}].exp_name`,
          newData: action.newData,
        });
        break;
      case "url":
        updatePropValueActionV2({
          prop: `exp_configs[${index}].sydney.url`,
          newData: action.newData,
        });
        break;
      case "option_sets":
        updatePropValueActionV2({
          prop: `exp_configs[${index}].sydney.option_sets`,
          newData: action.newData,
        });
        break;
      case "options":
        updatePropValueActionV2({
          prop: `exp_configs[${index}].sydney.options`,
          newData: action.newData,
        });
        break;
      case "baseline_names":
        updatePropValueActionV2({
          prop: `exp_configs[${index}].baseline_names`,
          newData: action.newData,
        });
        break;
      case "extra_fields":
        updatePropValueActionV2({
          prop: `exp_configs[${index}].extra_fields`,
          newData: action.newData,
        });
        break;
    }

    telemetryHelper.logUserActionEvent("ClickUpdateEngine");
  };

  const renderBaselineBlock = (index: number, engine: BingSydneyEngine) => {
    const allEngines = engines
      .map((_) => _.exp_name)
      .filter((_) => _ !== engine.exp_name);

    if (allEngines.length === 0) {
      return null;
    }

    const rows: JSX.Element[] = [];
    for (let i = 0; i < allEngines.length; i++) {
      const key = allEngines[i];
      if (key === engine.exp_name) {
        continue;
      }
      const baseline_names = engine.baseline_names ?? [];
      const checkbox = (
        <div className={styles.checkboxContainer}>
          <Checkbox
            className={styles.checkbox}
            key={key}
            checked={baseline_names.includes(key)}
            onChange={(event) => {
              const checked = event.target.checked;
              if (checked) {
                updateEnginesValue({
                  index,
                  prop: "baseline_names",
                  newData: [...baseline_names, key],
                });
              } else {
                const others = baseline_names.filter((val) => val !== key);
                updateEnginesValue({
                  index,
                  prop: "baseline_names",
                  newData: others,
                });
              }
            }}
            label={key}
          />
        </div>
      );
      rows.push(
        <div className={styles.rowWithSmallGap} key={i}>
          {checkbox}
        </div>,
      );
    }

    return (
      <div className={styles.block}>
        <div className={styles.experimentationFieldTitle}>Baselines:</div>
        <div className={styles.baselineContainer}>{rows}</div>
      </div>
    );
  };

  const renderEnginesView = () => {
    return (
      <div className={styles.block}>
        <div className={styles.blockTitle}>Experiments</div>
        <div>
          <Accordion
            key="bing_engines"
            openItems={openItems}
            onToggle={handleToggle}
            multiple
            collapsible
          >
            {engines.map((engine, index) => {
              return (
                <AccordionItem
                  key={`exp_configs[${index}]`}
                  value={"engine_" + index}
                >
                  <AccordionHeader>
                    {engine.exp_name}
                    <Delete16Regular
                      data-testid="mock-remove-engine-button"
                      className={styles.engineRemoveButton}
                      onClick={() => removeEngine(index)}
                    />
                  </AccordionHeader>
                  <AccordionPanel>
                    <div
                      id={"engine_" + index}
                      className={styles.enginesContainer}
                    >
                      <InfoLabel
                        required
                        className={styles.experimentationFieldTitle}
                        info={"The name of engine."}
                      >
                        Exp_name:
                      </InfoLabel>
                      <Field
                        className={styles.fieldStyle}
                        validationState={
                          getJobErrorByPath(`exp_configs[${index}].exp_name`)
                            ?.message === undefined
                            ? "none"
                            : "error"
                        }
                        validationMessage={
                          getJobErrorByPath(`exp_configs[${index}].exp_name`)
                            ?.message
                        }
                      >
                        <Input
                          aria-label="The name of engine."
                          size="medium"
                          value={engine.exp_name}
                          onChange={(_, data) => {
                            updateEnginesValue({
                              index,
                              prop: "exp_name",
                              newData: data.value,
                            });
                          }}
                        />
                      </Field>
                      <InfoLabel
                        required
                        className={styles.experimentationFieldTitle}
                        info={"The url used for scraping."}
                      >
                        Endpoint:
                      </InfoLabel>
                      <Field
                        className={styles.fieldStyle}
                        validationState={
                          getJobErrorByPath(`exp_configs[${index}].sydney.url`)
                            ?.message === undefined
                            ? "none"
                            : "error"
                        }
                        validationMessage={
                          getJobErrorByPath(`exp_configs[${index}].sydney.url`)
                            ?.message
                        }
                      >
                        <Input
                          aria-label="The url used for scraping."
                          size="medium"
                          value={engine.sydney.url}
                          onChange={(_, data) => {
                            updateEnginesValue({
                              index,
                              prop: "url",
                              newData: data.value,
                            });
                          }}
                        />
                      </Field>
                      {renderBaselineBlock(index, engine)}
                      <InfoLabel
                        required
                        className={styles.experimentationFieldTitle}
                        info={"The option set for this engine."}
                      >
                        OptionSet:
                      </InfoLabel>
                      <Field
                        className={styles.fieldStyle}
                        validationState={
                          getJobErrorByPath(
                            `exp_configs[${index}].sydney.option_sets`,
                          )?.message === undefined
                            ? "none"
                            : "error"
                        }
                        validationMessage={
                          getJobErrorByPath(
                            `exp_configs[${index}].sydney.option_sets`,
                          )?.message
                        }
                      >
                        <Input
                          aria-label="The option set for this engine."
                          size="medium"
                          value={engine.sydney.option_sets}
                          onChange={(_, data) =>
                            updateEnginesValue({
                              index,
                              prop: "option_sets",
                              newData: data.value,
                            })
                          }
                        />
                      </Field>
                      <JobEnginesJsonFieldView
                        fieldLabel="Extra Headers"
                        fieldInfor="The extra headers in json format."
                        key={`exp_configs[${index}].sydney.extra_headers`}
                        initValue={JSON.stringify(
                          engine.sydney.extra_headers,
                          null,
                          2,
                        )}
                        path={`exp_configs[${index}].sydney.extra_headers`}
                        isRequired={false}
                      />
                      <JobEnginesJsonFieldView
                        fieldLabel="Extra Params"
                        fieldInfor="The extra params in json format."
                        key={`exp_configs[${index}].sydney.extra_params`}
                        initValue={JSON.stringify(
                          engine.sydney.extra_params,
                          null,
                          2,
                        )}
                        path={`exp_configs[${index}].sydney.extra_params`}
                        isRequired={false}
                      />
                      <JobEnginesJsonFieldView
                        fieldLabel="Config Params"
                        fieldInfor="The config params in json format."
                        key={`exp_configs[${index}].sydney.config_params`}
                        initValue={JSON.stringify(
                          engine.sydney.config_params,
                          null,
                          2,
                        )}
                        path={`exp_configs[${index}].sydney.config_params`}
                        isRequired={false}
                      />
                      <JobEnginesJsonFieldView
                        fieldLabel="Plugins"
                        fieldInfor="The plugins in json format, which is a list of key-value pairs."
                        key={`exp_configs[${index}].sydney.plugins`}
                        initValue={JSON.stringify(
                          engine.sydney.plugins,
                          null,
                          2,
                        )}
                        path={`exp_configs[${index}].sydney.plugins`}
                        isRequired={false}
                      />
                      <JobEnginesJsonFieldView
                        fieldLabel="Options"
                        fieldInfor="The options in json format."
                        key={`exp_configs[${index}].sydney.options`}
                        initValue={JSON.stringify(
                          engine.sydney.options,
                          null,
                          2,
                        )}
                        path={`exp_configs[${index}].sydney.options`}
                        isRequired={true}
                      />
                      <JobEnginesJsonFieldView
                        fieldLabel="Extra Fields"
                        fieldInfor="The extra fields in json format."
                        initValue={JSON.stringify(engine.extra_fields, null, 2)}
                        path={`exp_configs[${index}].extra_fields`}
                      />
                    </div>
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
          <Button
            appearance="primary"
            onClick={addEngine}
            data-testid="mock-add-engine-button"
          >
            Add Engines
          </Button>
        </div>
      </div>
    );
  };

  return <div>{renderEnginesView()}</div>;
});
