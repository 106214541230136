import {
  generateGetLocalCache,
  generateSetLocalCache,
} from "../../helpers/cacheHelper";
import type { ContributionServices } from "./ContributionServices";

export const getContributionServices = (
  contributionKey: string,
): ContributionServices => {
  return {
    getLocalCache: generateGetLocalCache(contributionKey),
    setLocalCache: generateSetLocalCache(contributionKey),
  };
};
