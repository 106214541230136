import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Dropdown,
  Input,
  makeStyles,
  Option,
  shorthands,
  Text,
} from "@fluentui/react-components";
import { perfWrapper } from "@seval-portal/client-utils";
import { observer } from "mobx-react-lite";
import * as React from "react";

import type { Job } from "@seval-portal/client-models";
import type { JobsRouterRequestData } from "@seval-portal/shared";
import { computed } from "mobx";
import { getJobs } from "../../../../helpers/apiHelper";
import { productSettingsStore } from "../../../OfflineEval/store/productSettingsStore";
import type { FeatureEvalExperiment } from "../../models/Experiment";
import { formatDatetime } from "../../utils/format";
import { PaginationTable } from "../common/PaginationTable";
import { StatusTag } from "../common/StatusTag";
const useStyle = makeStyles({
  root: {
    maxWidth: "800px",
  },
  dialogContent: {
    margin: "16px 0 ",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "8px",
    marginBottom: "16px",
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    width: "260px",
  },
  label: {
    ...shorthands.margin("5px", "0", "5px", "0"),
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    fontStyle: "normal",
    color: "#605E5C",
  },
  truncateCell: {
    display: "inline-block",
    width: "100%",
    overflow: "hidden",
  },
});
const Job_List_Page_Size = 10;
const statusOptions = [
  "All",
  "Running",
  "Created",
  "Submitted",
  "Completed",
  "Failed",
  "SubmitFailed",
  "Canceled",
  "CancelRequested",
  "Deleted",
  "DatasetNotFound",
];
export const ExperimentIterationDialog = observer(
  (props: {
    isOpen: boolean;
    defaultSelectedId?: string;
    onDismiss: (offline?: FeatureEvalExperiment) => void;
  }) => {
    const styles = useStyle();
    const jobTemplates = computed(() => productSettingsStore.templates);
    const { isOpen, defaultSelectedId, onDismiss } = props;
    const statusDropdownOptions = React.useMemo(() => {
      return statusOptions.map((v) => {
        return { key: v, value: v, name: v };
      });
    }, []);
    const [filterStatus, setFilterStatus] = React.useState("Completed");
    const [searchJobName, setSearchJobName] = React.useState("");
    const [selectedJob, setSelectedJob] = React.useState<Job | undefined>(
      defaultSelectedId
        ? {
            ID: Number(defaultSelectedId),
          }
        : undefined,
    );
    const listSelected = React.useMemo(() => {
      if (selectedJob?.ID) {
        return [`${selectedJob.ID}`];
      }
      return [];
    }, [selectedJob]);
    const [listLoading, setListLoading] = React.useState(false);
    const [pagedStartId, setPagedStartId] = React.useState<
      Array<number | undefined>
    >([undefined]);
    const [connectJobs, setConnectJobs] = React.useState<Job[]>([]);
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(Job_List_Page_Size);
    const [hasMore, setHasMore] = React.useState(true);
    const startId = pagedStartId[page - 1];
    const handleConnectOffline = React.useCallback(() => {
      const offlineExperiment = {
        Name: selectedJob?.JobName,
        Stage: "offline",
        ExternalId: `${selectedJob?.ID}`,
      };
      onDismiss(selectedJob ? offlineExperiment : undefined);
    }, [selectedJob, onDismiss]);
    React.useEffect(() => {
      const templateTypes = jobTemplates
        .get()
        .map((template) => template.Type)
        .filter((v) => ![undefined, "BingV2", "CWC", "OPG"].includes(v));
      const productFilter = Array.from(new Set(templateTypes));
      const params: JobsRouterRequestData = {
        PageSize: pageSize,
        Product: productFilter.length > 0 ? productFilter.join(",") : undefined,
      };
      if (searchJobName) {
        params.Keyword = searchJobName;
      }
      if (filterStatus && filterStatus !== "All") {
        params.JobStatus = filterStatus;
      }
      if (startId) {
        params.StartedId = startId;
      }
      setListLoading(true);
      perfWrapper(
        "LoadJobList",
        getJobs(params).then((jobs) => {
          const showJobs = jobs.slice(0, pageSize);
          setConnectJobs(showJobs);
          setListLoading(false);
          setPagedStartId((prev) => {
            if (prev[page] !== undefined) {
              return prev;
            }
            const newStartId = jobs[showJobs.length - 1]?.ID - 1;
            const newPagedStartId = [...prev];
            newPagedStartId[page] = newStartId;
            return newPagedStartId;
          });
          if (showJobs.length < pageSize) {
            setHasMore(false);
          }
        }),
      );
    }, [page, pageSize, searchJobName, filterStatus, startId]);
    const listProps = React.useMemo(() => {
      const header = [
        {
          key: "JobName",
          title: "Job name",
          minWidth: 286,
          width: 300,
          render: (item: Job) => (
            <Text
              className={styles.truncateCell}
              truncate
              wrap={false}
              title={item.JobName}
            >
              {item.JobName}
            </Text>
          ),
        },
        {
          key: "Status",
          title: "Job status",
          minWidth: 114,
          width: 114,
          render: (item: Job) => <StatusTag label={item.Status ?? ""} />,
        },
        {
          key: "CreatedAt",
          title: "Created at",
          minWidth: 160,
          width: 200,
          render: (item: Job) => <>{formatDatetime(item.CreatedAt ?? "")}</>,
        },
      ];
      return {
        header,
        data: connectJobs,
        keyName: "ID",
        loading: listLoading,
        options: {
          selection: true,
          pageSizes: [`${Job_List_Page_Size}`],
          hideTotal: true,
        },
        onRowClick: (item: Job) => {
          setSelectedJob(item);
        },
        pagination: {
          page,
          pageSize,
          hasMore,
        },
        onPageChange: (newPage: number) => {
          setPage(newPage);
        },
        onPageSizeChange: (result: number) => {
          setPageSize(result);
        },
      };
    }, [listLoading, page, pageSize, hasMore, connectJobs]);
    return (
      <Dialog
        modalType="modal"
        open={isOpen}
        onOpenChange={(_, data) => {
          if (data.open === true) {
            onDismiss();
          }
        }}
      >
        <DialogSurface className={styles.root}>
          <DialogBody>
            <DialogTitle>
              {"Connect to offline evaluation job"}
              <Text block={true}>
                Please select from one of the offline evaluation jobs and
                connect to current shadow AB.
              </Text>
            </DialogTitle>
            <DialogContent className={styles.dialogContent}>
              <div className={styles.rowContainer}>
                <div className={styles.columnContainer}>
                  <label className={styles.label}>Status</label>
                  <Dropdown
                    aria-label="Status Dropdown"
                    value={filterStatus}
                    onOptionSelect={(_, data) => {
                      setFilterStatus(data.optionValue ?? "");
                      setPage(1);
                      setPagedStartId([undefined]);
                    }}
                  >
                    {statusDropdownOptions.map((option) => (
                      <Option key={option.key} value={option.value}>
                        {option.name}
                      </Option>
                    ))}
                  </Dropdown>
                </div>
                <div className={styles.columnContainer}>
                  <label className={styles.label}>Job name</label>
                  <Input
                    value={searchJobName}
                    onChange={(_e, data) => {
                      setSearchJobName(data.value);
                      setPage(1);
                      setPagedStartId([undefined]);
                    }}
                  />
                </div>
              </div>
              <PaginationTable {...listProps} selectionKeys={listSelected} />
            </DialogContent>

            <DialogActions>
              <Button onClick={handleConnectOffline} appearance="primary">
                Connect
              </Button>
              <Button onClick={() => onDismiss()} appearance="secondary">
                Cancel
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  },
);
