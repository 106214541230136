import type { JobConfigurationUpdateAction } from "@seval-portal/client-models";
import { JobConfiguration } from "@seval-portal/shared";
import { get } from "lodash";
import { computed } from "mobx";
import { jobStore } from "../store/jobStore";
import { getWhetherUnifiedTemplate } from "./getTemplateType";

// fix error when P is an union type
type Handle<A extends JobConfigurationUpdateAction, P extends A["prop"]> =
  Extract<A, { prop: P }> extends infer T
    ? T extends { newData: infer D }
      ? D | undefined
      : never
    : never;

export type JobConfigurationValue<
  T extends JobConfigurationUpdateAction["prop"],
> = Handle<JobConfigurationUpdateAction, T> | undefined;

// Test cases
// type Test1 = Handle<JobConfigurationUpdateAction, "configs_in_json">;
// type Test2 = Handle<JobConfigurationUpdateAction, "configs_in_json.conversations">;
// type Test3 = Handle<JobConfigurationUpdateAction, "configs_in_json.conversations.user_id">;
// type Test4 = Handle<JobConfigurationUpdateAction, "exp_configs">;
// type Test5 = Handle<JobConfigurationUpdateAction, "exp_configs[0]">;
// type Test6 = Handle<JobConfigurationUpdateAction, "exp_configs[0].exp_name">;

export const getValue = <T extends JobConfigurationUpdateAction["prop"]>(
  path: T,
): Handle<JobConfigurationUpdateAction, T> => {
  const config = JobConfiguration(jobStore.configuration, "JobConfiguration");
  return get(config, path) as Handle<JobConfigurationUpdateAction, T>;
};

export const getUnifiedUserIdPath = computed(() => {
  const isConvergeTemplate = getWhetherUnifiedTemplate.get();
  return isConvergeTemplate
    ? "global_config.user_id"
    : "configs_in_json.conversations.user_id";
});

export const getUnifiedUserId = computed(() => {
  const userIdPath = getUnifiedUserIdPath.get();
  return getValue(userIdPath);
});

export const getUnifiedExpConfigPath = computed(() => {
  const isConvergeTemplate = getWhetherUnifiedTemplate.get();
  return isConvergeTemplate
    ? "exp_configs_unified"
    : "configs_in_json.conversations.exp_configs";
});

export const getUnifiedLMCheckListPath = computed(() => {
  const isConvergeTemplate = getWhetherUnifiedTemplate.get();
  return isConvergeTemplate
    ? "queries.lmchecklist_input_file"
    : "configs_in_json.conversations.lmchecklist_input_file";
});

export const getUnified3pPluginsPathMap = computed(() => {
  const isConvergeTemplate = getWhetherUnifiedTemplate.get();
  return {
    sideload_3p_plugins: isConvergeTemplate
      ? "global_config.sideload_3p_plugins"
      : "sideload_3p_plugins",
    unsideload_3p_plugins: isConvergeTemplate
      ? "global_config.unsideload_3p_plugins"
      : "unsideload_3p_plugins",
  } as const;
});

export const getUnifiedSBSExpPath = computed(() => {
  const isConvergeTemplate = getWhetherUnifiedTemplate.get();
  return isConvergeTemplate
    ? "global_config.create_sbs_experiment"
    : "create_sbs_experiment";
});

export const getBingJobEngines = computed(() => {
  return getValue("exp_configs");
});

export const getBingJobTestSetsListMultiTurn = computed(() => {
  return getValue("multi_turn");
});

export const getBingJobTestSetsListSingleTurn = computed(() => {
  return getValue("single_turn");
});

export const getCompeteTestSetsListMultiTurn = computed(() => {
  return getValue("compete.multi_turn");
});

export const getCompeteTestSetsListSingleTurn = computed(() => {
  return getValue("compete.single_turn");
});

export const getUserId = computed(() => {
  return getValue("configs_in_json.conversations.user_id");
});

export const getInputFile = computed(() => {
  return getValue("configs_in_json.conversations.input_file");
});

export const getInputFolder = computed(() => {
  return getValue("configs_in_json.conversations.input_folder");
});

export const getExpConfigs = computed(() => {
  return getValue("configs_in_json.conversations.exp_configs");
});

export const getEvalTimeOverride = computed(() => {
  return getValue("configs_in_json.conversations.eval_time_override");
});

export const getBizChatMetrics = computed(() => {
  return {
    scleo: getValue("configs_in_json.scleo"),
    apology: getValue("configs_in_json.apology"),
    stewieleo: getValue("configs_in_json.stewieleo"),
    pileo: getValue("configs_in_json.pileo"),
    groundleo: getValue("configs_in_json.groundleo"),
    lmchecklist: getValue("configs_in_json.lmchecklist"),
    stewieleosbs: getValue("configs_in_json.stewieleosbs"),
    tcr: getValue("configs_in_json.tcr"),
    groundleo_claimbreak: getValue("configs_in_json.groundleo_claimbreak"),
    groundleo_claimbreakv1_1: getValue(
      "configs_in_json.groundleo_claimbreakv1_1",
    ),
    sbsleov2: getValue("configs_in_json.sbsleov2"),
    sbsleo: getValue("configs_in_json.sbsleo"),
    reasoningleo: getValue("configs_in_json.reasoningleo"),
    teamsgroundleo: getValue("configs_in_json.teamsgroundleo"),
    stewieleov5: getValue("configs_in_json.stewieleov5"),
    codeleo: getValue("configs_in_json.codeleo"),
    pptstorytellerquality: getValue("configs_in_json.pptstorytellerquality"),
    screlevance: getValue("configs_in_json.screlevance"),
    DCG: getValue("configs_in_json.DCG"),
    NDCG: getValue("configs_in_json.NDCG"),
    Precision: getValue("configs_in_json.Precision"),
    Recall: getValue("configs_in_json.Recall"),
    CiteDCG: getValue("configs_in_json.CiteDCG"),
  };
});

export const getBingJobTestSetsList = computed(() => {
  return (
    getBingJobTestSetsListMultiTurn.get() ??
    getBingJobTestSetsListSingleTurn.get()
  );
});

export const getBingJobRunMode = computed(() => {
  if (getBingJobTestSetsListMultiTurn.get() !== undefined) {
    return "multi_turn" as const;
  } else {
    return "single_turn" as const;
  }
});

export const getCompeteRunMode = computed(() => {
  if (getCompeteTestSetsListMultiTurn.get() !== undefined) {
    return "multi_turn" as const;
  } else {
    return "single_turn" as const;
  }
});

export const getUnifiedBizChatJobRunMode = computed(() => {
  return getValue("queries.query_type");
});

export const getUnifiedBizChatJobQueryList = computed(() => {
  return getValue("queries.query_sets");
});

export const getBingCluster = computed(() => {
  return getValue("cluster") ?? "";
});

export const getBingScenario = computed(() => {
  return getValue("scenario") ?? "default";
});

export const getConfigure3pPlugins = computed(() => {
  return {
    sideload_3p_plugins: getValue("sideload_3p_plugins"),
    unsideload_3p_plugins: getValue("unsideload_3p_plugins"),
  };
});
