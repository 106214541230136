import {
  Body1,
  Button,
  Divider,
  MessageBar,
  MessageBarActions,
  MessageBarBody,
  MessageBarTitle,
  Text,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import {
  ArrowLeft24Filled,
  ArrowLeft24Regular,
  DismissRegular,
  bundleIcon,
} from "@fluentui/react-icons";
import {
  closeSidePane,
  openSidePane,
} from "../../../../../mutators/sidePaneMutators";

import { TemplateType } from "@seval-portal/shared";
import { observer } from "mobx-react-lite";
import React from "react";
import { getMetricsChangeLog } from "../../../../../helpers/apiHelper";
import type { MetricsChangeLog } from "../../../models/MetricsChangeLog";
import { resultStore } from "../../../store/resultStore";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  backButton: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
  title: {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
    ...shorthands.margin("0", "0", "0", "9px"),
  },
  updateTime: {
    fontSize: "12px",
    fontStyle: "normal",
    lineHeight: "10px",
    ...shorthands.margin("20px", "0", "0", "0"),
  },
});

export const MessageNotificationBar = observer(() => {
  const styles = useStyles();

  const BackIcon = bundleIcon(ArrowLeft24Filled, ArrowLeft24Regular);

  const [metricsLog, setMetricsLog] = React.useState<MetricsChangeLog[]>([]);

  React.useEffect(() => {
    getMetricsChangeLog().then((res) => {
      const logs = res ?? [];
      if (resultStore.resultJob?.JobTemplateType === TemplateType.AIHub) {
        logs.push({
          title:
            "The LLM NDCG metrics are not fully trustworthy due to a known signal parity issue, so they should not be used for decision making yet.",
          description: "",
          time: new Date().toLocaleDateString(),
          author: "Search eval team",
        });
      }
      setMetricsLog(logs);
    });
  }, []);

  const renderLogs = () => {
    return metricsLog.map((log: MetricsChangeLog, index) => {
      return (
        <div key={"metric_log_" + index}>
          <div className={styles.updateTime}>Updated at {log.time}</div>
          <h2>{log.title}</h2>
          <div>{log.description}</div>
          <br></br>
          <Divider />
        </div>
      );
    });
  };

  const renderMetricNotification = () => {
    return (
      <div className={styles.root}>
        <div className={styles.titleRow}>
          <div
            className={styles.backButton}
            role="button"
            aria-label="Back Button"
            onClick={() => {
              closeSidePane();
            }}
          >
            <BackIcon />
          </div>
          <Body1 className={styles.title}>
            Metrics Notification & Changelog
          </Body1>
        </div>
        {renderLogs()}
      </div>
    );
  };

  if (metricsLog.length === 0) {
    return <></>;
  }

  return (
    <MessageBar key={"warning"} intent="warning">
      <MessageBarBody>
        <MessageBarTitle>Metrics notification & Changelog</MessageBarTitle>
        {metricsLog.length > 0 && (
          <>
            <br />
            <Text>{metricsLog[0].title ?? ""}</Text>
          </>
        )}
      </MessageBarBody>
      <MessageBarActions
        containerAction={
          <Button
            onClick={() => setMetricsLog([])}
            aria-label="dismiss"
            appearance="transparent"
            icon={<DismissRegular />}
          />
        }
      >
        <Button onClick={() => openSidePane(() => renderMetricNotification())}>
          See More
        </Button>
      </MessageBarActions>
    </MessageBar>
  );
});
