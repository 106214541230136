import { AppVersion } from "./appVersion";

export type AppEnvType = "LOCALHOST" | "STAGING" | "PROD";

export interface AppJobOutputInfo {
  workspaceId: string;
  sandBoxId: string;
  subscriptionsId: string;
  resourcegroups: string;
  storageName: string;
}
interface AppEnv {
  type: AppEnvType;
  apiEndpoint: string;
  webEndpoint: string;
  directoryId: string;
  applicationId: string;
  jobOutputInfo: AppJobOutputInfo;
}

export const getAppVersion = (): string | undefined => {
  if (getAppEnv().type === "LOCALHOST") {
    return undefined;
  }

  return AppVersion;
};

const stagingJobOutputInfo = {
  7: {
    workspaceId: "72511fa5-eb91-7a44-a981-8dee288658f1",
    sandBoxId: "84f131cd-125a-4530-978b-6ea080634719",
    subscriptionsId: "95e0ae98-c287-4f51-b23e-4e2317b7e169",
    resourcegroups: "EYESON.HERON.PROD.a85ac184-a2ef-4007-bd2d-538bfd3d3319",
    storageName: "destadls2gfzppqu5lm3sek",
  },
  4: {
    workspaceId: "72511fa5-eb91-7a44-a981-8dee288658f1",
    sandBoxId: "73d24a11-822f-439f-acdb-a03bcffe34da",
    subscriptionsId: "60d27411-7736-4355-ac95-ac033929fe9d",
    resourcegroups: "MOP.HERON.PROD.7ded6b1c-fbea-4178-bf25-e67c5eedc54f",
    storageName: "destadls2gngvacfarslewo",
  },
  1: {
    workspaceId: "72511fa5-eb91-7a44-a981-8dee288658f1",
    sandBoxId: "11654f3c-742d-43d6-a448-a08bfbd1c8a3",
    subscriptionsId: "3e02707f-7ddb-4796-ad0f-32f4f4b22757",
    resourcegroups: "EYESON.HERON.PROD.f8feb566-64b3-4b38-8c12-794af9cf6d14",
    storageName: "destadls2gnc5ha7hf54ewm",
  },
};

const prodJobOutputInfo = {
  3: {
    workspaceId: "7c13cbc3-af07-621e-df1a-029a268048d4",
    sandBoxId: "a572d40a-63de-4b79-8672-2d83fed1d6d5",
    subscriptionsId: "60d27411-7736-4355-ac95-ac033929fe9d",
    resourcegroups: "MOP.HERON.PROD.548dfcbe-631f-48e6-af0e-c7a5229ee586",
    storageName: "destadls2gisg62ym3btsni",
  },
  1: {
    workspaceId: "7c13cbc3-af07-621e-df1a-029a268048d4",
    sandBoxId: "b3921763-b2ef-4c2b-88e9-16a08a57df0a",
    subscriptionsId: "3e02707f-7ddb-4796-ad0f-32f4f4b22757",
    resourcegroups: "EYESON.HERON.PROD.62dc7afd-e09c-4ef3-bd5d-cf81a738d9c9",
    storageName: "destadls2gp7rwdmnxntcpg",
  },
};

function getValueByKey(
  outputInfoDict: { [key: string]: any },
  key: number,
): any {
  return outputInfoDict[key];
}

export const getSpecificWebEndpoint = (env: "prod" | "staging"): string => {
  switch (env) {
    case "prod":
      return "https://seval.microsoft.com";
    case "staging":
      return "https://seval-staging.microsoft.com";
  }
};

export const getProductFileName = () => {
  switch (getAppEnv().type) {
    case "PROD":
      return "product_Prod.json";
    case "STAGING":
      return "product_Staging.json";
    default:
      return "product_Localhost.json";
  }
};

export const getAppEnv = (envId = 1): AppEnv => {
  switch ((window as unknown as { APP_ENV: string }).APP_ENV) {
    case "PRO.PROD":
      return {
        type: "PROD",
        apiEndpoint: `${window.location.origin}/api`,
        webEndpoint: window.location.origin,
        directoryId: "72f988bf-86f1-41af-91ab-2d7cd011db47",
        applicationId: "ced093fa-3078-45b7-8d28-bb7ba4554a77",
        jobOutputInfo: getValueByKey(prodJobOutputInfo, envId),
      };
    case "PRO.STAGING":
      return {
        type: "STAGING",
        apiEndpoint: `${window.location.origin}/api`,
        webEndpoint: window.location.origin,
        directoryId: "72f988bf-86f1-41af-91ab-2d7cd011db47",
        applicationId: "ced093fa-3078-45b7-8d28-bb7ba4554a77",
        jobOutputInfo: getValueByKey(stagingJobOutputInfo, envId),
      };
    default:
      return {
        type: "LOCALHOST",
        apiEndpoint: "http://localhost:4000/api",
        webEndpoint: "http://localhost:3000",
        directoryId: "72f988bf-86f1-41af-91ab-2d7cd011db47",
        applicationId: "5369873f-4827-48dc-805c-2fc2f3150340",
        jobOutputInfo: getValueByKey(stagingJobOutputInfo, envId),
      };
  }
};

export const getJobOutputInfoByStorageName = (
  storageName: string | undefined,
): AppJobOutputInfo | undefined => {
  if (storageName === undefined) {
    return undefined;
  }
  switch (getAppEnv().type) {
    case "PROD":
      return Object.values(prodJobOutputInfo).find((info) =>
        info.storageName.includes(storageName),
      );
    default:
      return Object.values(stagingJobOutputInfo).find((info) =>
        info.storageName.includes(storageName),
      );
  }
};
