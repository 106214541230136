import { Link } from "@fluentui/react-components";
import { Search16Regular } from "@fluentui/react-icons";
import type { MetricJudgementViewMetricKey } from "@seval-portal/client-utils";
import { observer } from "mobx-react";
import { useCallback } from "react";
import { openSidePane } from "../../../../../../mutators/sidePaneMutators";
import { getAnnotationDebugLink } from "../../../../helpers/renderMetricsHelper";
import { resultStore } from "../../../../store/resultStore";
import { MetricJudgementView } from "../../SidePane/MetricJudgementView";
import { tableCellSharedStyle } from "./tableCellSharedStyles";
interface IMChatMetricsValueCell {
  metricKey: string;
  expName: string;
  content: string;
  reasoningMetricKey: MetricJudgementViewMetricKey | undefined;
  query: string;
}

export const MChatMetricsValueCell = observer(
  (props: IMChatMetricsValueCell) => {
    const { metricKey, expName, content, reasoningMetricKey, query } = props;

    const styles = tableCellSharedStyle();

    const handleUterranceClick = useCallback(
      (
        selectedKey: MetricJudgementViewMetricKey | undefined,
        selectedQuery: string,
      ) => {
        if (selectedKey !== undefined) {
          openSidePane(() => {
            return (
              <MetricJudgementView
                query={selectedQuery}
                jobId={resultStore.resultJob?.JobId ?? ""}
                jobCreator={resultStore.resultJob?.CreatorSmtpAddress}
                metricKey={selectedKey}
                jobIdForShare={`${resultStore.resultJob?.ID}`}
              />
            );
          });
        }
      },
      [],
    );

    if (reasoningMetricKey === undefined) {
      const annotation_debuglink = getAnnotationDebugLink(query, expName);
      if (metricKey === "iterative_query_level_ndcg" && annotation_debuglink) {
        return (
          <Link target="_blank" href={annotation_debuglink}>
            <span>{content}</span>
            <Search16Regular className={styles.searchIcon} />
          </Link>
        );
      }
      return <>{content}</>;
    } else {
      return (
        <Link
          onClick={() => {
            handleUterranceClick(reasoningMetricKey, query);
          }}
        >
          <span>{content}</span>

          {content.length > 0 && (
            <Search16Regular className={styles.searchIcon} />
          )}
        </Link>
      );
    }
  },
);
