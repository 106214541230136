import type { Job, JobTemplate } from "@seval-portal/client-models";
import type { JobGroupData } from "@seval-portal/shared";
import { mutatorAction } from "satcheljs";
import { jobListStore } from "../store/jobListStore";

export const updateJobListViewMode = mutatorAction(
  "updateJobListViewMode",
  (mode: "job" | "group") => {
    jobListStore.mode = mode;
  },
);

export const updateJobTemplates = mutatorAction(
  "updateJobTemplates",
  (value: JobTemplate[]) => {
    jobListStore.jobTemplates = value;
  },
);

export const updateJobList = mutatorAction("updateJobList", (value: Job[]) => {
  jobListStore.jobs = value;
});

export const updateGroupList = mutatorAction(
  "updateGroupList",
  (value: JobGroupData[]) => {
    jobListStore.groups = value;
  },
);

export const updateExpandedIndex = mutatorAction(
  "updateExpandedIndex",
  (index: number[]) => {
    jobListStore.groupExpandIndex = index;
  },
);
