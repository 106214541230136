import { observer } from "mobx-react-lite";
import { updatePublicLMChecklistSets } from "../../../mutators/jobCreationFileMutators";
import { jobCreationFileStore } from "../../../store/jobCreationFileStore";
import { jobStore } from "../../../store/jobStore";
import { JobConfigure3pPluginsBlockView } from "../components/Job3pPluginsConfigurationBlockView";
import { JobSbsExpContainer } from "../components/JobSBSExpContainer";
import { JobUserIdView } from "../components/JobUserIdView";
import { JobMultiPairExperimentationView } from "../UnifiedTemplatesComponents/JobMultiPairExperimentationView";
import { JobUnifiedLLMChecklistDatasetView } from "../UnifiedTemplatesComponents/JobUnifiedLLMChecklistDatasetView";
import { JobUnifiedTestSetView } from "../UnifiedTemplatesComponents/JobUnifiedTestSetView";

export const UnifiedBizChatRender = observer(() => {
  const selectedTemplate = jobStore.selectedTemplate;

  if (selectedTemplate === undefined) {
    return <></>;
  }

  return (
    <>
      <JobUnifiedLLMChecklistDatasetView
        publicLMChecklistSets={jobCreationFileStore.publicLMChecklistSets}
        customLMChecklistSets={jobCreationFileStore.customLMChecklistSets}
        setPublicLMChecklistSets={updatePublicLMChecklistSets}
        customChecklistPathString="queries.lmchecklist_input_file"
      />
      <JobUserIdView />
      <JobUnifiedTestSetView defaultOpenItems={[0]} />
      <JobMultiPairExperimentationView defaultOpenItems={[0]} />
      <JobSbsExpContainer />
      <JobConfigure3pPluginsBlockView />
    </>
  );
});
