import { Spinner } from "@fluentui/react-components";
import { partnerExample } from "@seval-portal/client-partner-example";
import { isFeatureEnabled } from "@seval-portal/client-store";
import type {
  PageType,
  Partner,
  PartnerName,
} from "@seval-portal/client-utils";
import { observer } from "mobx-react-lite";
import { jobCreationStore } from "../../../store/jobCreationStore";
import { JobErrorToast } from "../components/JobErrorToast";
import { JobNameView } from "../components/JobNameView";
import { JobPreValidationToast } from "../components/JobPreValidationToast";
import { JobSetAsPriorityView } from "../components/JobSetAsPriorityView";
import { useJobCreationStyles } from "./useJobCreationStyles";

const renderPartnerPage = (partner: Partner, pageType: PageType) => {
  switch (pageType) {
    case "create":
      return <partner.pages.jobCreatePage />;
    case "result":
      return <partner.pages.jobResultPage />;
    default:
      return <h2>Page not found for {pageType}</h2>;
  }
};

const partners: [Partner] = [partnerExample];

type CreatePageRenderProps = {
  partnerName: PartnerName;
};

export const JobCreatePageRender = observer(
  ({ partnerName }: CreatePageRenderProps) => {
    const styles = useJobCreationStyles();
    if (jobCreationStore.isLoading) {
      return (
        <div className={styles.headerContainer}>
          <Spinner
            className={styles.spinner}
            labelPosition="below"
            label="Loading Job Templates..."
          />
        </div>
      );
    }
    const partner = partners.find((p) => p.name === partnerName);
    if (!partner) {
      return <h2>Partner not found for {partnerName}</h2>;
    }
    return (
      <div className={styles.root}>
        {isFeatureEnabled("job-priority") && <JobSetAsPriorityView />}
        <JobNameView />
        {renderPartnerPage(partner, "create")}
        <JobErrorToast />
        <JobPreValidationToast />
      </div>
    );
  },
);
