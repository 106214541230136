import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
} from "@fluentui/react-components";
import { isFeatureEnabled, store } from "@seval-portal/client-store";
import {
  getScrapingServiceToken,
  msalInstance,
  telemetryHelper,
} from "@seval-portal/client-utils";
import { observer } from "mobx-react-lite";
import { revokeConsentAction } from "../../../../actions/consentAction";
import { logoutAction } from "../../../../actions/logoutAction";
import type { FeatureSettingsDialogContributionProps } from "../../../../contribution/FeatureSettingsContribution";
import { getTokenName } from "../../../../helpers/accountHelper";
import {
  deleteToken,
  scrapingServiceTokenRevoke,
  sendRevokeConsentNotification,
} from "../../../../helpers/apiHelper";
import { resetStore } from "../../../../mutators/resetStore";

export const RevokeConsentDialog = observer(
  (props: FeatureSettingsDialogContributionProps) => {
    const handleRevokeConsent = () => {
      {
        const deleteTokensIfNeeded = () => {
          if (isFeatureEnabled("scraping-service-migration")) {
            const sssTokenName = getTokenName(
              store.account?.username ?? "",
              "3s",
            );
            const deleteTokenPromise = sssTokenName
              ? deleteToken({ SecretName: sssTokenName })
              : Promise.resolve();
            const scrapingServiceTokenPromise = getScrapingServiceToken()
              .then((token) => {
                return scrapingServiceTokenRevoke({
                  accessToken: token,
                });
              })
              .catch((error) => {
                telemetryHelper.logErrorEvent("RevokeConsentError", {
                  message: error.message,
                });
                return Promise.resolve();
              });

            return Promise.all([
              deleteTokenPromise,
              scrapingServiceTokenPromise,
            ]);
          } else {
            const sssTokenName = getTokenName(
              store.account?.username ?? "",
              "3s",
            );
            return sssTokenName
              ? deleteToken({ SecretName: sssTokenName })
              : Promise.resolve();
          }
        };

        Promise.all([
          deleteTokensIfNeeded(),
          revokeConsentAction(),
          sendRevokeConsentNotification(),
        ])
          .catch((error) => {
            telemetryHelper.logErrorEvent("RevokeConsentError", {
              message: error.message,
            });
          })
          .finally(() => {
            logoutAction(msalInstance, "redirect");
            resetStore();
          });
      }
    };

    return (
      <Dialog
        open={props.isOpen}
        onOpenChange={() => {
          props.close();
        }}
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle>Revoke consent</DialogTitle>
            <DialogContent>
              <Field
                label={
                  <>
                    <Body1>{`Do you confirm to revoke the consent?\n`}</Body1>
                    <br />
                    <strong>{`*Note: This action will delete all your jobs and tokens.`}</strong>
                  </>
                }
              ></Field>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleRevokeConsent();
                }}
                appearance="primary"
              >
                Confirm
              </Button>
              <Button
                onClick={() => {
                  props.close();
                }}
                appearance="secondary"
              >
                Cancel
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  },
);
