import type { TokenInitDialogType } from "@seval-portal/client-models";
import type { BingMetaDataResponse, GPTAgent } from "@seval-portal/shared";
import { createStore } from "satcheljs";

export enum AccountType {
  Test = "test",
  Personal = "personal",
}

export enum QueryType {
  QuerySet = "querySet",
  Template = "template",
}
interface IJobCreationStore {
  isOpenProductSelectDialog: boolean;
  isLoading: boolean;
  isLoadingContentView: boolean;
  isSubmitting: boolean;
  isVerifying: boolean;
  isTokenDialogOpen: boolean;
  tokenDialogEmail: string;
  substrateTokenVerifyFailed: boolean;
  sevalTokenVerifyFailed: boolean;
  teamsCliTokenVerifyFailed: boolean;
  canSkipTokenUploading: boolean;
  tokenInitDialogType: TokenInitDialogType;
  bingMetaData: BingMetaDataResponse | undefined;
  isDispatchedErrorToast: boolean;
  userUploadedSets: string[];
  isPreValidating: boolean;
  availableGPTAgents: GPTAgent[];
  isEditingRequiredGroupJob?: boolean;
}

export const initJobCreationStore: IJobCreationStore = {
  isOpenProductSelectDialog: false,
  isLoading: true,
  isLoadingContentView: false,
  isSubmitting: false,
  isVerifying: false,
  isTokenDialogOpen: false,
  tokenDialogEmail: "",
  substrateTokenVerifyFailed: false,
  sevalTokenVerifyFailed: false,
  teamsCliTokenVerifyFailed: false,
  canSkipTokenUploading: false,
  tokenInitDialogType: "TokenMissingOrExpired",
  bingMetaData: undefined,
  isDispatchedErrorToast: false,
  userUploadedSets: [],
  isPreValidating: false,
  availableGPTAgents: [],
};

export const jobCreationStore = createStore<IJobCreationStore>(
  "jobCreationStore",
  Object.assign({}, initJobCreationStore),
)();
