import { Badge, Title3, shorthands } from "@fluentui/react-components";
import { getAppEnv, getAppVersion } from "@seval-portal/client-utils";
import { observer } from "mobx-react-lite";
import logo from "../../resources/logo.png";
import { makeResponsiveStyles } from "../Responsive/makeResponsiveStyles";
import { AppBarFeedback } from "./AppBarFeedback";
import { AppBarReportIssue } from "./AppBarReportIssue";
import { AppBarSettings } from "./AppBarSettings";
import { AppBarTabs } from "./AppBarTabs";
import { AppBarUserAvatar } from "./AppBarUserAvatar";

const useStyles = makeResponsiveStyles(
  {
    root: {
      flexShrink: 0,
      display: "flex",
      flexDirection: "row",
      width: "100%",
      height: "72px",
      backgroundColor: "black",
      alignItems: "center",
      paddingLeft: "50px",
      paddingRight: "50px",
      columnGap: "10px",
      boxSizing: "border-box",
      zIndex: 1000000,
      ...shorthands.overflow("hidden"),
    },
    icon: {
      flexShrink: 0,
      color: "white",
      height: "30px",
      width: "30px",
    },
    title: {
      color: "white",
      whiteSpace: "nowrap",
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    subTitle: {
      color: "rgba(186, 186, 186, 1)",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "22px",
    },
    label: {
      color: "rgb(13, 102, 53)",
      backgroundColor: "rgb(202, 234, 216)",
      ...shorthands.padding("4px", "8px"),
      ...shorthands.borderRadius("12px"),
    },
    avatarContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "auto",
    },
    titleWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  {
    xs: {
      root: {
        paddingLeft: "20px",
        paddingRight: "20px",
      },
    },
    sm: {
      root: {
        paddingLeft: "20px",
        paddingRight: "20px",
      },
    },
    md: {
      root: {
        paddingLeft: "20px",
        paddingRight: "20px",
      },
    },
  },
);

export const AppBar = observer(() => {
  const styles = useStyles();
  const { type } = getAppEnv();
  const version = getAppVersion();

  return (
    <div className={styles.root}>
      <img src={logo} alt="Office 365" className={styles.icon} />
      <div className={styles.titleWrapper}>
        <Title3 className={styles.title}>{"SEVAL Pro"}</Title3>
        <Title3 className={styles.subTitle}>
          {"Copilot Evaluation Platform"}
        </Title3>
      </div>
      <AppBarTabs />
      {type !== "PROD" && (
        <>
          <Badge className={styles.label}>{type}</Badge>
          {version !== undefined && (
            <Badge className={styles.label}>{getAppVersion()}</Badge>
          )}
        </>
      )}
      <div className={styles.avatarContainer}>
        <AppBarFeedback />
        <AppBarReportIssue />
        <AppBarSettings />
        <AppBarUserAvatar />
      </div>
    </div>
  );
});
