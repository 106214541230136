"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UtteranceDetailConversation = exports.UtteranceDetail = void 0;
const tools_1 = require("../tools");
exports.UtteranceDetail = (0, tools_1.object)({
    query: tools_1.str,
    segment: tools_1.str,
    exp_name: tools_1.str,
    conversation: tools_1.str,
    metric_key: tools_1.str,
    response: tools_1.str,
});
exports.UtteranceDetailConversation = (0, tools_1.array)((0, tools_1.object)({
    SydneyReply: (0, tools_1.array)(tools_1.str),
    SydneySearches: (0, tools_1.array)((0, tools_1.object)({
        Query: (0, tools_1.optional)(tools_1.str),
        SearchResults: (0, tools_1.optional)(tools_1.str),
    })),
    Iterations: (0, tools_1.optional)((0, tools_1.array)(tools_1.anyObject)),
    audio_paths: (0, tools_1.optional)((0, tools_1.object)({
        user: (0, tools_1.optional)(tools_1.str),
        bot: (0, tools_1.optional)(tools_1.str),
        combined: (0, tools_1.optional)(tools_1.str),
    })),
}));
