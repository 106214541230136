import type {
  FeatureContribution,
  FeatureContributionSubTab,
} from "@seval-portal/client-models";
import { mutatorAction } from "satcheljs";
import { registeredContributions } from "../contribution/register";
import { contributionStore } from "../store/contributionStore";

const checkIfPathMatches = (pathPattern: string, currentPath: string) => {
  const pathPatternKey = pathPattern.split("/")[1];
  const currentPathKey = currentPath.split("?")[0].split("/")[1];

  if (
    pathPatternKey === currentPathKey &&
    currentPathKey !== undefined &&
    pathPatternKey !== undefined
  ) {
    return true;
  } else {
    return false;
  }
};

const getContributionByPathname = (pathname: string) => {
  const contributions = registeredContributions.filter((_) => _.isEnabled());

  const checkContribution = (contribution: FeatureContribution) => {
    if (contribution.type === "single-page") {
      const pathRegistry = Object.entries(contribution.pathRegistry).find(
        ([path]) => checkIfPathMatches(path, pathname),
      )?.[1];

      if (pathRegistry !== undefined) {
        return {
          contribution: contribution,
          contributionSubTab: undefined,
          contributionPathRegistry: pathRegistry,
          currentPath: pathname,
        };
      }
    }

    if (contribution.type === "sub-tab") {
      for (const subTab of contribution.subTabs) {
        const pathRegistry = Object.entries(subTab.pathRegistry).find(
          ([path]) => checkIfPathMatches(path, pathname),
        )?.[1];

        if (pathRegistry !== undefined) {
          return {
            contribution: contribution,
            contributionSubTab: subTab,
            contributionPathRegistry: pathRegistry,
            currentPath: pathname,
          };
        }
      }
    }

    return undefined;
  };

  for (const contribution of contributions) {
    const checkResult = checkContribution(contribution);
    if (checkResult !== undefined) {
      return checkResult;
    }
  }

  const fallbackContribution = contributions[0];
  switch (fallbackContribution.type) {
    case "single-page":
      return {
        contribution: fallbackContribution,
        contributionSubTab: undefined,
        contributionPathRegistry:
          fallbackContribution.pathRegistry[fallbackContribution.defaultPath],
        currentPath: fallbackContribution.defaultPath,
      };

    case "sub-tab":
      return {
        contribution: fallbackContribution,
        contributionSubTab: fallbackContribution.subTabs[0],
        contributionPathRegistry:
          fallbackContribution.subTabs[0].pathRegistry[
            fallbackContribution.subTabs[0].defaultPath
          ],
        currentPath: fallbackContribution.subTabs[0].defaultPath,
      };
  }
};

export const updateCurrentPath = mutatorAction(
  "updateCurrentPath",
  (path: string, state: object | undefined = undefined) => {
    const result = getContributionByPathname(path);
    contributionStore.selectedContribution = result.contribution;
    contributionStore.selectedContributionSubTab = result.contributionSubTab;
    contributionStore.selectedContributionPathRegistry =
      result.contributionPathRegistry;
    contributionStore.currentPath = result.currentPath;
    contributionStore.currentPathState = state;
  },
);

export const updateSelectedContribution = mutatorAction(
  "updateSelectedContribution",
  (contribution: FeatureContribution) => {
    contributionStore.selectedContribution = contribution;

    switch (contribution.type) {
      case "single-page":
        contributionStore.selectedContributionSubTab = undefined;
        contributionStore.selectedContributionPathRegistry =
          contribution.pathRegistry[contribution.defaultPath];
        contributionStore.currentPath = contribution.defaultPath;
        break;

      case "sub-tab":
        contributionStore.selectedContributionSubTab = contribution.subTabs[0];
        contributionStore.selectedContributionPathRegistry =
          contribution.subTabs[0].pathRegistry[
            contribution.subTabs[0].defaultPath
          ];
        contributionStore.currentPath = contribution.subTabs[0].defaultPath;
        break;
    }
  },
);

export const updateSelectedContributionTab = mutatorAction(
  "updateSelectedContributionTab",
  (contribution: FeatureContribution, tab: FeatureContributionSubTab) => {
    contributionStore.selectedContribution = contribution;
    contributionStore.selectedContributionSubTab = tab;
    contributionStore.selectedContributionPathRegistry =
      tab.pathRegistry[tab.defaultPath];
    contributionStore.currentPath = tab.defaultPath;
  },
);

export const updateSelectedContributionDialogId = mutatorAction(
  "updateSelectedContributionDialogId",
  (dialogId: string | undefined) => {
    contributionStore.selectedContributionDialogId = dialogId;
  },
);
