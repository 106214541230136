import {
  Button,
  mergeClasses,
  Popover,
  PopoverSurface,
  PopoverTrigger,
} from "@fluentui/react-components";
import { Info20Regular } from "@fluentui/react-icons";
import type { Job } from "@seval-portal/client-models";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { formatCreateTime } from "../../../helpers/formatHelper";
import { getReferedJob } from "../../../selectors/scheduleJobSelector";
import { scheduleJobStore } from "../../../store/scheduleJobStore";
import { sharedStyles } from "../sharedStyles";

export const InforButtonView = observer(() => {
  const styles = sharedStyles();

  const [referJob, setReferJob] = React.useState<Job | undefined>(undefined);
  const { selectedScheduledJob } = scheduleJobStore;
  useEffect(() => {
    getReferedJob.get().then((job) => {
      if (job && job[0]) {
        setReferJob(job[0]);
      }
    });
  }, []);
  return (
    <Popover>
      <PopoverTrigger disableButtonEnhancement>
        <Button icon={<Info20Regular />}>Info</Button>
      </PopoverTrigger>

      <PopoverSurface tabIndex={-1}>
        <div className={styles.infoGrid}>
          {selectedScheduledJob?.Type && (
            <>
              <div className={styles.infoTitle}>Type</div>

              <div className={styles.infoContent}>
                {selectedScheduledJob?.Type ?? ""}
              </div>
            </>
          )}
          {selectedScheduledJob?.LastUpdateBy && (
            <>
              <div className={styles.infoTitle}>Last Updated By</div>
              <div className={styles.infoContent}>
                {selectedScheduledJob.LastUpdateBy}
              </div>
            </>
          )}
          {selectedScheduledJob?.LastUpdateTime && (
            <>
              <div className={styles.infoTitle}>Last Updated Time</div>
              <div className={styles.infoContent}>
                {formatCreateTime(selectedScheduledJob.LastUpdateTime)}
              </div>
            </>
          )}
          {selectedScheduledJob?.LastExecuteTime && (
            <>
              <div className={styles.infoTitle}>Last Execute Time</div>
              <div className={styles.infoContent}>
                {formatCreateTime(selectedScheduledJob.LastExecuteTime)}
              </div>
            </>
          )}
          {selectedScheduledJob?.EmailSubscriptionList && (
            <>
              <div className={styles.infoTitle}>Email Subscription List</div>
              <div className={styles.infoContent}>
                {selectedScheduledJob.EmailSubscriptionList.split(",").join(
                  ", ",
                )}
              </div>
            </>
          )}

          {referJob && (
            <>
              <div className={styles.infoTitle}>Refer Job</div>

              <div
                className={mergeClasses(styles.infoContent, styles.inforBreak)}
              >
                [{referJob.ID}] {referJob.JobName}
              </div>
            </>
          )}
        </div>
      </PopoverSurface>
    </Popover>
  );
});
