import { JobRenameDialog } from "@seval-portal/client-components";
import type { Job } from "@seval-portal/client-models";
import { observer } from "mobx-react";
import React from "react";
import { JobSettingsDialog } from "../../../../../components/Dialog/JobSettingsDialog";
import { useToast } from "../../../../../components/Wrappers/ToasterProvider";
import { removeJobFromGroup } from "../../../../../helpers/apiHelper";
import {
  refreshGroupJobListAction,
  switchSelectedJobAction,
} from "../../../actions/groupDetailActions";
import { getJobSettings } from "../../../helpers/groupListHelper";
import { updateGroupJobs } from "../../../mutators/groupDetailMutators";
import { isJobRemovable } from "../../../selectors/groupJob";
import { groupDetailStore } from "../../../store/groupDetailStore";
import { GeneralTable } from "../../JobList/GeneralTable";
import { JobActionsCell } from "../../JobList/JobCell/JobActionsCell";
import { JobCreateDateCell } from "../../JobList/JobCell/JobCreateDateCell";
import { JobCreatorCell } from "../../JobList/JobCell/JobCreatorCell";
import { JobMetricsCell } from "../../JobList/JobCell/JobMetricsCell";
import { JobNameCell } from "../../JobList/JobCell/JobNameCell";
import { JobStatusCell } from "../../JobList/JobCell/JobStatusCell";

export const JobInforPane = observer(() => {
  const displayJobs = groupDetailStore.jobs;
  const [currentJob, setCurrentJob] = React.useState<Job | undefined>(
    undefined,
  );
  const emptyFunc = () => void 0;
  // Dialog
  const [isSettingDialogOpen, setIsSettingDialogOpen] = React.useState(false);
  const [isRenameDialogOpen, setIsRenameDialogOpen] = React.useState(false);

  const toast = useToast();
  const onRemoveFromGroup = (job: Job) => {
    const groupID = groupDetailStore.group?.ID;
    toast.onToastStart("Removing job from group...");
    if (groupID === undefined) {
      toast.onToastFailure("Group ID is undefined");
      return;
    }
    removeJobFromGroup({
      GroupId: groupID,
      JobIds: [job.ID],
    }).then(() => {
      toast.onToastSuccess("Job removed from group");
      const newJobList = groupDetailStore.jobs.filter((_) => _.ID !== job.ID);
      updateGroupJobs(newJobList);
      switchSelectedJobAction(newJobList[0]);
    });
  };

  const onSettingPageClose = () => {
    setIsSettingDialogOpen(false);
    setCurrentJob(undefined);
  };

  const onRenameDialogClose = (newJobName: string) => {
    setIsRenameDialogOpen(false);
    if (currentJob && currentJob.JobName !== newJobName) {
      currentJob.JobName = newJobName;
      setCurrentJob(undefined);
    }
  };

  const renderJobList = () => (
    <GeneralTable
      key={displayJobs.length}
      loadMore={emptyFunc}
      hasMore={false}
      rows={displayJobs}
      columns={[
        {
          title: "Name",
          render: (job) => (
            <>
              <JobNameCell job={job} />
            </>
          ),
          weight: 3,
          minWidth: "400px",
        },
        {
          title: "",
          render: (job) => (
            <JobActionsCell
              item={job}
              jobTemplates={[]}
              onJobSettingsClick={() => {
                setCurrentJob(job);
                getJobSettings(job).then((settings) => {
                  setCurrentJob({ ...job, Settings: settings });
                });
                setIsSettingDialogOpen(true);
              }}
              onJobRenameClick={() => {
                setCurrentJob(job);
                setIsRenameDialogOpen(true);
              }}
              scenario="list"
              onRemoveFromGroup={
                isJobRemovable(job) ? onRemoveFromGroup : undefined
              }
              onRefreshJobList={() => refreshGroupJobListAction()}
            />
          ),
          weight: 0,
          minWidth: "50px",
        },
        {
          title: "Created By",
          render: (job) => <JobCreatorCell job={job} />,
          weight: 1,
          minWidth: "150px",
        },
        {
          title: "Date",
          render: (job) => <JobCreateDateCell job={job} />,
          weight: 1,
          minWidth: "250px",
        },
        {
          title: "Status",
          render: (job) => <JobStatusCell job={job} />,
          weight: 0,
          minWidth: "150px",
        },
        {
          title: "Metrics",
          render: (job) => <JobMetricsCell job={job} />,
          weight: 0,
          minWidth: "150px",
        },
      ]}
    />
  );

  return (
    <>
      {renderJobList()}

      {isSettingDialogOpen && (
        <JobSettingsDialog
          isOpen={isSettingDialogOpen}
          jobName={currentJob?.JobName ?? "Unknown Job"}
          settings={currentJob?.Settings}
          dataSets={currentJob?.DataSets}
          lastSelectedTab={"settings"}
          onClose={() => onSettingPageClose()}
        />
      )}

      {isRenameDialogOpen && (
        <JobRenameDialog
          isOpen={isRenameDialogOpen}
          jobName={currentJob?.JobName ?? ""}
          jobId={currentJob?.ID ?? 0}
          onClose={(newJobName) => onRenameDialogClose(newJobName)}
        />
      )}
    </>
  );
});
