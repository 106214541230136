import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from "@fluentui/react-components";
import { telemetryHelper } from "@seval-portal/client-utils";
import {
  createScheduledJob,
  updateScheduledJob,
} from "../../helpers/apiHelper";
import { updateCurrentPath } from "../../mutators/updateContributions";
import { searchScheduleJobsAcion } from "../../partners/OfflineEval/actions/scheduleJobActions";
import {
  clearSearchCriteria,
  switchPageMode,
} from "../../partners/OfflineEval/mutators/scheduleJobMutators";
import {
  getScheduleJobSubmitContent,
  getSubmitScheduleJobRequest,
} from "../../partners/OfflineEval/selectors/scheduleJobSelector";
import { scheduleJobStore } from "../../partners/OfflineEval/store/scheduleJobStore";
import { useToast } from "../Wrappers/ToasterProvider";

export const ScheduleJobRequestDialog = () => {
  const toast = useToast();
  const onSubmitRequest = () => {
    toast.onToastStart("Submitting Update Job...");

    const getRequestPromise = () => {
      if (
        scheduleJobStore.pageMode === "Create" &&
        scheduleJobStore.selectedScheduledJob
      ) {
        return createScheduledJob({
          Content: scheduleJobStore.selectedScheduledJob,
        }).then(() => {
          telemetryHelper.logUserActionEvent(
            "CreateScheduler",
            scheduleJobStore.selectedScheduledJob,
          );
        });
      }
      const param = getScheduleJobSubmitContent.get();
      if (param !== undefined && scheduleJobStore.pageMode === "Edit") {
        return updateScheduledJob(param).then(() => {
          telemetryHelper.logUserActionEvent("UpdateScheduler", param);
        });
      }
      throw new Error("Invalid Request Content!");
    };

    getRequestPromise().then(
      () => {
        toast.onToastSuccess("Submit Succeeded");
        clearSearchCriteria();
        switchPageMode("View");
        searchScheduleJobsAcion();
        updateCurrentPath("/scheduler/list");
      },
      (error) => {
        toast.onToastFailure(`Submit Failed: ${error.message}`);
      },
    );
  };
  const submitRequest = getSubmitScheduleJobRequest.get();
  return (
    <Dialog>
      <DialogTrigger disableButtonEnhancement>
        <Button appearance="primary" disabled={submitRequest.isDisabled}>
          Submit
        </Button>
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            Are you sure you want to submit your change?
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Close</Button>
            </DialogTrigger>
            <DialogTrigger disableButtonEnhancement>
              <Button
                data-testid="submit-button"
                disabled={submitRequest.isDisabled}
                appearance="primary"
                onClick={onSubmitRequest}
              >
                Submit
              </Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
