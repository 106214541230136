import { store } from "@seval-portal/client-store";
import {
  getAppEnv,
  graphRequest,
  msalInstance,
} from "@seval-portal/client-utils";
import {
  array,
  object,
  optional,
  parseJsonStrOptional,
  str,
} from "@seval-portal/shared";
import { getValue } from "../partners/OfflineEval/selectors/getJobPropV2";

export const getGraphToken = () => {
  if (store.account === undefined) {
    return Promise.resolve(undefined);
  }

  return msalInstance
    .acquireTokenSilent({
      ...graphRequest,
      account: store.account,
    })
    .then((_) => _.accessToken)
    .catch(() => undefined);
};

export const getGraphTokenInfo = () =>
  getGraphToken()
    .then((_) => {
      if (_ === undefined) {
        return undefined;
      }

      return JSON.parse(window.atob(_.split(".")[1]));
    })
    .catch(() => undefined);

const MSALSessionKeys = object({
  accessToken: optional(array(str)),
  idToken: optional(array(str)),
  refreshToken: optional(array(str)),
});

const MSALSessionRefreshToken = object({
  clientId: str,
  credentialType: str,
  environment: str,
  homeAccountId: str,
  secret: str,
});

export const getRefreshToken = () => {
  const { applicationId } = getAppEnv();
  const localKeysStr =
    localStorage.getItem(`msal.token.keys.${applicationId}`) ?? "";

  const sessionKeys = parseJsonStrOptional(localKeysStr, MSALSessionKeys);
  const refreshTokenKey = sessionKeys?.refreshToken?.[0];

  if (refreshTokenKey === undefined) {
    return undefined;
  }

  const refreshTokenStr = localStorage.getItem(refreshTokenKey) ?? "";
  const refreshToken = parseJsonStrOptional(
    refreshTokenStr,
    MSALSessionRefreshToken,
  );

  return refreshToken?.secret;
};

export const is3pExtPluginsConfigured = (): boolean => {
  return (
    (getValue("sideload_3p_plugins") || getValue("unsideload_3p_plugins")) ??
    false
  );
};
