import {
  Combobox,
  InfoLabel,
  makeStyles,
  Option,
  shorthands,
  Text,
  tokens,
} from "@fluentui/react-components";
import { perfWrapper } from "@seval-portal/client-utils";
import type { QuerySetManagementSearchRequestData } from "@seval-portal/shared";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { getQuerySetManagement } from "../../../../../helpers/apiHelper";
import type { QuerySetManagement } from "../../../models/QuerySetManagement";

const useStyles = makeStyles({
  block: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    width: "100%",
    ...shorthands.gap("8px"),
  },
  blockTitle: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  dropdownMenu: {
    backgroundColor: "#F8F7F9",
  },
  listbox: {
    maxHeight: "300px",
  },
});

export const QuerySetTableSelector = observer(
  (props: {
    selected?: string;
    envId?: number;
    onChange: (s?: string) => void;
  }) => {
    const styles = useStyles();
    const { selected: querySet, envId, onChange } = props;
    const [querySetTable, setQuerySetTable] = React.useState<
      QuerySetManagement[]
    >([]);

    React.useEffect(() => {
      const params: QuerySetManagementSearchRequestData = {
        Status: "Activated",
      };
      if (envId !== undefined && envId !== -1) {
        params.Env = String(envId);
      }
      perfWrapper(
        "LoadQuerySetManagementList",
        getQuerySetManagement(params).then((data) => {
          setQuerySetTable(data.results);
        }),
      );
    }, [envId]);

    return (
      <div className={styles.block}>
        <InfoLabel
          className={styles.blockTitle}
          required
          info={
            "Specify the file path of input file from SEVAL query set storage"
          }
        >
          Test set
        </InfoLabel>
        <div>
          ChatGPT scraper can only run on a pre-registered query set and{" "}
          <Text weight="semibold">does not support custom queries.</Text> To
          register a new query set, please contact the Competitive Evaluation
          Team (alias: competeanalysissg)
        </div>
        <Combobox
          data-testid="query-set-combobox"
          aria-label="Specify the file path of input file from SEVAL query set storage"
          className={styles.dropdownMenu}
          listbox={{ className: styles.listbox }}
          value={querySet}
          selectedOptions={[querySet ?? ""]}
          onOptionSelect={(_, data) => {
            if (data.optionValue !== undefined) {
              onChange(data.optionValue);
            }
          }}
        >
          {querySetTable.map((query, index) => {
            return (
              <Option key={index} value={query.Name}>
                {query.Name}
              </Option>
            );
          })}
        </Combobox>
      </div>
    );
  },
);
