import * as React from "react";

import {
  Button,
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  Spinner,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import {
  ArrowUploadRegular,
  List20Filled,
  List20Regular,
  People20Filled,
  People20Regular,
  Person20Filled,
  Person20Regular,
  TextBulletListSquareEdit24Regular,
} from "@fluentui/react-icons";
import {
  getBingQuerySetGenerations,
  getBingUserSets,
  getCustomQuerySets,
  getPublicQuerySets,
  getQuerySetGenerations,
} from "../../../../helpers/apiHelper";

import { perfWrapper } from "@seval-portal/client-utils";
import { observer } from "mobx-react-lite";
import { CWCQuerySetGenerationDialog } from "../../../../components/Dialog/CWCQuerySetGenerationDialog";
import { QuerySetGenerationDialog } from "../../../../components/Dialog/QuerySetGenerationDialog";
import { useSharedTabStyles } from "../../../../components/Shared/SharedTabStyles";
import { useToast } from "../../../../components/Wrappers/ToasterProvider";
import { QuerySetTableFilter } from "../../models/QuerySets";
import {
  updateBingCustomQuerySets,
  updateBingGeneratedQuerySets,
  updateMChatCustomQuerySets,
  updateMChatGeneratedQuerySets,
  updateMChatPublicQuerySets,
  updateQuerySetsLoadingStatus,
  updateSelectedProduct,
  updateSelectedTab,
} from "../../mutators/querySetsMutators";
import { getQuerySets } from "../../selectors/getQuerySets";
import { querySetsStore } from "../../store/querySetsStore";
import { UploadCWCQuerySetDialog } from "../Dialog/UploadCWCQuerySetDialog";
import { UploadingFileDialog } from "../Dialog/UploadingFileDialog";
import { FilterButton } from "../Other/FilterButton";
import { QuerySetsTable } from "./QuerySetsTable";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    boxSizing: "border-box",
  },
  functionBarContainer: {
    width: "100%",
    ...shorthands.margin("15px", "0", "20px", "0"),
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  buttonContainer: {
    marginLeft: "auto",
  },
  generateButton: {
    ...shorthands.padding("10px", "12px"),
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
    width: "116px",
    height: "44px",
    marginRight: "6px",
    fontFamily: tokens.fontFamilyBase,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
  },
  AIGraphButton: {
    ...shorthands.padding("10px", "12px"),
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
    width: "260px",
    height: "44px",
    marginRight: "16px",
    fontFamily: tokens.fontFamilyBase,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
  },
  uploadButton: {
    ...shorthands.margin("0", "20px"),
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
    width: "116px",
    height: "44px",
    fontFamily: tokens.fontFamilyBase,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
  },
  spinner: {
    width: "100%",
  },
  tabGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "44px",
    ...shorthands.gap("8px"),
  },
  messageBar: {
    width: "100%",
    ...shorthands.margin("10px", "0px"),
  },
});

export const QuerySetsView = observer(() => {
  const styles = useStyles();
  const toast = useToast();
  const sharedStyles = useSharedTabStyles();

  const [isFileDialogOpen, setIsFileDialogOpen] = React.useState(false);
  const [isQuerySetGenerationDialogOpen, setIsQuerySetGenerationDialogOpen] =
    React.useState(false);

  React.useEffect(() => {
    switch (querySetsStore.selectedTab) {
      case QuerySetTableFilter.all:
        fetchAllQuerySets();
        break;
      case QuerySetTableFilter.shared:
        fetchSharedQuerySets();
        break;
      case QuerySetTableFilter.myQuerySets:
        fetchMyQuerySets();
        break;
      case QuerySetTableFilter.myBingQuerySets:
        fetchBingQuerySets();
        break;
    }
  }, [querySetsStore.selectedTab]);

  const fetchAllQuerySets = () => {
    perfWrapper(
      "LoadQuerySets",
      Promise.resolve()
        .then(() => {
          updateQuerySetsLoadingStatus(true);
        })
        .then(() =>
          Promise.all([
            getPublicQuerySets(),
            getCustomQuerySets(),
            getQuerySetGenerations(),
          ]).then(([publicQuerySets, customQuerySets, querySetGenerations]) => {
            updateMChatPublicQuerySets(publicQuerySets);
            updateMChatCustomQuerySets(customQuerySets);
            updateMChatGeneratedQuerySets(querySetGenerations);
          }),
        )
        .finally(() => {
          updateQuerySetsLoadingStatus(false);
        }),
    );
  };

  const fetchSharedQuerySets = () => {
    perfWrapper(
      "LoadQuerySets",
      Promise.resolve()
        .then(() => {
          updateQuerySetsLoadingStatus(true);
        })
        .then(() =>
          getPublicQuerySets().then((publicQuerySets) => {
            updateMChatPublicQuerySets(publicQuerySets);
          }),
        )
        .finally(() => {
          updateQuerySetsLoadingStatus(false);
        }),
    );
  };

  const fetchMyQuerySets = () => {
    perfWrapper(
      "LoadQuerySets",
      Promise.resolve()
        .then(() => {
          updateQuerySetsLoadingStatus(true);
        })
        .then(() =>
          Promise.all([getCustomQuerySets(), getQuerySetGenerations()]).then(
            ([customQuerySets, querySetGenerations]) => {
              updateMChatCustomQuerySets(customQuerySets);
              updateMChatGeneratedQuerySets(querySetGenerations);
            },
          ),
        )
        .finally(() => {
          updateQuerySetsLoadingStatus(false);
        }),
    );
  };

  const getBingGenerationJobs = () => {
    if (shouldSupportBingQuerySetGeneration) {
      return getBingQuerySetGenerations();
    }
    return Promise.resolve([]);
  };

  const fetchBingQuerySets = () => {
    perfWrapper(
      "LoadQuerySets",
      Promise.resolve()
        .then(() => {
          updateQuerySetsLoadingStatus(true);
        })
        .then(() =>
          Promise.all([getBingUserSets("CWC"), getBingGenerationJobs()]).then(
            ([bingSets, bingGeneration]) => {
              updateBingCustomQuerySets(bingSets);
              updateBingGeneratedQuerySets(bingGeneration);
            },
          ),
        )
        .finally(() => {
          updateQuerySetsLoadingStatus(false);
        }),
    );
  };

  const onFileUploadCancel = () => {
    setIsFileDialogOpen(false);
  };

  const onFileUploadStart = () => {
    toast.onToastStart("Uploading file...");
    setIsFileDialogOpen(false);
  };

  const refreshQuerySets = () => {
    switch (querySetsStore.selectedTab) {
      case QuerySetTableFilter.all:
        fetchAllQuerySets();
        break;
      case QuerySetTableFilter.myQuerySets:
        fetchMyQuerySets();
        break;
      case QuerySetTableFilter.myBingQuerySets:
        fetchBingQuerySets();
        break;
      default:
        break;
    }
  };

  const onFileUploadSuccess = () => {
    toast.onToastSuccess("File uploaded successfully");
    refreshQuerySets();
  };

  const onFileUploadFailure = (error: Error) => {
    toast.onToastFailure(`File upload failed with message: ${error.message}`);
  };

  const onQuerySetGenerationCancel = () => {
    setIsQuerySetGenerationDialogOpen(false);
  };

  const onQuerySetGenerationSuccess = () => {
    setIsQuerySetGenerationDialogOpen(false);
    refreshQuerySets();
  };

  const shouldSupportMChatQuerySetGeneration =
    querySetsStore.selectedTab !== QuerySetTableFilter.myBingQuerySets;

  const shouldSupportBingQuerySetGeneration =
    querySetsStore.selectedTab === QuerySetTableFilter.myBingQuerySets;

  const getStyle = (name: string) => {
    return querySetsStore.selectedProduct === name
      ? mergeClasses(sharedStyles.tab, sharedStyles.activeTab)
      : mergeClasses(sharedStyles.tab, sharedStyles.inactiveTab);
  };

  return (
    <div className={styles.root}>
      <div className={styles.tabGroup}>
        <Button
          shape="square"
          className={getStyle("MChat")}
          onClick={() => {
            if (querySetsStore.selectedProduct !== "MChat") {
              updateSelectedProduct("MChat");
              updateSelectedTab(QuerySetTableFilter.all);
            }
          }}
        >
          BizChat
        </Button>
        <Button
          shape="square"
          className={getStyle("Bing")}
          onClick={() => {
            if (querySetsStore.selectedProduct !== "Bing") {
              updateSelectedProduct("Bing");
              updateSelectedTab(QuerySetTableFilter.myBingQuerySets);
            }
          }}
        >
          CWC
        </Button>
      </div>
      <div className={styles.functionBarContainer}>
        {querySetsStore.selectedProduct === "MChat" && (
          <div className={styles.tabGroup}>
            <FilterButton
              value={QuerySetTableFilter.all}
              selectedTab={querySetsStore.selectedTab}
              text="All"
              activeIcon={<List20Filled />}
              inactionIcon={<List20Regular />}
              clickTab={() => updateSelectedTab(QuerySetTableFilter.all)}
            />

            <FilterButton
              value={QuerySetTableFilter.shared}
              selectedTab={querySetsStore.selectedTab}
              text="Shared"
              activeIcon={<People20Filled />}
              inactionIcon={<People20Regular />}
              clickTab={() => updateSelectedTab(QuerySetTableFilter.shared)}
            />

            <FilterButton
              value={QuerySetTableFilter.myQuerySets}
              selectedTab={querySetsStore.selectedTab}
              text="My query sets"
              activeIcon={<Person20Filled />}
              inactionIcon={<Person20Regular />}
              clickTab={() =>
                updateSelectedTab(QuerySetTableFilter.myQuerySets)
              }
            />
          </div>
        )}

        <div className={styles.buttonContainer}>
          {(shouldSupportMChatQuerySetGeneration ||
            shouldSupportBingQuerySetGeneration) && (
            <Button
              className={styles.generateButton}
              icon={<TextBulletListSquareEdit24Regular />}
              onClick={() => {
                setIsQuerySetGenerationDialogOpen(true);
              }}
            >
              Generate
            </Button>
          )}
          <Button
            className={styles.uploadButton}
            appearance="primary"
            icon={<ArrowUploadRegular />}
            onClick={() => setIsFileDialogOpen(true)}
          >
            Upload
          </Button>
        </div>
      </div>
      {querySetsStore.selectedTab === QuerySetTableFilter.myBingQuerySets && (
        <MessageBar className={styles.messageBar}>
          <MessageBarBody>
            <MessageBarTitle>Notice</MessageBarTitle>
            You can upload query set for CWC job under this filter.
          </MessageBarBody>
        </MessageBar>
      )}
      {querySetsStore.isLoading && (
        <Spinner
          className={styles.spinner}
          labelPosition="below"
          label="Loading Query Sets..."
        />
      )}
      {!querySetsStore.isLoading && (
        <QuerySetsTable
          querySets={getQuerySets.get()}
          onRefreshQuerySets={refreshQuerySets}
        />
      )}
      {querySetsStore.selectedTab === QuerySetTableFilter.myBingQuerySets && (
        <UploadCWCQuerySetDialog
          isOpen={isFileDialogOpen}
          close={onFileUploadCancel}
          success={onFileUploadSuccess}
        />
      )}
      {querySetsStore.selectedTab !== QuerySetTableFilter.myBingQuerySets && (
        <UploadingFileDialog
          type="Query"
          isOpen={isFileDialogOpen}
          onCancel={onFileUploadCancel}
          onStart={onFileUploadStart}
          onSuccess={onFileUploadSuccess}
          onFailure={onFileUploadFailure}
        />
      )}
      {shouldSupportMChatQuerySetGeneration && (
        <QuerySetGenerationDialog
          isOpen={isQuerySetGenerationDialogOpen}
          onSuccess={onQuerySetGenerationSuccess}
          onCancel={onQuerySetGenerationCancel}
        />
      )}
      {shouldSupportBingQuerySetGeneration && (
        <CWCQuerySetGenerationDialog
          isOpen={isQuerySetGenerationDialogOpen}
          onSuccess={onQuerySetGenerationSuccess}
          onCancel={onQuerySetGenerationCancel}
        />
      )}
    </div>
  );
});
