import { Button } from "@fluentui/react-button";
import {
  Checkbox,
  Dropdown,
  Input,
  Option,
  shorthands,
  Spinner,
  tokens,
} from "@fluentui/react-components";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from "@fluentui/react-dialog";
import type { Job } from "@seval-portal/client-models";
import { JobStatusOptions } from "@seval-portal/client-utils";
import React, { useEffect } from "react";
import { makeResponsiveStyles } from "../../../../components/Responsive/makeResponsiveStyles";
import { ResponsiveRow } from "../../../../components/Responsive/ResponsiveRow";
import { getJobs } from "../../../../helpers/apiHelper";
import { convertProductExperimentNameToBasicExperimentName } from "../../helpers/jobCloneHelper";
import { GeneralTable } from "../JobList/GeneralTable";
import { JobCreateDateCell } from "../JobList/JobCell/JobCreateDateCell";
import { JobStatusCell } from "../JobList/JobCell/JobStatusCell";

export type MyJobListProps = {
  existingJobs: Job[];
  groupName: string;
  isOpen: boolean;
  requiredTemplates?: string[];
  onClose: () => void;
  onAddJobs: (jobs: Job[]) => void;
};

const useStyles = makeResponsiveStyles(
  {
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      boxSizing: "border-box",
      minHeight: "100%",
      overflowX: "auto",
    },
    columnContainer: {
      display: "flex",
      flexDirection: "column",
    },
    rowContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      ...shorthands.gap("8px"),
    },
    label: {
      ...shorthands.margin("5px", "0", "5px", "0"),
      fontFamily: tokens.fontFamilyBase,
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "16px",
      fontStyle: "normal",
      color: "#605E5C",
    },
    jobList: {
      marginTop: "10px",
      maxHeight: "400px",
      overflowY: "auto",
      paddingRight: "10px",
    },
  },
  {
    xs: {
      rowContainer: {
        width: "70%",
      },
    },
    sm: {
      rowContainer: {
        width: "70%",
      },
    },
    md: {
      rowContainer: {
        width: "70%",
      },
    },
    lg: {
      rowContainer: {
        maxWidth: "70%",
      },
    },
  },
);

export const MyJobListDialog = (props: MyJobListProps) => {
  const styles = useStyles();
  const [jobs, setJobs] = React.useState<Job[]>([]);

  const [startedId, setStartedId] = React.useState<number | undefined>(
    undefined,
  );
  const [selectedStatus, setSelectedStatus] = React.useState<string>(
    JobStatusOptions[4],
  );
  const [jobNameKeyword, setJobNameKeyword] = React.useState<
    string | undefined
  >(undefined);
  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isFetching, setIsFetching] = React.useState<boolean>(true);
  const [selectedJobs, setSelectedJobs] = React.useState<Job[]>([]);

  const filterExistingJobs = (jobList: Job[]) => {
    const existingJobIds = new Set(props.existingJobs.map((job) => job.ID));

    const requiredTemplates = props.requiredTemplates ?? [];
    if (requiredTemplates.length > 0) {
      return jobList.filter(
        (job) =>
          !existingJobIds.has(job.ID) &&
          requiredTemplates.includes(
            convertProductExperimentNameToBasicExperimentName(
              job.ExperimentName ?? "",
            ),
          ),
      );
    }
    return jobList.filter((job) => !existingJobIds.has(job.ID));
  };

  const refreshList = () => {
    setIsLoading(true);
    getJobs({
      JobStatus: selectedStatus === "All" ? "" : selectedStatus,
      Keyword: jobNameKeyword,
      Filter: "my",
    }).then((rawJobList) => {
      if (rawJobList.length > 0) {
        const lastJob = rawJobList[rawJobList.length - 1];
        setStartedId(lastJob.ID - 1);
      }
      const joblist = filterExistingJobs(rawJobList);
      setIsLoading(false);
      setIsFetching(false);
      setJobs(joblist);
      setHasMore(joblist.length > 0);
    });
  };

  useEffect(() => {
    if (!props.isOpen) {
      return;
    }
    refreshList();
  }, [props.isOpen, selectedStatus, jobNameKeyword]);

  const resetState = () => {
    setJobs([]);
    setStartedId(undefined);
    setSelectedStatus(JobStatusOptions[4]);
    setJobNameKeyword(undefined);
    setHasMore(true);
    setIsLoading(true);
    setIsFetching(true);
    setSelectedJobs([]);
  };

  const fetchNext = () => {
    if (!hasMore || isFetching || startedId === undefined) {
      return;
    }
    setIsFetching(true);
    getJobs({
      StartedId: startedId,
      JobStatus: selectedStatus === "All" ? "" : selectedStatus,
      Keyword: jobNameKeyword,
      Filter: "my",
    }).then((rawJobList) => {
      if (rawJobList.length > 0) {
        const lastJob = rawJobList[rawJobList.length - 1];
        setStartedId(lastJob.ID - 1);
      }
      const joblist = filterExistingJobs(rawJobList);
      setJobs((prev) => [...prev, ...joblist]);
      setHasMore(joblist.length > 0);
      setIsFetching(false);
    });
  };

  const renderList = () => {
    if (jobs.length === 0) {
      return <div>No jobs to be added.</div>;
    }
    return (
      <div id="job-list" style={{ height: "300px", overflow: "auto" }}>
        <GeneralTable
          loadMore={fetchNext}
          hasMore={hasMore}
          rows={jobs}
          scrollableTarget="job-list"
          columns={[
            {
              title: "",
              render: (job) => (
                <Checkbox
                  data-testid={`job-checkbox-${job.ID}`}
                  shape="circular"
                  onChange={(_, data) => {
                    if (data.checked) {
                      setSelectedJobs((prev) => [...prev, job]);
                    } else {
                      setSelectedJobs((prev) =>
                        prev.filter((selectedJob) => selectedJob.ID !== job.ID),
                      );
                    }
                  }}
                  label=""
                />
              ),
              weight: 0,
              minWidth: "50px",
            },
            {
              title: "JobName",
              render: (job) => <>{job.JobName}</>,
              weight: 3,
              minWidth: "300px",
            },
            {
              title: "Status",
              render: (job) => <JobStatusCell job={job} />,
              weight: 1,
              minWidth: "100px",
            },
            {
              title: "Date",
              render: (job) => <JobCreateDateCell job={job} />,
              weight: 2,
              minWidth: "200px",
            },
          ]}
        />
      </div>
    );
  };
  return (
    <Dialog open={props.isOpen}>
      <DialogSurface style={{ minWidth: "1000px" }}>
        <DialogBody>
          <DialogTitle>
            Add existing jobs to [Group]{props.groupName}
          </DialogTitle>
          <DialogContent>
            <div className={styles.rowContainer}>
              <ResponsiveRow
                maxColumnCount={2}
                maxColumnCountSmall={1}
                columnGap={8}
              >
                <div className={styles.columnContainer}>
                  <label className={styles.label}>Status</label>
                  <Dropdown
                    style={{ minWidth: "100px" }}
                    aria-label="Status Filering Dropdown"
                    defaultValue={JobStatusOptions[4]}
                    defaultSelectedOptions={[JobStatusOptions[4]]}
                    onOptionSelect={(_, data) => {
                      setSelectedStatus(
                        data.optionValue ?? JobStatusOptions[4],
                      );
                    }}
                  >
                    {JobStatusOptions.map((option) => (
                      <Option key={option}>{option}</Option>
                    ))}
                  </Dropdown>
                </div>
                <div className={styles.columnContainer}>
                  <label className={styles.label}>Job Name</label>
                  <Input
                    style={{ minWidth: "100px" }}
                    appearance="outline"
                    placeholder={"Type Job Name"}
                    value={jobNameKeyword}
                    onKeyDown={(ev) => {
                      if (ev.key === "Enter") {
                        setJobNameKeyword(jobNameKeyword);
                      }
                    }}
                    onChange={(_, data) => {
                      setJobNameKeyword(data.value);
                    }}
                  />
                </div>
              </ResponsiveRow>
            </div>
            <div className={styles.jobList}>
              {isLoading ? <Spinner /> : renderList()}
            </div>
            {selectedJobs &&
              selectedJobs.length > 0 &&
              `${selectedJobs.length} jobs selected.`}
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button
                appearance="primary"
                onClick={() => {
                  props.onAddJobs(selectedJobs);
                  props.onClose();
                }}
                disabled={selectedJobs.length === 0}
              >
                Add
              </Button>
            </DialogTrigger>
            <DialogTrigger disableButtonEnhancement>
              <Button
                onClick={() => {
                  props.onClose();
                  resetState();
                }}
              >
                Close
              </Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
