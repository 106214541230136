import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  Input,
  makeStyles,
  Spinner,
} from "@fluentui/react-components";
import { renameJob } from "@seval-portal/client-utils";
import React from "react";

type JobRenameDialogProps = {
  isOpen: boolean;
  jobName: string;
  jobId: number;
  onClose: (editedName: string) => void;
};

const useStyles = makeStyles({
  dialog: {
    maxWidth: "600px",
  },
  body: {
    whiteSpace: "pre",
  },
  block: {
    marginBottom: "20px",
  },
  blockTitle: {
    fontWeight: "bold",
  },
});

export const JobRenameDialog = (props: JobRenameDialogProps) => {
  const styles = useStyles();
  const { isOpen, jobName, jobId, onClose } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [newJobName, setNewJobName] = React.useState(jobName);
  const [isFocused, setIsFocused] = React.useState(false);

  React.useEffect(() => {
    if (isOpen) {
      setNewJobName(jobName);
    }
  }, [isOpen, jobName]);

  const isNameChanged = newJobName !== jobName;

  return (
    <Dialog open={isOpen} onOpenChange={() => {}}>
      <DialogSurface className={styles.dialog}>
        {isLoading ? <Spinner /> : null}
        {!isLoading && (
          <DialogBody>
            <DialogTitle>Rename Job</DialogTitle>
            <DialogContent>
              <div className={styles.block}>
                <Field
                  label={
                    <span className={styles.blockTitle}>New Job Name:</span>
                  }
                >
                  <Input
                    size="medium"
                    placeholder={isFocused || isNameChanged ? "" : jobName}
                    value={isFocused || isNameChanged ? newJobName : ""}
                    onChange={(_, data) => {
                      setNewJobName(data.value);
                    }}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => {
                      if (!newJobName) {
                        setNewJobName(jobName);
                        setIsFocused(false);
                      }
                    }}
                  />
                </Field>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setIsLoading(true);
                  renameJob({ JobId: jobId, newJobName: newJobName }).then(
                    () => {
                      onClose(newJobName);
                      setIsLoading(false);
                    },
                  );
                }}
                appearance={isNameChanged ? "primary" : "secondary"}
                disabled={!isNameChanged}
              >
                Save and Close
              </Button>
              <Button
                onClick={() => {
                  onClose(jobName);
                }}
                appearance="secondary"
              >
                Cancel
              </Button>
            </DialogActions>
          </DialogBody>
        )}
      </DialogSurface>
    </Dialog>
  );
};
