import {
  makeStyles,
  mergeClasses,
  shorthands,
} from "@fluentui/react-components";
import { useMotion } from "@fluentui/react-motion-preview";
import { store } from "@seval-portal/client-store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { closeSidePane } from "../../mutators/sidePaneMutators";
import { contributionStore } from "../../store/contributionStore";
import type { WrapperProps } from "./WrapperProps";

const useStyles = makeStyles({
  sidePane: {
    maxWidth: "100%",
    position: "absolute",
    height: "100%",
    boxSizing: "border-box",
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
    boxShadow: "0 0 10px 0 rgba(0,0,0,0.5)",
    ...shorthands.padding("24px"),
    ...shorthands.overflow("auto"),
    backgroundColor: "white",

    transform: "translateX(100%)",
    transitionDuration: "0.25s",
    transitionDelay: "0s",
    transitionProperty: "transform",
    willChange: "transform",
  },
  narrow: {
    width: "350px",
  },
  wide: {
    width: "680px",
  },
  visible: {
    transform: "translateX(0%)",
  },
});

export const SidePaneProvider = observer((props: WrapperProps) => {
  const styles = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [size, setSize] = React.useState<"narrow" | "wide">("wide");
  const [element, setElement] = React.useState<JSX.Element>(<></>);
  const motion = useMotion<HTMLDivElement>(isOpen);

  useEffect(() => {
    setSize(store.sidePane.size);
    if (store.sidePane.render) {
      setElement(store.sidePane.render());
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [store.sidePane.render]);

  useEffect(() => {
    closeSidePane();
  }, [contributionStore.currentPath]);

  return (
    <>
      {props.children}
      {motion.canRender && (
        <div
          data-testid="side-pane"
          ref={motion.ref}
          className={mergeClasses(
            styles.sidePane,
            motion.active && styles.visible,
            size === "narrow" ? styles.narrow : styles.wide,
          )}
        >
          {element}
        </div>
      )}
    </>
  );
});
