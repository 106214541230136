import type { ClientFeatureFlag, TypeGuard } from "@seval-portal/shared";
import {
  ClientFeatureFlagSets,
  object,
  optional,
  str,
} from "@seval-portal/shared";

import { AuthOverrides } from "@seval-portal/client-models";
import { parseJsonStrWithDefault } from "@seval-portal/shared";

const LocalCache = object({
  featureFlags: optional(ClientFeatureFlagSets),
  flightedFeatureFlags: optional(ClientFeatureFlagSets),
  authOverrides: optional(AuthOverrides),
  cachedSubstrateToken: optional(str),
  cachedScrapingServiceToken: optional(str),
  cachedHeronAccessToken: optional(str),
  cachedAvalonAccessToken: optional(str),
});

type LocalCache = ReturnType<typeof LocalCache>;

export const LOCAL_CACHE_KEY = "LOCAL_CACHE";
const CONTRIBUTION_CACHE_KEY_PREFIX = "SEVAL_CONTRIBUTION";

const getLocalCache = () => {
  const cacheString = localStorage.getItem(LOCAL_CACHE_KEY);
  if (cacheString !== null) {
    return parseJsonStrWithDefault(cacheString, LocalCache, {});
  } else {
    return {};
  }
};

const setLocalCache = (localCache: LocalCache) => {
  localStorage.setItem(LOCAL_CACHE_KEY, JSON.stringify(localCache));
};

export const getCachedSubstrateToken = () => {
  return getLocalCache().cachedSubstrateToken;
};

export const setCachedSubstrateToken = (token: string | undefined) => {
  const localCache = getLocalCache();
  localCache.cachedSubstrateToken = token;
  setLocalCache(localCache);
};

export const getCachedScrapingServiceToken = () => {
  return getLocalCache().cachedScrapingServiceToken;
};

export const setCachedScrapingServiceToken = (token: string | undefined) => {
  const localCache = getLocalCache();
  localCache.cachedScrapingServiceToken = token;
  setLocalCache(localCache);
};

export const getCachedHerontAccessToken = () => {
  return getLocalCache().cachedHeronAccessToken;
};

export const setCachedHeronAccessToken = (token: string | undefined) => {
  const localCache = getLocalCache();
  localCache.cachedHeronAccessToken = token;
  setLocalCache(localCache);
};

export const getCachedAvalonAccessToken = () => {
  return getLocalCache().cachedAvalonAccessToken;
};

export const setCachedAvalonAccessToken = (token: string | undefined) => {
  const localCache = getLocalCache();
  localCache.cachedAvalonAccessToken = token;
  setLocalCache(localCache);
};

export const getAuthOverrides = () => {
  return getLocalCache().authOverrides;
};

export const setAuthOverrides = (overrides: AuthOverrides) => {
  const localCache = getLocalCache();
  localCache.authOverrides = overrides;
  setLocalCache(localCache);
};

export const cleanAuthOverrides = () => {
  const localCache = getLocalCache();
  localCache.authOverrides = undefined;
  setLocalCache(localCache);
};

export const setFeatureFlagOverride = (
  featureFlag: ClientFeatureFlag,
  isEnabled: boolean,
) => {
  const localCache = getLocalCache();
  localCache.featureFlags = localCache.featureFlags ?? {};
  localCache.featureFlags[featureFlag] = isEnabled;
  setLocalCache(localCache);
};

export const getFeatureFlagOverrides = () => {
  return getLocalCache().featureFlags ?? {};
};

export const cleanFeatureFlagOverrides = () => {
  const localCache = getLocalCache();
  localCache.featureFlags = undefined;
  setLocalCache(localCache);
};

export const setFlightedFeatureFlags = (
  featureFlags: ClientFeatureFlagSets,
) => {
  const localCache = getLocalCache();
  localCache.flightedFeatureFlags = featureFlags;
  setLocalCache(localCache);
};

export const getFlightedFeatureFlags = () => {
  return getLocalCache().flightedFeatureFlags ?? {};
};

const setContributionCache = (key: string, cache: object) => {
  localStorage.setItem(
    `${CONTRIBUTION_CACHE_KEY_PREFIX}_${key}`,
    JSON.stringify(cache),
  );
};

const getContributionCache = <T>(
  key: string,
  typeGuard: TypeGuard<T>,
): T | undefined => {
  try {
    return typeGuard(
      JSON.parse(
        localStorage.getItem(`${CONTRIBUTION_CACHE_KEY_PREFIX}_${key}`) ?? "{}",
      ),
      "ContributionCache",
    );
  } catch {
    return undefined;
  }
};

export const generateSetLocalCache = (key: string | undefined) => {
  if (key === undefined) {
    return undefined;
  }

  return (cache: object) => {
    setContributionCache(key, cache);
  };
};

export const generateGetLocalCache = (key: string | undefined) => {
  if (key === undefined) {
    return undefined;
  }

  return <T>(typeGuard: TypeGuard<T>) => {
    return getContributionCache<T>(key, typeGuard);
  };
};

export const clearLocalStorgeExceptLocalCache = () => {
  // Clear all local storage except LOCAL_CACHE
  const keysToRemove = Object.keys(window.localStorage).filter(
    (key) => key !== LOCAL_CACHE_KEY,
  );
  for (const key of keysToRemove) {
    window.localStorage.removeItem(key);
  }
};
