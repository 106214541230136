"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServerFeatureFlagConfig = exports.ServerFeatureFlagDefaults = exports.ServerFeatureFlagSets = void 0;
const typeHelper_1 = require("../tools/typeHelper");
exports.ServerFeatureFlagSets = (0, typeHelper_1.object)({
    "redact-secret": (0, typeHelper_1.optional)(typeHelper_1.bool),
    "enable-mise": (0, typeHelper_1.optional)(typeHelper_1.bool),
    "bot-framework": (0, typeHelper_1.optional)(typeHelper_1.bool),
    "migrate-scheduler-sydney-client-secret": (0, typeHelper_1.optional)(typeHelper_1.bool),
    active_region_for_Cloverport_centralus: (0, typeHelper_1.optional)(typeHelper_1.bool), // used to identify whether the centralus region is active for Cloverport, eg workspace: amlworkspacenc5ha7hf54ewm
    active_region_for_Cloverport_eastus: (0, typeHelper_1.optional)(typeHelper_1.bool), // used to identify whether the eastus region is active for Cloverport, eg workspace: amlworkspacefzppqu5lm3sek
    active_region_for_Cloverport_eastus2: (0, typeHelper_1.optional)(typeHelper_1.bool), // used to identify whether the eastus2 region is active for Cloverport, eg workspace: amlworkspacep7rwdmnxntcpg
    active_region_for_Consumer_centralus: (0, typeHelper_1.optional)(typeHelper_1.bool), // used to identify whether the centralus region is active for Consumer, eg workspace: deepleo-seval-ws
    active_region_for_Consumer_eastus: (0, typeHelper_1.optional)(typeHelper_1.bool), // used to identify whether the eastus region is active for Consumer
    active_region_for_Consumer_eastus2: (0, typeHelper_1.optional)(typeHelper_1.bool), // used to identify whether the eastus2 region is active for Consumer
    "active_region_for_MSIT-eyesoff_centralus": (0, typeHelper_1.optional)(typeHelper_1.bool), // used to identify whether the centralus region is active for MSIT-eyesoff, eg workspace: amlworkspacengvacfarslewo
    "active_region_for_MSIT-eyesoff_eastus": (0, typeHelper_1.optional)(typeHelper_1.bool), // used to identify whether the eastus region is active for MSIT-eyesoff
    "active_region_for_MSIT-eyesoff_eastus2": (0, typeHelper_1.optional)(typeHelper_1.bool), // used to identify whether the eastus2 region is active for MSIT-eyesoff, eg workspace: amlworkspaceisg62ym3btsni
});
exports.ServerFeatureFlagDefaults = {
    "redact-secret": false,
    "enable-mise": false,
    "bot-framework": false,
    "migrate-scheduler-sydney-client-secret": false,
    active_region_for_Cloverport_centralus: false,
    active_region_for_Cloverport_eastus: false,
    active_region_for_Cloverport_eastus2: false,
    active_region_for_Consumer_centralus: false,
    active_region_for_Consumer_eastus: false,
    active_region_for_Consumer_eastus2: false,
    "active_region_for_MSIT-eyesoff_centralus": false,
    "active_region_for_MSIT-eyesoff_eastus": false,
    "active_region_for_MSIT-eyesoff_eastus2": false,
};
exports.ServerFeatureFlagConfig = (0, typeHelper_1.object)({
    seval: (0, typeHelper_1.object)({
        server: exports.ServerFeatureFlagSets,
    }),
});
