import type { AppConfigurationSnapshot } from "@seval-portal/client-models";
import type { SettingFileName } from "@seval-portal/shared";
import { createStore } from "satcheljs";

export type AppConfigurationStore = {
  selectedFileName: SettingFileName | undefined;
  snapshots: AppConfigurationSnapshot[] | undefined;
  selectedSnapshot: AppConfigurationSnapshot | undefined;
  selectedSnapshotContent: string | undefined;
  showConfigEditHistory: boolean;
  showConfigWithIdentifiers: boolean;
};

export const initialStore: AppConfigurationStore = {
  selectedFileName: undefined,
  snapshots: undefined,
  selectedSnapshot: undefined,
  selectedSnapshotContent: undefined,
  showConfigEditHistory: true,
  showConfigWithIdentifiers: false,
};

export const appConfigurationStore = createStore<AppConfigurationStore>(
  "appConfigurationStore",
  Object.assign({}, initialStore),
)();

export const isSelectedSnapshot = (snapshot: AppConfigurationSnapshot) => {
  const selectedSnapshot = appConfigurationStore.selectedSnapshot;
  return (
    selectedSnapshot !== undefined &&
    selectedSnapshot.snapshot === snapshot.snapshot &&
    selectedSnapshot.name === snapshot.name &&
    selectedSnapshot.properties.createdOn === snapshot.properties.createdOn &&
    selectedSnapshot.properties.lastModified ===
      snapshot.properties.lastModified
  );
};
