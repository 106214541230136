import { getMetricCategory, getMetricGroups } from "./metricsMapping";

import { DiagnosisPageMetrics } from "@seval-portal/client-utils";
import type { Metric } from "../models/GeneralMetricsResponse";
import type { MetricsGroup } from "./metricsMapping";

export interface ExportedMetricsData {
  segment: string;
  category: string;
  metric: string;
  group: MetricsGroup;
  control: string;
  treatment: string;
  delta: string;
  pairedN: string;
  pValue: string;
}

export interface MetricsData {
  category: string;
  categoryIndex: number;
  group: MetricsGroup;
  metric: string;
  segment: string;
  control: string;
  treatment: string;
  delta: string;
  n: string;
  droppedNarrows: string;
  pValue: string;
}

export interface MetricCategory {
  category: string;
  categoryIndex: number;
  stage: string;
}

export const convertJSONToMetricsData = (jsonList: Metric[]): MetricsData[] => {
  const sortMetricsData = (a: MetricsData, b: MetricsData): number => {
    // Sort by category first, category index smaller in the first
    // if category index is the same, then sort alphabetically by metrics_key
    if (a.categoryIndex === b.categoryIndex) {
      return a.metric.localeCompare(b.metric);
    } else {
      return a.categoryIndex - b.categoryIndex;
    }
  };

  return jsonList
    .flatMap((metric) => {
      const {
        metrics_key,
        segment,
        score_control,
        score_experiment,
        score_delta,
        p_value,
        n,
        dropped_narows,
      } = metric;

      const category = getMetricCategory(metrics_key.toLowerCase());
      const groups = getMetricGroups(metrics_key.toLowerCase());

      return groups.map((group) => ({
        category: category.category,
        categoryIndex: category.categoryIndex,
        group,
        metric: metrics_key,
        segment,
        control: score_control,
        treatment: score_experiment,
        delta: score_delta,
        n: n ?? "",
        droppedNarrows: dropped_narows ?? "",
        pValue: p_value ?? "",
      }));
    })
    .sort(sortMetricsData);
};

export const mapMetricsKey = (key: string): string => {
  return DiagnosisPageMetrics[key] ?? key;
};
