import {
  Button,
  makeStyles,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { AddCircle24Filled } from "@fluentui/react-icons";
import { isFeatureEnabled } from "@seval-portal/client-store";
import { observer } from "mobx-react-lite";
import { updateCurrentPath } from "../../../../../mutators/updateContributions";
import { createJobGroupAction } from "../../../actions/jobGroupActions";
import { updateIsOpenProductSelectDialog } from "../../../mutators/jobCreationMutators";
import { updateJobPermission } from "../../../mutators/jobShareMutators";

const useStyles = makeStyles({
  newEvaluationButton: {
    ...shorthands.padding("10px", "12px"),
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
    width: "200px",
    height: "44px",
    color: "white",
    marginLeft: "auto",
    fontFamily: tokens.fontFamilyBase,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
  },
  icon: {
    opacity: 0.5,
    color: "#fff",
  },
});

export const CreateGroupButton = observer(() => {
  const styles = useStyles();
  const createGroup = () => {
    updateCurrentPath("/groupCreate");
    updateJobPermission([]);
    updateIsOpenProductSelectDialog(false);
    createJobGroupAction();
  };
  return (
    <div>
      {isFeatureEnabled("enable-job-group") && (
        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <MenuButton
              className={styles.newEvaluationButton}
              appearance="primary"
              icon={<AddCircle24Filled className={styles.icon} />}
            >
              New Evaluation
            </MenuButton>
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              <MenuItem
                onClick={() => {
                  updateIsOpenProductSelectDialog(true);
                }}
              >
                Single Job
              </MenuItem>
              <MenuItem onClick={createGroup}>Group Job</MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
      )}
      {!isFeatureEnabled("enable-job-group") && (
        <Button
          className={styles.newEvaluationButton}
          appearance="primary"
          icon={<AddCircle24Filled className={styles.icon} />}
          onClick={() => {
            updateIsOpenProductSelectDialog(true);
          }}
        >
          New Evaluation
        </Button>
      )}
    </div>
  );
});
