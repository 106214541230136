import type { JobConfigurationUpdateAction } from "@seval-portal/client-models";
import { getValue } from "../../getJobPropV2";

export const checkScrapingCacheName = <
  T extends JobConfigurationUpdateAction["prop"],
>(
  valueProp: T,
  path: string,
) => {
  const value: string | number | undefined = getValue(valueProp);
  if (value === undefined || value === "" || typeof value !== "string") {
    return {
      path,
      message: valueProp + ": This field should not be empty!",
    };
  }

  try {
    const parsed: { display_name: string } = JSON.parse(value);
    if (typeof parsed !== "object" || parsed === null) {
      return {
        path,
        message: valueProp + ": This field should be an object!",
      };
    } else if (
      parsed.display_name === undefined ||
      parsed.display_name === ""
    ) {
      return {
        path,
        message: valueProp + ": This field should not be empty!",
      };
    }
    return undefined;
  } catch (e) {
    return {
      path,
      message: valueProp + ":This field should be a valid JSON!",
    };
  }
};
