import type { Job } from "@seval-portal/client-models";
import { isFeatureEnabled, store } from "@seval-portal/client-store";
import { JobGroupSettings, parseJsonStrOptional } from "@seval-portal/shared";
import { computed } from "mobx";
import { groupDetailStore } from "../store/groupDetailStore";

export const notGroupOwner = computed(() => {
  return groupDetailStore.group?.CreatorSmtpAddress !== store.account?.username;
});

export const isJobRemovable = (job: Job) => {
  const isGroupOwner = !notGroupOwner.get();
  const isJobGroupStage2 = isFeatureEnabled("job-group-stage-2");
  const groupTemplate = groupDetailStore.groupTemplates.find(
    (template) => template.Id === groupDetailStore.group?.GroupTemplateId,
  );
  const templateObject = parseJsonStrOptional(
    groupTemplate?.Settings,
    JobGroupSettings,
  );
  const currentTemplate = templateObject?.find((template) =>
    job.ExperimentName?.includes(template.templateName),
  );
  const isRequiredJob = currentTemplate?.isRequired === true;
  const isLastJob =
    groupDetailStore.jobs.filter((i) => job.ExperimentName === i.ExperimentName)
      .length === 1;
  const isLastRequiredJob = isLastJob && isRequiredJob;
  return isGroupOwner && !isLastRequiredJob && isJobGroupStage2;
};
