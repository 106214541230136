import { isFeatureEnabled } from "@seval-portal/client-store";
import { observer } from "mobx-react-lite";
import { refreshCustomQuerySets } from "../../../helpers/jobCreationHelper";
import { updateCustomizedQuerySets } from "../../../mutators/jobCreationFileMutators";
import { getValue } from "../../../selectors/getJobPropV2";
import { jobCreationFileStore } from "../../../store/jobCreationFileStore";
import { jobStore } from "../../../store/jobStore";
import { JobEvalTimeOverrideView } from "../components/JobEvalTimeOverrideView";
import { JobGPTIdentifierView } from "../components/JobGPTIdentifierView";
import { JobMetricsBlockView } from "../components/JobMetricsBlockView";
import { JobSbsExpContainer } from "../components/JobSBSExpContainer";
import { JobSelectedFileView } from "../components/JobSelectedFileView";
import { JobUploadFolderView } from "../components/JobUploadFolderView";
import { JobUserIdView } from "../components/JobUserIdView";
import { JobPairExpMultiServiceView } from "../ConfigComponents/JobPairExpMultiServiceView";
import { useJobCreationStyles } from "./useJobCreationStyles";

const renderDivider = () => {
  const styles = useJobCreationStyles();
  return <div className={styles.divider} />;
};

export const UniversalSearchTemplateRender = observer(() => {
  const selectedTemplate = jobStore.selectedTemplate;
  if (selectedTemplate === undefined) {
    return <></>;
  }
  return (
    <>
      <JobUploadFolderView label="Upload Scraped Results" />
      <JobMetricsBlockView />
      <>
        {getValue("configs_in_json.conversations.input_file") !== undefined && (
          <JobSelectedFileView
            customQuerySets={jobCreationFileStore.customQuerySets}
            setCustomQuerySets={updateCustomizedQuerySets}
            refreshCustomQuerySets={refreshCustomQuerySets}
          />
        )}
        <JobUserIdView />
      </>
      {isFeatureEnabled("eval-time-override") && <JobEvalTimeOverrideView />}
      {renderDivider()}
      <JobPairExpMultiServiceView />
      {!isFeatureEnabled("gpt-agent-list") && <JobGPTIdentifierView />}
      <JobSbsExpContainer />
      {renderDivider()}
    </>
  );
});
