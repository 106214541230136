import { Button, Field, tokens } from "@fluentui/react-components";
import {
  Add16Regular,
  CopyAddRegular,
  Delete16Regular,
  Edit16Regular,
  Warning20Filled,
} from "@fluentui/react-icons";
import { isFeatureEnabled } from "@seval-portal/client-store";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { StatusTile } from "../../../../../components/Shared/StatusTile";
import { Tip } from "../../../../../components/Shared/Tip";
import { useToast } from "../../../../../components/Wrappers/ToasterProvider";
import {
  addEditSubJobAction,
  deleteSubJobAction,
  dupSubJobAction,
} from "../../../actions/jobGroupActions";
import { getJobCompletionError } from "../../../helpers/creationDataHelper";
import type { LocalJobSettings } from "../../../store/jobGroupCreationStore";
import { jobGroupCreationStore } from "../../../store/jobGroupCreationStore";
import { GeneralTable } from "../../JobList/GeneralTable";
import { JobStatusCell } from "../../JobList/JobCell/JobStatusCell";
import { useGroupStyles } from "../groupStyles";
import { AddNewJobButton } from "./AddNewJobButton";

export const GroupJobView = observer(() => {
  const styles = useGroupStyles();
  const emptyFunction = () => void 0;

  const isPredefined =
    jobGroupCreationStore.selectedGroupTemplate?.Type === "Predefined";

  const jobCompletionError = getJobCompletionError.get();

  const isStageNotEnable = isFeatureEnabled("job-group-stage-2");

  const toast = useToast();
  useEffect(() => {
    if (!isStageNotEnable) {
      return;
    }
    const toastStatus = jobGroupCreationStore.toastStatus;
    if (toastStatus === undefined) {
      toast.onToastDismiss();
    } else {
      switch (toastStatus.state) {
        case "success":
          toast.onToastSuccess(toastStatus.message);
          break;
        case "error":
          toast.onToastFailure(toastStatus.message);
          break;
        case "start":
          toast.onToastStart(toastStatus.message);
          break;
        case "update":
          toast.onToastProgressUpdate(toastStatus.message);
          break;
      }
    }
  }, [jobGroupCreationStore.toastStatus]);

  if (!isStageNotEnable) {
    return (
      <div className={styles.block}>
        <Field
          required
          label={<span className={styles.blockTitle}>Jobs</span>}
          validationMessage={jobCompletionError}
          validationState={jobCompletionError ? "error" : undefined}
        ></Field>
        <GeneralTable
          loadMore={emptyFunction}
          hasMore={false}
          rows={jobGroupCreationStore.jobs}
          columns={[
            {
              title: "Template",
              render: (job) => <>{job.templateName}</>,
              weight: 3,
              minWidth: "250px",
            },
            {
              title: "Job Name",
              render: (job) => (
                <>
                  {job.configuration === undefined ? (
                    <>
                      {" "}
                      {job.type === "required" && (
                        <Warning20Filled
                          color={tokens.colorStatusWarningForeground1}
                        />
                      )}
                      {"  "}
                      No Job Added{" "}
                    </>
                  ) : (
                    <>{job.jobName}</>
                  )}
                </>
              ),
              weight: 3,
              minWidth: "400px",
            },
            {
              title: "Action",
              render: (job) => (
                <>
                  {job.configuration ? (
                    <Button
                      icon={<Edit16Regular />}
                      onClick={() => addEditSubJobAction(job.localId, "edit")}
                    >
                      Edit
                    </Button>
                  ) : (
                    <Button
                      icon={<Add16Regular />}
                      onClick={() => addEditSubJobAction(job.localId, "add")}
                    >
                      Add
                    </Button>
                  )}
                  {(!isPredefined || job.type !== "required") && (
                    <Button
                      icon={<Delete16Regular />}
                      onClick={() => deleteSubJobAction(job.localId)}
                    >
                      Delete
                    </Button>
                  )}
                </>
              ),
              weight: 2,
              minWidth: "200px",
            },
          ]}
        />
        {!isPredefined && (
          <Button
            className={styles.fixWidthButton}
            icon={<Add16Regular />}
            onClick={() => addEditSubJobAction("New Job", "add")}
          >
            Add
          </Button>
        )}
      </div>
    );
  }
  const PredefinedHint = `Please ensure that all required jobs are added using the preset template before submitting the job group.`;
  const noJobPlaceholder = {
    jobName: "",
    templateName: "",
    configuration: undefined,
    localId: "",
    type: "optional",
  };
  const renderJobTable = (
    index: number | undefined,
    jobs: LocalJobSettings[],
  ) => {
    const currentTemplateName =
      index !== undefined
        ? jobGroupCreationStore.selectedGroupTemplateSettings?.[index]
            .templateName
        : "";
    const hasJobs = jobs.length > 0;
    const displayJobs = hasJobs ? jobs : [noJobPlaceholder];
    return (
      <div key={`job-table-${index}`} className={styles.columnWithSmallerGap}>
        {index !== undefined && (
          <div className={styles.blockTitle}>
            Template {index + 1}: {currentTemplateName}
          </div>
        )}

        <GeneralTable
          loadMore={emptyFunction}
          hasMore={false}
          rows={displayJobs}
          columns={[
            {
              title: "Job Name",
              render: (job) => (
                <>
                  {job.configuration === undefined ? (
                    <>
                      <Warning20Filled
                        color={tokens.colorStatusWarningForeground1}
                      />
                      No Job Added{" "}
                    </>
                  ) : (
                    <Tip content={job.jobName} relationship={"label"}>
                      <div
                        style={{
                          lineBreak: "anywhere",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {job.jobName}
                      </div>
                    </Tip>
                  )}
                </>
              ),
              weight: 3,
              minWidth: "100px",
            },
            {
              title: "Status",
              render: (job) => (
                <>
                  {job.configuration === undefined ? (
                    <></>
                  ) : job.linkedJob === undefined ? (
                    <StatusTile hasClickEvent={false} status={"New Job"} />
                  ) : (
                    <JobStatusCell job={job.linkedJob} />
                  )}
                </>
              ),
              weight: 1,
              minWidth: "100px",
            },
            {
              title: "Action",
              render: (job) => (
                <div className={styles.rowWithSmallGap}>
                  {job.configuration && !job.linkedJob && (
                    <Button
                      icon={<Edit16Regular />}
                      onClick={() => addEditSubJobAction(job.localId, "edit")}
                    >
                      Edit
                    </Button>
                  )}
                  {job.configuration && (
                    <Button
                      icon={<CopyAddRegular />}
                      onClick={() => dupSubJobAction(job.localId)}
                    >
                      Duplicate
                    </Button>
                  )}

                  {hasJobs && (
                    <Button
                      icon={<Delete16Regular />}
                      onClick={() => deleteSubJobAction(job.localId)}
                    >
                      Delete
                    </Button>
                  )}
                </div>
              ),
              weight: 3,
              minWidth: "400px",
            },
          ]}
        />

        <AddNewJobButton
          currentTemplateName={currentTemplateName}
          onClick={() =>
            addEditSubJobAction("New Job", "add", currentTemplateName)
          }
        />
      </div>
    );
  };

  return (
    <div className={styles.block}>
      <Field
        required
        label={<span className={styles.blockTitle}>Jobs</span>}
        validationMessage={jobCompletionError}
        validationState={jobCompletionError ? "error" : undefined}
      ></Field>
      {isPredefined && <div>{PredefinedHint}</div>}{" "}
      {isPredefined &&
        jobGroupCreationStore.selectedGroupTemplateSettings?.map(
          (section, index) =>
            renderJobTable(
              index,
              jobGroupCreationStore.jobs.filter(
                (job) => job.templateName === section.templateName,
              ),
            ),
        )}
      {!isPredefined && renderJobTable(undefined, jobGroupCreationStore.jobs)}
    </div>
  );
});
