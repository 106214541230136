import type {
  TenantUserAccount,
  VirtualAccountInfo,
  VirtualTenantWithVirtualUserIds,
} from "@seval-portal/client-models";
import { mutatorAction } from "satcheljs";
import type {
  AccountType,
  QueryType,
} from "../store/jobCreationSyntheticStore";
import { jobCreationSyntheticStore } from "../store/jobCreationSyntheticStore";

export const setAccountType = mutatorAction(
  "setAccountType",
  (accountType: AccountType) => {
    jobCreationSyntheticStore.accountType = accountType;
  },
);

export const setQueryType = mutatorAction(
  "setQueryType",
  (queryType: QueryType) => {
    jobCreationSyntheticStore.queryType = queryType;
  },
);

export const setSelectedQuerySet = mutatorAction(
  "setSelectedQuerySet",
  (selectedQuerySet: string) => {
    jobCreationSyntheticStore.selectedQuerySet = selectedQuerySet;
  },
);

export const setSelectedUser = mutatorAction(
  "setSelectedUser",
  (user: TenantUserAccount | undefined) => {
    jobCreationSyntheticStore.selectedUser = user;
  },
);

export const toggleDialogOpen = mutatorAction(
  "toggleDialogOpen",
  (isOpen: boolean) => {
    jobCreationSyntheticStore.isOpenSelectSyntheticUserDialog = isOpen;
  },
);

export const setSearchTenant = mutatorAction(
  "setSearchTenant",
  (searchTenant: string) => {
    jobCreationSyntheticStore.searchTenant = searchTenant;
  },
);

export const setSearchTag = mutatorAction(
  "setSearchTag",
  (searchTag: string) => {
    jobCreationSyntheticStore.searchTag = searchTag;
  },
);

export const setSearchRing = mutatorAction(
  "setSearchRing",
  (searchRing: string) => {
    jobCreationSyntheticStore.searchRing = searchRing;
  },
);

export const setSearchCreator = mutatorAction(
  "setSearchCreator",
  (searchCreator: string) => {
    jobCreationSyntheticStore.searchCreator = searchCreator;
  },
);

export const setPersonalAccount = mutatorAction(
  "setPersonalAccount",
  (personalAccount: string) => {
    jobCreationSyntheticStore.personalAccount = personalAccount;
  },
);

export const setTemplateQuerySet = mutatorAction(
  "setTemplateQuerySet",
  (templateQuerySet: string) => {
    jobCreationSyntheticStore.templateQuerySet = templateQuerySet;
  },
);

export const setVirtualTenants = mutatorAction(
  "setVirtualTenants",
  (virtualTenants: VirtualTenantWithVirtualUserIds[]) => {
    jobCreationSyntheticStore.virtualTenants = virtualTenants;
  },
);

export const setQuerySets = mutatorAction(
  "setQuerySets",
  (querySets: string[]) => {
    jobCreationSyntheticStore.querySets = querySets;
  },
);

export const setSelectedVirtualTenant = mutatorAction(
  "setVirtualTenant",
  (virtualTenant: VirtualTenantWithVirtualUserIds) => {
    jobCreationSyntheticStore.selectedVirtualTenant = virtualTenant;
  },
);

export const setSelectedVirtualAccount = mutatorAction(
  "setVirtualAccount",
  (virtualAccount: VirtualAccountInfo) => {
    jobCreationSyntheticStore.selectedVirtualAccount = virtualAccount;
  },
);

export const setOpenItems = mutatorAction(
  "setOpenItems",
  (openItems: string[]) => {
    jobCreationSyntheticStore.openItems = openItems;
  },
);
