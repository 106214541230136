import {
  Button,
  Dropdown,
  Input,
  mergeClasses,
  Option,
  ProgressBar,
} from "@fluentui/react-components";
import { ArrowLeft24Regular, FlashAddRegular } from "@fluentui/react-icons";
import { scheduleJobStatusOptions } from "@seval-portal/client-utils";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ScheduleJobRequestDialog } from "../../../../components/Dialog/ScheduleJobRequestDialog";
import { ResponsiveRow } from "../../../../components/Responsive/ResponsiveRow";
import { InforIconTip } from "../../../../components/Shared/Tip";
import { updateCurrentPath } from "../../../../mutators/updateContributions";
import { isSchedulerManager } from "../../../../selectors/userPermission";
import {
  onScheduleJobAction,
  schedulerPageInitAcion,
  searchScheduleJobsAcion,
  updateSearchCriteriaAcion,
} from "../../actions/scheduleJobActions";
import {
  clearSearchCriteria,
  switchPageMode,
  updateSearchCriteria,
} from "../../mutators/scheduleJobMutators";
import {
  getSchedulerQuotaError,
  mapSchedulerStatus,
} from "../../selectors/scheduleJobSelector";
import { scheduleJobStore } from "../../store/scheduleJobStore";
import { ActiveScheduleJobsTable } from "./ActiveScheduleJobsTable";
import { InforButtonView } from "./components/InfoButtonView";
import { SchedulerActionButton } from "./components/SchedulerActionButton";
import { maxActiveScheduler, quotaLimitTip } from "./constants";
import { ScheduleJobDetailView } from "./ScheduleJobDetailView";
import { ScheduleJobEditView } from "./ScheduleJobEditView";
import { sharedStyles } from "./sharedStyles";

export const JobScheduleView = observer(() => {
  const styles = sharedStyles();

  const { mode, schedulerId } = useParams();

  const { pageMode, isLoading, selectedScheduledJob } = scheduleJobStore;

  useEffect(() => {
    schedulerPageInitAcion(mode, schedulerId);
  }, []);

  if (isLoading) {
    return <ProgressBar />;
  }

  const renderBackArrowButton = () => {
    return (
      <Button
        appearance="transparent"
        onClick={() => {
          clearSearchCriteria();
          searchScheduleJobsAcion();
          switchPageMode("View");
          updateCurrentPath("/scheduler/list");
        }}
        icon={<ArrowLeft24Regular />}
      />
    );
  };

  const renderScheduleJobView = () => {
    if (pageMode === "View") {
      return (
        <div>
          <div className={styles.listFilter}>
            <div className={styles.filterGroup}>
              <ResponsiveRow
                maxColumnCount={3}
                maxColumnCountSmall={1}
                columnGap={8}
              >
                <div className={styles.columnLayout}>
                  <label className={styles.label}>Scheduler Name</label>
                  <Input
                    appearance="outline"
                    placeholder="Type scheduler name"
                    onChange={(_, data) => {
                      updateSearchCriteria("Name", data.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        searchScheduleJobsAcion();
                      }
                    }}
                  />
                </div>
                <div className={styles.columnLayout}>
                  <label className={styles.label}>Create By</label>
                  <Input
                    appearance="outline"
                    placeholder="Type Creator Name"
                    onChange={(_, data) => {
                      updateSearchCriteria("CreateBy", data.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        searchScheduleJobsAcion();
                      }
                    }}
                  />
                </div>

                <div className={styles.columnLayout}>
                  <label className={styles.label}>Status</label>
                  <Dropdown
                    aria-label="Status Filering Dropdown"
                    value={mapSchedulerStatus(
                      scheduleJobStore.searchCriteria.get("Status"),
                    )}
                    onOptionSelect={(_, data) => {
                      if (data.optionValue === "All") {
                        updateSearchCriteriaAcion("Status", undefined);
                      } else {
                        updateSearchCriteriaAcion(
                          "Status",
                          data.optionValue ?? "",
                        );
                      }
                    }}
                  >
                    <Option key={"All"} text="All">
                      {"All"}
                    </Option>
                    {scheduleJobStatusOptions.map((option) => (
                      <Option key={option} text={option}>
                        {mapSchedulerStatus(option)}
                      </Option>
                    ))}
                  </Dropdown>
                </div>
              </ResponsiveRow>
            </div>
            <Button
              data-testid="new-scheduler-button"
              className={styles.newTriggerButton}
              appearance="primary"
              icon={<FlashAddRegular />}
              onClick={() => {
                onScheduleJobAction(undefined, "Create");
                updateCurrentPath("/scheduler/Create");
              }}
            >
              New Scheduler
            </Button>
          </div>
          <ActiveScheduleJobsTable />
        </div>
      );
    }

    if (pageMode === "Create") {
      return (
        <div>
          <div className={styles.rowSpaceBetwween}>
            <div className={styles.headerTitleContainer}>
              {renderBackArrowButton()}
              <div className={styles.headerTitle}>Setup New Scheduler</div>
            </div>
            <div
              className={
                getSchedulerQuotaError.get() ? styles.stopColor : undefined
              }
            >
              Active scheduler quota: {scheduleJobStore.ownedSchedulerCount} /{" "}
              {isSchedulerManager.get() ? "Unlimited" : maxActiveScheduler}
              <InforIconTip content={quotaLimitTip} />
            </div>
          </div>
          <div className={mergeClasses(styles.root, styles.detailContainer)}>
            <ScheduleJobEditView />
          </div>
          <div className={styles.rowCenter}>
            <ScheduleJobRequestDialog />
            <Button
              onClick={() => {
                switchPageMode("View");
                updateCurrentPath("/scheduler/list");
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    }

    if (pageMode === "ReadOnly" && selectedScheduledJob) {
      return (
        <div>
          <div className={styles.rowSpaceBetwween}>
            <div className={styles.headerTitleContainer}>
              {renderBackArrowButton()}
              <div className={styles.headerTitle}>
                {scheduleJobStore.selectedScheduledJob?.Name}
              </div>
            </div>
            <div className={styles.actionButtons}>
              <InforButtonView />

              <SchedulerActionButton
                scheduler={scheduleJobStore.selectedScheduledJob}
                action="Edit"
              />
              <SchedulerActionButton
                scheduler={scheduleJobStore.selectedScheduledJob}
                action="Stop"
              />
              <SchedulerActionButton
                scheduler={scheduleJobStore.selectedScheduledJob}
                action="Enable"
              />
            </div>
          </div>
          <ScheduleJobDetailView />
        </div>
      );
    }

    if (pageMode === "Edit" && selectedScheduledJob) {
      return (
        <div>
          <div className={styles.rowSpaceBetwween}>
            <div className={styles.headerTitleContainer}>
              {renderBackArrowButton()}
              <div className={styles.headerTitle}>
                Edit Scheduler: {selectedScheduledJob.Name}
              </div>
            </div>
          </div>
          <div className={mergeClasses(styles.root, styles.detailContainer)}>
            <ScheduleJobEditView />
          </div>

          <div className={styles.rowCenter}>
            <ScheduleJobRequestDialog />
            <Button
              onClick={() => {
                switchPageMode("View");
                updateCurrentPath("/scheduler/list");
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    }
  };
  return <div className={styles.root}>{renderScheduleJobView()}</div>;
});
