import type { Job, JobTemplate } from "@seval-portal/client-models";
import type { JobGroupData } from "@seval-portal/shared";
import { createStore } from "satcheljs";

interface IJobListStore {
  mode: "job" | "group";
  jobTemplates: JobTemplate[];
  jobs: Job[];
  groups: JobGroupData[];
  groupExpandIndex: number[];
}

export const initJobListStore: IJobListStore = {
  mode: "job",
  jobTemplates: [],
  jobs: [],
  groups: [],
  groupExpandIndex: [],
};

export const jobListStore = createStore<IJobListStore>(
  "jobListStore",
  Object.assign({}, initJobListStore),
)();
