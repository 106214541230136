import { makeStyles, shorthands } from "@fluentui/react-components";
import {
  Home20Filled,
  Home20Regular,
  List20Filled,
  List20Regular,
  Timer20Filled,
  Timer20Regular,
} from "@fluentui/react-icons";

import { telemetryHelper } from "@seval-portal/client-utils";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { jobListStore } from "../../store/jobListStore";
import { FilterButton } from "../Other/FilterButton";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.gap("6px"),
    marginTop: "15px",
    marginBottom: "15px",
    height: "44px",
  },
});

interface IJobFilterViewProps {
  type: string;
  onClickType: (type: string) => void;
}

export const JobFilterView = observer((prop: IJobFilterViewProps) => {
  const { type, onClickType } = prop;
  const styles = useStyles();

  const isShowJobGroup = computed(() => jobListStore.mode === "group").get();
  const clickType = (clickedType: string) => {
    telemetryHelper.logUserActionEvent("ClickJobFilterButton", {
      clickedType,
    });
    onClickType(clickedType);
  };

  return (
    <div className={styles.root}>
      <FilterButton
        value={"all"}
        selectedTab={type}
        text="All"
        activeIcon={<List20Filled />}
        inactionIcon={<List20Regular />}
        clickTab={() => clickType("all")}
      />
      <FilterButton
        value={"my"}
        selectedTab={type}
        text="My Jobs"
        activeIcon={<Home20Filled />}
        inactionIcon={<Home20Regular />}
        clickTab={() => clickType("my")}
      />
      {!isShowJobGroup && (
        <FilterButton
          value={"Scheduled"}
          selectedTab={type}
          text="Scheduled Jobs"
          activeIcon={<Timer20Filled />}
          inactionIcon={<Timer20Regular />}
          clickTab={() => clickType("Scheduled")}
        />
      )}
    </div>
  );
});
