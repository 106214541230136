import type { Job } from "@seval-portal/client-models";
import { action } from "satcheljs";

export const loadGroupDetailAction = action(
  "loadGroupDetailAction",
  (groupId: number) => ({ groupId }),
);

export const refreshGroupJobListAction = action(
  "refreshGroupJobListAction",
  () => ({}),
);

export const switchSelectedJobAction = action(
  "switchSelectedJobAction",
  (job: Job | undefined) => ({ job }),
);
