import { array, object, str } from "@seval-portal/shared";

const ServicePlan = object({
  servicePlanId: str,
  servicePlanName: str,
  provisioningStatus: str,
});

const UserLicense = object({
  id: str,
  skuId: str,
  skuPartNumber: str,
  servicePlans: array(ServicePlan),
});

export const UserLicenseResponse = object({
  value: array(UserLicense),
});

export type UserLicense = ReturnType<typeof UserLicense>;
export type UserLicenseResponse = ReturnType<typeof UserLicenseResponse>;
