import type { QuerySetFile } from "@seval-portal/client-models";
import { createStore } from "satcheljs";
import { QuerySetTableFilter } from "../models/QuerySets";

type QuerySetsStore = {
  isLoading: boolean;
  selectedTab: QuerySetTableFilter;
  selectedProduct: "MChat" | "Bing";
  MChatPublicQuerySets: QuerySetFile[];
  MChatCustomQuerySets: QuerySetFile[];
  MChatGeneratedQuerySets: QuerySetFile[];
  BingCustomQuerySets: QuerySetFile[];
  BingGeneratedQuerySets: QuerySetFile[];
};

export const initQuerySetsStore: QuerySetsStore = {
  isLoading: false,
  selectedProduct: "MChat",
  selectedTab: QuerySetTableFilter.all,
  MChatPublicQuerySets: [],
  MChatCustomQuerySets: [],
  MChatGeneratedQuerySets: [],
  BingCustomQuerySets: [],
  BingGeneratedQuerySets: [],
};

export const querySetsStore = createStore<QuerySetsStore>(
  "querySetsStore",
  Object.assign({}, initQuerySetsStore),
)();
