import type {
  TenantUserAccount,
  VirtualAccountInfo,
  VirtualTenantWithVirtualUserIds,
} from "@seval-portal/client-models";
import { createStore } from "satcheljs";

export enum AccountType {
  UseSyntheticTenant = "Use synthetic tenant",
  InputManually = "Input manually",
}

export enum QueryType {
  LinkSyntheticTenant = "Linked with synthetic tenant",
  UploadQuerySet = "Upload query set",
}

interface IJobCreationSyntheticStore {
  isOpenSelectSyntheticUserDialog: boolean;
  selectedUser?: TenantUserAccount;
  accountType: AccountType;
  queryType: QueryType;
  selectedQuerySet: string;
  personalAccount: string;
  templateQuerySet: string;
  searchTenant: string;
  searchTag: string;
  searchRing: string;
  searchCreator: string;
  virtualTenants: VirtualTenantWithVirtualUserIds[];
  querySets: string[];
  selectedVirtualTenant?: VirtualTenantWithVirtualUserIds;
  selectedVirtualAccount?: VirtualAccountInfo;
  openItems: string[];
}

export const initSyntheticStore: IJobCreationSyntheticStore = {
  isOpenSelectSyntheticUserDialog: false,
  selectedUser: undefined,
  accountType: AccountType.UseSyntheticTenant,
  queryType: QueryType.LinkSyntheticTenant,
  selectedQuerySet: "",
  personalAccount: "",
  templateQuerySet: "",
  searchTenant: "",
  searchTag: "",
  searchRing: "",
  searchCreator: "",
  virtualTenants: [],
  querySets: [],
  openItems: [],
};

export const jobCreationSyntheticStore =
  createStore<IJobCreationSyntheticStore>(
    "syntheticStore",
    Object.assign({}, initSyntheticStore),
  )();
