import {
  Button,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import {
  dashboard_BingAllJobs,
  dashboard_BingMultiturn,
  dashboard_BingSingleturn,
} from "@seval-portal/client-utils";
import { observer } from "mobx-react";
import { resultStore } from "../../../store/resultStore";
import { useDetailPageStyles } from "../sharedStyles";

const useStyles = makeStyles({
  buttonContainer: {
    height: "20px",
    maxWidth: "200px",
    justifyContent: "center",
    alignjobs: "center",
    display: "flex",
    flexDirection: "row",
    backgroundColor: tokens.colorBrandBackground2,
    ...shorthands.borderRadius("4px"),
    ...shorthands.borderWidth("0px"),
  },
  buttonText: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "12px",
    lineHeight: "16px",
    fontStyle: "normal",
    opacity: 1,
    color: tokens.colorBrandForegroundLink,
  },
  buttonRow: {
    display: "flex",
    flexDirection: "row",
  },
});

export const BingMonitorView = observer(() => {
  const styles = useDetailPageStyles();
  const buttonStyles = useStyles();

  const job = resultStore.resultJob;

  const isBingScheduleJob =
    job?.Properties?.IsBingJob && job?.JobType === "Scheduled";
  const isBingScheduleJobSingleTurn =
    isBingScheduleJob && job?.ExperimentName === "Scheduled_Bing_singleturn";

  const getMonitorLink = () => {
    if (isBingScheduleJobSingleTurn) {
      return dashboard_BingSingleturn;
    }
    if (job?.ExperimentName === "Scheduled_Bing_multiturn") {
      return dashboard_BingMultiturn;
    }
    return dashboard_BingAllJobs;
  };

  return (
    <>
      <div className={styles.infoTitle}>Monitor</div>
      <Button
        className={buttonStyles.buttonContainer}
        onClick={() => {
          window.open(getMonitorLink(), "_blank");
        }}
      >
        <span className={buttonStyles.buttonText}>Regular run dashboard</span>
      </Button>
    </>
  );
});
