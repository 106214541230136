export const CONSENT_VERSION = 4;
export const TENANT_ID = "72f988bf-86f1-41af-91ab-2d7cd011db47";
export const SEVAL_ARIA_TABLE_NAME = "seval";
export const BizChat_Official_Eval_Templates = [
  "BizChat_Evaluation_All_Metrics",
  "BizChat_Evaluation_LEO_Only",
  "BizChat_Shared",
  "BizChat_Evaluation_SuggestionChip",
  "BizChat_Evaluation_SuggestionChip_with_SydneyScraper",
];
export const BizChat_Official_Test_Tenant_Set_Paths = [
  "data/OfficialSets/1K_GoldenSet_TestTenant.tsv",
  "data/OfficialSets/Canary_Set_TestTenant.tsv",
  "data/OfficialSets/Core_Set_TestTenant.tsv",
  "data/OfficialSets/MchatmultiturnQuery_V0_Template.tsv",
] as const;

export const Default_QuerySet_Template = "1K_GoldenSet_MSIT_03-12-2025";

export const QuerySet_Templates = [
  Default_QuerySet_Template,
  "1K_GoldenSet_MSIT_12-30-2024",
  "core-set-msit-12-05",
  "2024-06-21-Reasoning-Mega",
  "2024-8-7_Rep_Queryset",
  "2024-8-7_Rep_Queryset_Email",
  "2024-8-7_Rep_Queryset_Calendar",
  "SC_Calibration_Set_9_14",
  "2024-07-17-Reasoning_CIQ",
  "1K_GoldenSet_MSIT_Multiturn",
  "MchatmultiturnQuery_V0_Template",
  "multiturnOnlyTemplate_V1",
  "SyntheticQuerySetV0",
  "Pending_Files500Set_1172024",
  "Search_GoldenSet_V0_Template",
  "intl_metric_pl",
  "MSIT-CopilotActions-Expansion-Set",
  "2025-03-14-MSIT-Voice-Expansion-Set",
  "Voice-Sanity-Template",
] as const;

export const productOptions = ["All", "BizChat", "CWC", "OPG"] as const;

export const lmChecklistFile = {
  personalAccount: "LMChecklist/MSIT/LMC_MSIT_flight_review.yml",
  fakeTenant: "LMChecklist/TestTenant/LMC_TestTenant.yml",
} as const;

export const scheduleJobStatusOptions = [
  "Active",
  "Canceled",
  "Completed",
] as const;

export const DeprecatedSydneyClientSecret =
  "resource=https://substrate.office.com/sydney&client_id=d3590ed6-52b3-4102-aeff-aad2292ab01c&grant_type=refresh_token&username={username}&refresh_token={search_refreshtoken}&scope=openid";
export const GeneralSydneyClientSecret =
  "resource=https://substrate.office.com/sydney&grant_type=refresh_token&username={username}&refresh_token={search_refreshtoken}&scope=openid";

export const defaultAzureStorageUrlPathPrefix =
  "azureml://datastores/sevaldatastore/paths/queryset/";

export const deprecatedUrlPathPrefixForBing =
  "azureml://datastores/sevaldatastore/paths";

export const bingAzureStorageUrlRoot =
  "azureml://datastores/workspaceblobstore/paths/";

export const BingQuerySetGenerationRAIMetrics = [
  "content_harms_multiturn",
  "jailbreak_multiturn",
  "ipcopyright_multiturn",
  "dea_multiturn",
];

export const dashboard_BingSingleturn =
  "https://msit.powerbi.com/groups/13ff7aa6-b0fa-4250-aa91-fb38b1688d9e/reports/d4b8a189-0167-4999-95ef-511250e64727?ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&pbi_source=linkShare&bookmarkGuid=db9a4b73-b0e4-487e-b887-c1bfd795d6ee";

export const dashboard_BingMultiturn =
  "https://msit.powerbi.com/groups/13ff7aa6-b0fa-4250-aa91-fb38b1688d9e/reports/d4b8a189-0167-4999-95ef-511250e64727?ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&pbi_source=linkShare&bookmarkGuid=96fd530a-4fd1-4594-9f0c-059ff799a9ca";

export const dashboard_BingAllJobs =
  "https://msit.powerbi.com/groups/13ff7aa6-b0fa-4250-aa91-fb38b1688d9e/reports/d4b8a189-0167-4999-95ef-511250e64727/ReportSection53cf671ad9933d8881bc?ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&experience=power-bi&bookmarkGuid=db9a4b73-b0e4-487e-b887-c1bfd795d6ee";

export const CWC_SkipScraping_AML_Storage_Prefix =
  "azureml://datastores/workspaceblobstore/paths/CWCSkipScraping/";

export const folderPath_bingJobConfig = "SevalPortal/jobs";
export const folderPath_CWCJobConfig = "SevalPortal/CWC/jobs";

export const folderPath_CWCQuerySet = "SevalPortal/CWC/queryset";

export const fileUrlPrefix_CWCQuerySet_new =
  bingAzureStorageUrlRoot + folderPath_CWCQuerySet;

export const folderPath_bingQueryGenerate = "SevalPortal/generation";

export const bingfile_DiagnosisFileName = "seval_sbs_metrics_score.json";

export const citeFileSuffix_baseline = "_conversations.tsv";
export const citeFileSuffix_treatment = "_treatment_conversations.tsv";

export const Exp_Workspace_Id = "f082eddd-3c30-4fa7-83e7-ae506a831a40";
export const harmfulRAISegment = "RAI - Content Harms";

export const JobStatusOptions = [
  "All",
  "Running",
  "Created",
  "Submitted",
  "Completed",
  "Failed",
  "SubmitFailed",
  "Canceled",
  "CancelRequested",
  "Deleted",
  "DatasetNotFound",
];
