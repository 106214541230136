import { action } from "satcheljs";

import type { SubstrateSingleUserInfor } from "@seval-portal/client-models";

export const openJobShareDialog = action("openJobShareDialog", () => ({}));

export const inviteUsersAction = action("inviteUsersAction", () => ({}));

export const batchInviteUsersAction = action(
  "batchInviteUsersAction",
  () => ({}),
);

export const updateSelectUsersAction = action(
  "updateSelectUsersAction",
  (users: SubstrateSingleUserInfor[]) => ({ users }),
);

export const removeUsersAction = action(
  "removeUsersAction",
  (userToRemove: string) => ({ userToRemove }),
);
