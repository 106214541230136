import { store } from "@seval-portal/client-store";
import { fileUrlPrefix_CWCQuerySet_new } from "@seval-portal/client-utils";
import { getBingUserSets } from "../../../helpers/apiHelper";

export const verifyCWCQuerySetFile = (file: File) => {
  const userId = store.account?.localAccountId;
  if (!userId) {
    throw new Error(`Please login first.`);
  }

  const regexStr = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
  const fileNameWithoutTsv = file.name.replace(".tsv", "");
  if (!regexStr.test(fileNameWithoutTsv)) {
    throw new Error(
      `Invalid file name: ${file.name}, file name should start with a letter or an underscore and contain only letters, numbers, and underscores!`,
    );
  }

  const fileName = `${userId}/${file.name}`;

  return getBingUserSets("CWC").then((userTestSet) => {
    if (userTestSet.map((_) => _.name).includes(fileName)) {
      throw new Error(`The file ${fileName} already exists.`);
    }

    return Promise.resolve(file);
  });
};

export const checkCWCTestSet = (file: File) => {
  return verifyCWCQuerySetFile(file);
};

export const getCWCUserSetsAuthor = (filePath: string) => {
  const filePathSplit = filePath
    .replace(fileUrlPrefix_CWCQuerySet_new + "/", "")
    .split("/");
  const guid = filePathSplit[0];

  if (store.account?.localAccountId === guid) {
    return store.account?.name ?? "You";
  }

  return "Others";
};
