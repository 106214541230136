import {
  Button,
  Combobox,
  Field,
  InfoLabel,
  Option,
  shorthands,
  tokens,
  typographyStyles,
} from "@fluentui/react-components";
import {
  BizChat_Official_Eval_Templates,
  BizChat_Official_Test_Tenant_Set_Paths,
} from "@seval-portal/client-utils";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { useToast } from "../../../../../components/Wrappers/ToasterProvider";
import {
  checkOnlineQuerySetContent,
  checkOnlineQuerySetWarning,
} from "../../../../../helpers/apiHelper";
import { updatePropValueActionV2 } from "../../../actions/jobActions";
import { updateBizchatQuerySetValidationResult } from "../../../mutators/jobMutators";
import QuerysetWhitelist from "../../../resources/querysetWhitelist.json";
import { getJobErrorByPath } from "../../../selectors/creationError/getJobCreationError";
import { getInputFile, getUserId } from "../../../selectors/getJobPropV2";
import { jobStore } from "../../../store/jobStore";
import { UploadingFileDialog } from "../../Dialog/UploadingFileDialog";
import { MockDeclarativeAgentsHint } from "./JobGPTAgentView";

const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    rowWithSmallGap: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      ...shorthands.gap("16px"),
    },
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    listbox: {
      maxHeight: "300px",
    },
    warning: typographyStyles.caption1Strong,
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

interface JobSelectedFileViewProps {
  customQuerySets: string[];
  setCustomQuerySets: (querySets: string[]) => void;
  refreshCustomQuerySets: () => void;
}

export const JobSelectedFileView = observer(
  (props: JobSelectedFileViewProps) => {
    const { customQuerySets, setCustomQuerySets } = props;

    const toast = useToast();
    const [isCheckingHeader, setIsCheckingHeader] = useState(false);
    const [isTestSetFileDialogOpen, setIsTestSetFileDialogOpen] =
      useState(false);
    const [boundaryRef, setBoundaryRef] = useState<HTMLDivElement | null>(null);
    const [onlineCheckWarning, setOnlineCheckWarning] = useState<
      string | undefined
    >(undefined);

    const styles = useStyles();

    const selectedTemplate = jobStore.selectedTemplate;
    if (selectedTemplate === undefined) {
      return <></>;
    }
    let querySets = customQuerySets.slice().sort();
    if (
      BizChat_Official_Eval_Templates.includes(selectedTemplate.Name) &&
      jobStore.selectedEvaluationType == "FakeTenant"
    ) {
      querySets = [...BizChat_Official_Test_Tenant_Set_Paths, ...querySets];
    }
    const testFileName = getInputFile.get() ?? "";

    const error = getJobErrorByPath("configs_in_json.conversations.input_file");

    const getValidationState = () => {
      if (isCheckingHeader) {
        return "warning";
      }

      if (error === undefined) {
        return "success";
      }

      return "error";
    };

    const getValidationMessage = () => {
      if (isCheckingHeader) {
        return "Checking header...";
      }

      if (error === undefined) {
        return "QuerySet Header Validation Success.";
      }

      return error.message;
    };

    const handleQuerySetUpdated = (value: string) => {
      if (value.startsWith("data/") || QuerysetWhitelist.includes(value)) {
        setIsCheckingHeader(false);
        return;
      }

      setIsCheckingHeader(true);
      setOnlineCheckWarning(undefined);

      checkOnlineQuerySetWarning({
        FileName: value,
        UserId: getUserId.get() ?? "Unknown",
      })
        .then((result) => {
          setOnlineCheckWarning(result);
        })
        .catch((_) => {
          setOnlineCheckWarning(
            "QuerySet Content Validation Failed: " + _.message,
          );
          props.refreshCustomQuerySets();
        });

      checkOnlineQuerySetContent({
        FileName: value,
        UserId: getUserId.get(),
        TemplateName: selectedTemplate.Name,
      })
        .then(() => {
          updateBizchatQuerySetValidationResult(value, {
            state: "success",
            message: undefined,
          });
        })
        .catch((_) => {
          updateBizchatQuerySetValidationResult(value, {
            state: "error",
            message: "QuerySet Content Validation Failed: " + _.message,
          });
          props.refreshCustomQuerySets();
        })
        .finally(() => {
          setIsCheckingHeader(false);
        });
    };

    const onFileUploadStart = () => {
      toast.onToastStart("Uploading file...");
    };

    const onFileUploadSuccess = () => {
      toast.onToastSuccess("File uploaded successfully");
    };

    const onFileUploadFailure = (e: Error) => {
      toast.onToastFailure(`File upload failed with message: ${e.message}`);
    };

    useEffect(() => {
      if (testFileName) {
        handleQuerySetUpdated(testFileName);
      }
    }, [testFileName]);

    return (
      <div ref={setBoundaryRef} className={styles.block}>
        <InfoLabel
          className={styles.blockTitle}
          required
          info={
            "Specify the file path of input file from SEVAL query set storage"
          }
        >
          Test set
        </InfoLabel>
        <div>
          For flight review, please run the following two query sets: 1K Golden
          Set (
          <a
            href="https://o365exchange.visualstudio.com/O365%20Core/_git/SydneyEvaluation?path=/Enterprise/data/OfficialSets/1K_GoldenSet_MSIT.tsv"
            rel="noreferrer"
            target="_blank"
          >
            link
          </a>
          ) against template [BizChat_Evaluation_Flight_Review_For_Avalon] and
          3P Scenarios Golden Set (
          <a
            href="https://o365exchange.visualstudio.com/O365%20Core/_git/SydneyEvaluation?path=/Enterprise/data/OfficialSets/3PScenarios_GoldenSet.tsv"
            rel="noreferrer"
            target="_blank"
          >
            link
          </a>
          ) against template
          [BizChat_Evaluation_Flight_Review_For_3P_Scenarios].
          <br />
          Please refer to this wiki (
          <a
            href="https://eng.ms/docs/experiences-devices/m365-core/microsoft-search-assistants-intelligence-msai/cortana-services-and-skills-compliant/compliant-sydney/m365-chat-platform-aka-compliant-sydney/bizchat/quality-evaluation/datasets/offline-evaluation-query-set/offline-eval-personalization-pipeline/use-personalized-query-set-generator-in-seval"
            rel="noreferrer"
            target="_blank"
          >
            link
          </a>
          ) to generate the personalized query set for the 1K set in the Query
          Set tab in SEVAL; 3P Scenarios Golden Set doesn't require any
          personalization.
        </div>

        <Field
          validationState={getValidationState()}
          validationMessage={getValidationMessage()}
        >
          <div className={styles.rowWithSmallGap}>
            <Combobox
              positioning={{
                overflowBoundary: boundaryRef,
              }}
              aria-label="Specify the file path of input file from SEVAL query set storage"
              style={{ flex: 1 }}
              listbox={{ className: styles.listbox }}
              value={testFileName}
              selectedOptions={[testFileName]}
              onOptionSelect={(_, data) => {
                if (data.optionValue !== undefined) {
                  updatePropValueActionV2({
                    prop: "configs_in_json.conversations.input_file",
                    newData: data.optionValue,
                  });
                }
              }}
            >
              {querySets.map((query, index) => {
                return <Option key={index}>{query}</Option>;
              })}
            </Combobox>
            <Button
              appearance="primary"
              onClick={setIsTestSetFileDialogOpen.bind(null, true)}
            >
              Upload File
            </Button>
          </div>
          {onlineCheckWarning && (
            <div className={styles.warning}>
              {onlineCheckWarning}
              <br />
              {MockDeclarativeAgentsHint}
            </div>
          )}
        </Field>
        <UploadingFileDialog
          type="Query"
          isOpen={isTestSetFileDialogOpen}
          onCancel={setIsTestSetFileDialogOpen.bind(null, false)}
          onStart={onFileUploadStart}
          onSuccess={(fileName) => {
            updatePropValueActionV2({
              prop: "configs_in_json.conversations.input_file",
              newData: fileName,
            });
            setCustomQuerySets([...customQuerySets, fileName]);
            setIsTestSetFileDialogOpen(false);
            onFileUploadSuccess();
          }}
          onFailure={(e) => {
            setIsTestSetFileDialogOpen(false);
            onFileUploadFailure(e);
          }}
        />
      </div>
    );
  },
);
