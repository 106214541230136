import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Badge,
  Button,
  Checkbox,
  Combobox,
  Dropdown,
  InfoLabel,
  makeStyles,
  Option,
  OptionGroup,
  shorthands,
  tokens,
} from "@fluentui/react-components";

import type { AccordionToggleEventHandler } from "@fluentui/react-components";
import { Delete16Regular } from "@fluentui/react-icons";
import { store } from "@seval-portal/client-store";
import {
  CWC_SkipScraping_AML_Storage_Prefix,
  fileUrlPrefix_CWCQuerySet_new,
  folderPath_CWCQuerySet,
} from "@seval-portal/client-utils";
import type { BingMetaDataResponse } from "@seval-portal/shared";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
import { useToast } from "../../../../../components/Wrappers/ToasterProvider";
import { getBingUserSets } from "../../../../../helpers/apiHelper";
import {
  deletePropValueActionV2,
  updatePropValueActionV2,
} from "../../../actions/jobActions";
import { getCWCUserSetsAuthor } from "../../../helpers/CWCSetHelper";
import { generateFormattedDateString } from "../../../helpers/formatHelper";
import { updateUserUploadedTestSet } from "../../../mutators/jobCreationMutators";
import {
  getBingJobRunMode,
  getBingJobTestSetsList,
} from "../../../selectors/getJobPropV2";
import { jobCreationStore } from "../../../store/jobCreationStore";
import { jobStore } from "../../../store/jobStore";
import { CWCUploadingFolderDialog } from "../../Dialog/CWCUploadingFolderDialog";
import { UploadingFileDialog } from "../../Dialog/UploadingFileDialog";

const useStyles = makeStyles({
  label: {
    color: "rgb(13, 102, 53)",
    backgroundColor: "rgb(202, 234, 216)",
    ...shorthands.padding("4px", "8px"),
    ...shorthands.borderRadius("12px"),
  },
  columnWithSmallerGap: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    ...shorthands.gap("8px"),
  },
  rowWithSmallGap: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    ...shorthands.gap("16px"),
    alignItems: "center",
  },
  block: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    width: "100%",
    ...shorthands.gap("8px"),
  },
  blockTitle: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  listbox: {
    maxHeight: "300px",
  },
  deleteButton: {
    marginLeft: "10px",
  },
  addButton: {
    minWidth: "20px",
  },
  testSetContainer: {
    flexWrap: "wrap",
    justifyContent: "flex-start",
    ...shorthands.gap("8px"),
    display: "flex",
    width: "100%",
  },
  testSet: {
    ...shorthands.borderRadius("8px"),
    ...shorthands.border("1px", "solid", "#EDEBE9"),
    ...shorthands.padding("10px", "12px"),
  },
  metricsContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    ...shorthands.gap("8px"),
  },
  checkboxContainer: {
    ...shorthands.gap("12px"),
    fontFamily: tokens.fontFamilyBase,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  checkbox: {
    accentColor: "#773CFC",
    maxWidth: "fit-content",
    minWidth: "45%",
    '> input[type="checkbox"]:checked': {
      backgroundColor: "red",
      color: "red",
    },
  },
  textBoxHeight: {
    height: "100px",
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("8px"),
  },
  dropDown: {
    width: "100%",
    overflowX: "auto",
  },
  errorName: {
    ...shorthands.padding("0px", "5px", "0px", "5px"),
    color: tokens.colorPaletteRedForeground1,
  },
});

interface IJobTestSetBlockProps {
  metadata: BingMetaDataResponse;
}

export const JobTestSetBlockView = observer((props: IJobTestSetBlockProps) => {
  const { metadata } = props;
  const styles = useStyles();
  const testLists = getBingJobTestSetsList.get() ?? {};

  const [isUploadOpen, setIsUploadOpen] = React.useState(false);
  const [openItems, setOpenItems] = React.useState([""]);

  const [uploadFolderPath, setUploadFolderPath] = React.useState<string>("");

  const defaultTestSetList = metadata.datasets;

  const singleTurnMetrics = metadata.metrics.singleturn;

  const multiTurnMetrics = metadata.metrics.multiturn;

  const [
    isSkipingScrapingUploadingFolderOpen,
    setIsSkipingScrapingUploadingFolderOpen,
  ] = React.useState(false);
  const runMode = getBingJobRunMode.get();
  const [boundaryRef, setBoundaryRef] = React.useState<HTMLDivElement | null>(
    null,
  );

  const refreshUserUploadedTestSet = () => {
    getBingUserSets("CWC").then((sets) => {
      const fileList = sets.map((_) => _.name);
      updateUserUploadedTestSet(fileList);
    });
  };

  const handleToggle: AccordionToggleEventHandler<string> = (event, data) => {
    setOpenItems(data.openItems);
  };

  const addMoreTest = () => {
    const newTest = {
      options: undefined,
      input_folder: undefined,
      metrics: undefined,
    };

    setOpenItems(["<Select new test set>"]);

    updatePropValueActionV2({
      prop: `${runMode}.<Select new test set>`,
      newData: newTest,
    });
  };

  const getCurrentValue = (testSet: string) => {
    return get(jobStore.configuration, `${runMode}.${testSet}`);
  };

  const updateTest = (oldSet: string, set: string, value: object) => {
    const currentSet = getCurrentValue(oldSet);
    deletePropValueActionV2(`${runMode}.${oldSet}`);

    const updatedItem: object = Object.assign({}, currentSet, value);

    setOpenItems([...openItems, set]);
    updatePropValueActionV2({
      prop: `${runMode}.${set}`,
      newData: updatedItem,
    });
  };

  const removeTest = (oldSet: string) => {
    deletePropValueActionV2(`${runMode}.${oldSet}`);
  };

  const renderMetricsBlock = (testSet: string) => {
    const checkedList = testLists[testSet];

    const metrics =
      runMode === "single_turn" ? singleTurnMetrics : multiTurnMetrics;

    if (metrics === undefined) {
      return <></>;
    }

    const rows = [];
    for (let i = 0; i < metrics.length; i++) {
      const key = metrics[i];
      const checkbox = (
        <Checkbox
          className={styles.checkbox}
          key={key}
          checked={
            checkedList.metrics === undefined
              ? false
              : checkedList.metrics.indexOf(key) >= 0
          }
          onChange={(event) => {
            const checked = event.target.checked;
            const currentValue = checkedList.metrics ?? [];
            if (checked) {
              const newValue = [...currentValue, key];
              updateTest(testSet, testSet, {
                ...checkedList,
                metrics: newValue,
              });
            } else {
              const otherChecked = currentValue.filter(
                (value: string) => value !== key,
              );
              updateTest(testSet, testSet, {
                ...checkedList,
                metrics: otherChecked,
              });
            }
          }}
          label={key}
        />
      );
      rows.push(checkbox);
    }

    return <div className={styles.metricsContainer}>{rows}</div>;
  };

  const onFolderUploadStart = () => {
    toast.onToastStart("Uploading folder...");
  };

  const onFolderUploadSuccess = () => {
    toast.onToastSuccess("Folder uploaded successfully");
  };

  const onFolderUploadFailure = (error: Error) => {
    toast.onToastFailure(`Folder upload failed with message: ${error.message}`);
  };

  const renderOptionInput = (testSet: string) => {
    const { [testSet]: checkedList } = testLists;
    const options = checkedList.options;
    const hasSkipScrap = options?.find((option) => option === "skip_scraping");
    return (
      <div ref={setBoundaryRef} className={styles.block}>
        <InfoLabel info={"Input valid options, seperate by ','"}>
          Options
        </InfoLabel>
        <Dropdown
          positioning={{
            overflowBoundary: boundaryRef,
          }}
          className={styles.dropDown}
          aria-label="Drop down for the option."
          multiselect={true}
          value={options?.join(",") ?? ""}
          selectedOptions={options ?? []}
          onOptionSelect={(_, data) => {
            if (data.selectedOptions.length === 0) {
              updateTest(testSet, testSet, {
                ...checkedList,
                options: undefined,
              });
            } else {
              updateTest(testSet, testSet, {
                ...checkedList,
                options: data.selectedOptions,
              });
            }
          }}
        >
          {metadata.options &&
            metadata.options.map((option) => (
              <Option key={option}>{option}</Option>
            ))}
        </Dropdown>
        {hasSkipScrap && (
          <div className={styles.rowWithSmallGap}>
            <Button
              onClick={() => {
                setIsSkipingScrapingUploadingFolderOpen(true);
              }}
            >
              Upload
            </Button>
            {checkedList.previous_scrape_output && (
              <div className={styles.label}>
                {checkedList.previous_scrape_output}
              </div>
            )}
            {checkedList.previous_scrape_output === undefined && (
              <div className={styles.errorName}>
                Please upload the folder first
              </div>
            )}
            <CWCUploadingFolderDialog
              folderName={
                store.account?.localAccountId +
                "/" +
                jobStore.jobName +
                "_" +
                testSet +
                generateFormattedDateString()
              }
              isOpen={isSkipingScrapingUploadingFolderOpen}
              onCancel={() => setIsSkipingScrapingUploadingFolderOpen(false)}
              onStart={onFolderUploadStart}
              onSuccess={(newName) => {
                setIsSkipingScrapingUploadingFolderOpen(false);
                onFolderUploadSuccess();
                const skipScrapingPrefix = CWC_SkipScraping_AML_Storage_Prefix;
                updateTest(testSet, testSet, {
                  ...checkedList,
                  previous_scrape_output: skipScrapingPrefix + newName,
                });
              }}
              onFailure={(e) => {
                setIsSkipingScrapingUploadingFolderOpen(false);
                onFolderUploadFailure(e);
              }}
            ></CWCUploadingFolderDialog>
          </div>
        )}
      </div>
    );
  };

  const getFileName = (option: string) => {
    const regex = /^(.+)\/([^/]+)$/;
    const match = option.match(regex);
    if (match) {
      const fileName = match[2].replaceAll(".tsv", "");
      return fileName;
    }
    return "";
  };

  const isInvalidName = (option: string) => {
    const regex = /^(.+)\/([^/]+)$/;
    const match = option.match(regex);
    if (match) {
      const fileName = match[2].replaceAll(".tsv", "");
      const regexStr = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
      if (!regexStr.test(fileName)) {
        return false;
      }
      return true;
    }
    return false;
  };

  const getDisplayFileName = (option: string) => {
    const fileName = getFileName(option);
    if (isInvalidName(option)) {
      return fileName;
    }
    return (
      fileName + "(Invalid file name, please upload with a valid name again)"
    );
  };

  const renderUserInfor = (url: string) => {
    return getCWCUserSetsAuthor(url);
  };

  const renderSets = (testSet: string, value: any, index: number) => {
    const currentTestSet = Object.keys(testLists);
    const { [testSet]: checkedList } = testLists;
    return (
      <AccordionItem key={index} value={testSet}>
        <AccordionHeader>
          {checkedList.data_uri && (
            <Badge className={styles.label}>
              {renderUserInfor(checkedList.data_uri)}
            </Badge>
          )}
          {testSet}
          <Delete16Regular
            aria-label="Delete current test set."
            className={styles.deleteButton}
            onClick={() => removeTest(testSet)}
          />
        </AccordionHeader>
        <AccordionPanel>
          <div className={styles.testSet}>
            <div className={styles.columnWithSmallerGap}>
              <div className={styles.block}>
                <InfoLabel info={"Select a test set from available list"}>
                  File Name
                </InfoLabel>
                <Combobox
                  key={testSet}
                  aria-label="The selected file name for the test set."
                  style={{ flex: 1 }}
                  listbox={{ className: styles.listbox }}
                  value={testSet}
                  onOptionSelect={(_, data) => {
                    const selectedOption = data.optionValue;
                    if (
                      selectedOption &&
                      selectedOption.indexOf(
                        store.account?.localAccountId ?? folderPath_CWCQuerySet,
                      ) >= 0
                    ) {
                      updateTest(testSet, getFileName(selectedOption), {
                        ...value,
                        data_uri: `${fileUrlPrefix_CWCQuerySet_new}/${data.optionValue}`,
                        input_folder: undefined,
                      });
                    } else {
                      const regex = /^(.+)\/([^/]+)$/;
                      const match = selectedOption?.match(regex);
                      if (match) {
                        const path = match[1];
                        const fileName = match[2];
                        updateTest(testSet, fileName.replace(".tsv", ""), {
                          ...value,
                          input_folder: "./" + path,
                          data_uri: undefined,
                        });
                      }
                    }
                  }}
                >
                  <OptionGroup label={store.account?.username}>
                    {jobCreationStore.userUploadedSets.map((query) => {
                      return (
                        <Option
                          key={query}
                          disabled={
                            currentTestSet.indexOf(getFileName(query)) >= 0 ||
                            !isInvalidName(query)
                          }
                          value={query}
                        >
                          {getDisplayFileName(query)}
                        </Option>
                      );
                    })}
                  </OptionGroup>
                  <OptionGroup label="Shared">
                    {defaultTestSetList &&
                      defaultTestSetList.map((query: string) => {
                        return (
                          <Option
                            key={getFileName(query)}
                            disabled={
                              currentTestSet.indexOf(getFileName(query)) >= 0
                            }
                            value={query}
                          >
                            {query}
                          </Option>
                        );
                      })}
                  </OptionGroup>
                </Combobox>
              </div>
              <div className={styles.block}>
                <InfoLabel
                  info={"Specify the metrics to be evaluated in this test set"}
                >
                  Metrics
                </InfoLabel>
                {renderMetricsBlock(testSet)}
                {renderOptionInput(testSet)}
              </div>
            </div>
          </div>
        </AccordionPanel>
      </AccordionItem>
    );
  };

  const onCloseUploadDialog = () => {
    setIsUploadOpen(false);
  };

  const onOpenUploadDialog = () => {
    if (!store.account?.localAccountId) {
      toast.onToastFailure("Please login first");
      return;
    }
    setUploadFolderPath(store.account?.localAccountId);
    setIsUploadOpen(true);
  };

  const toast = useToast();

  const renderUploadBlock = () => {
    return (
      <div>
        <Button appearance="primary" onClick={onOpenUploadDialog}>
          Upload Test Set
        </Button>
        <UploadingFileDialog
          subFolder={uploadFolderPath}
          type={"CWC"}
          isOpen={isUploadOpen}
          onCancel={() => {
            onCloseUploadDialog();
            setIsUploadOpen(false);
          }}
          onStart={() => {
            onCloseUploadDialog();
            toast.onToastStart("Uploading file...");
          }}
          onSuccess={() => {
            toast.onToastSuccess("File uploaded successfully");
            refreshUserUploadedTestSet();
          }}
          onFailure={(error) => {
            toast.onToastFailure(
              `File upload failed with message: ${error.message}`,
            );
          }}
        />
      </div>
    );
  };

  return (
    <div className={styles.block}>
      <InfoLabel className={styles.blockTitle} info={"Sort alphabetically"}>
        Test Set
      </InfoLabel>
      <div>
        <Accordion
          key="bing_testset"
          openItems={openItems}
          onToggle={handleToggle}
          collapsible
          multiple
        >
          {Object.entries(
            Object.fromEntries(
              Object.entries(testLists).sort((a, b) => {
                return a[0].localeCompare(b[0]);
              }),
            ),
          ).map((entry, index) => {
            return renderSets(entry[0], entry[1], index);
          })}
        </Accordion>
      </div>
      <div className={styles.buttonBox}>
        <Button appearance="primary" onClick={addMoreTest}>
          Add Test Set
        </Button>
        {renderUploadBlock()}
      </div>
    </div>
  );
});
