import type { QuerySetFile } from "@seval-portal/client-models";

export enum QuerySetTableFilter {
  all = "All",
  shared = "Shared",
  myQuerySets = "MyQuerySets",
  myBingQuerySets = "MyBingQuerySets",
}

export type QuerySetDataSource =
  | "public"
  | "bizchat"
  | "bizchatGeneration"
  | "bing"
  | "bingGeneration";

export type QuerySetData = {
  source: QuerySetDataSource;
  file: QuerySetFile;
};
