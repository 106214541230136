export type EvaluationType = "FakeTenant" | "PersonalAccount";
export const AvailableRefreshTokenTypes = [
  "3S refresh token",
  "Teams CLI refresh token",
  "Graph refresh token",
] as const;
export const UploadableRefreshTokenTypes = [
  "Teams CLI refresh token",
  "Graph refresh token",
];
export const FullRefreshTokenTypes = [
  ...AvailableRefreshTokenTypes,
  "SEVAL refresh token",
  "Scraping service refresh token",
] as const;
export type RefreshTokenType = (typeof FullRefreshTokenTypes)[number];
export const AvailableFakeTenantEvaluationTypes = [
  "Teams CLI refresh token",
  "password",
] as const;
export const SEVALAvailableFakeTenantEvaluationTypes = [
  "Teams CLI refresh token",
  "password",
] as const;
export type FakeTenantUploadTokenType =
  | (typeof AvailableFakeTenantEvaluationTypes)[number]
  | (typeof SEVALAvailableFakeTenantEvaluationTypes)[number];
export type UploadTokenType = FakeTenantUploadTokenType | RefreshTokenType;
type EditorRuleIgnore = {
  type: "ignore";
  path: string;
};

type EditorRuleFile = {
  type: "file";
  path: string;
  options: string[];
  selectedOption: string;
};

type EditorRuleDisable = {
  type: "disable";
  path: string;
};

export type EditorRule = EditorRuleIgnore | EditorRuleFile | EditorRuleDisable;
