import type { FieldProps } from "@fluentui/react-components";
import {
  Body1Strong,
  Button,
  DialogActions,
  Field,
  Spinner,
  Text,
  Textarea,
  makeStyles,
  useToastController,
} from "@fluentui/react-components";
import { Lightbulb16Regular, Open20Regular } from "@fluentui/react-icons";
import type { Job } from "@seval-portal/client-models";
import { Exp_Workspace_Id } from "@seval-portal/client-utils";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import {
  getNewExperimentParams,
  getNexusUrlByJobSettings,
} from "../../../components/Shared/helpers/ShadowABExperimentHelper";
import { createExperiment } from "../../../helpers/apiHelper";
import { getUserAlias } from "../../../partners/ShadowAB/utils/account";
import { ShadowABExperimentSetupSuccessToast } from "./ShadowABExperimentSetupSuccessToast";

const useStyle = makeStyles({
  tips: {
    color: "#616161",
    fontSize: "12px",
    margin: "8px 0 16px",
  },
  nexusContainer: {
    marginTop: "24px",
    marginBottom: "8px",
    backgroundColor: "#EBF3FC",
    border: "1px solid #B4D6FA",
    borderRadius: "8px",
    padding: "12px",
  },
  nexusTitle: {
    display: "flex",
    alignItems: "center",
    color: "#242424",
  },
  nexusContent: {
    margin: "8px 4px",
    color: "#242424",
  },
  action: {
    marginTop: "16px",
  },
  longButton: {
    whiteSpace: "nowrap",
  },
});
export const SetupNewExperimentByJob = observer(
  (props: {
    toasterId: string;
    onDismiss: () => void;
    onErrorMessage: (msg: string) => void;
    upstreamJob: Job;
  }) => {
    const { toasterId, onDismiss, onErrorMessage, upstreamJob } = props;
    const { dispatchToast } = useToastController(toasterId);
    const styles = useStyle();
    const myAlias = getUserAlias();
    const [treatmentVariable, setTreatmentVariable] = React.useState("");
    const [
      treatmentVariableValidationState,
      setTreatmentVariableValidationState,
    ] = React.useState<FieldProps["validationState"]>("none");
    const [experimentCreating, setExperimentCreating] = React.useState(false);

    const handleSetupExperiment = () => {
      if (!treatmentVariable) {
        setTreatmentVariableValidationState("error");
      } else {
        const newExperimentId = uuidv4();
        const newExperimentParams = getNewExperimentParams(
          upstreamJob,
          newExperimentId,
          myAlias,
          treatmentVariable,
        );
        setExperimentCreating(true);
        createExperiment(newExperimentParams)
          .then(() => {
            setExperimentCreating(false);
            const newExperimentUrl = `https://exp.microsoft.com/a/feature/${newExperimentId}?workspaceId=${Exp_Workspace_Id}`;
            window.open(newExperimentUrl, "_blank");
            dispatchToast(
              <ShadowABExperimentSetupSuccessToast
                toastId={"setupExperimentSuccess"}
                toasterId={toasterId}
                newExperimentUrl={newExperimentUrl}
              />,
              {
                intent: "success",
                timeout: -1,
                toastId: "setupExperimentSuccess",
              },
            );
            onDismiss();
          })
          .catch((e) => {
            setExperimentCreating(false);
            onErrorMessage(e.message);
          });
      }
    };
    const handleNexus = () => {
      const url = getNexusUrlByJobSettings(upstreamJob);
      window.open(url, "_blank");
    };
    return (
      <>
        <Field className={styles.tips}>
          <Text>
            You'll be guided to the Control Tower to complete the experiment
            setup.
          </Text>
        </Field>

        {upstreamJob && (
          <>
            <div className={styles.nexusContainer}>
              <Body1Strong className={styles.nexusTitle} block={true}>
                <Lightbulb16Regular />
                Important
              </Body1Strong>
              <Text className={styles.nexusContent} block={true}>
                Set up your experiment on Control Tower by using the config from
                Nexus Hub as your treatment variable value. Simply go to Nexus
                Hub, grab the config, and paste it in Control Tower.
              </Text>
              <Button
                size="small"
                data-testid="nexus-link"
                className={styles.longButton}
                onClick={handleNexus}
                icon={<Open20Regular />}
              >
                Generate config on Nexus
              </Button>
            </div>
            <Field
              label="Experiment configurations"
              required={true}
              validationState={treatmentVariableValidationState}
            >
              <Textarea
                value={treatmentVariable}
                placeholder="Paste config from Nexus"
                onChange={(_e, data) => {
                  setTreatmentVariable(data.value);
                  if (data.value) {
                    setTreatmentVariableValidationState("none");
                  }
                }}
              />
            </Field>
            <DialogActions className={styles.action}>
              <Button
                data-testid="exp-link"
                className={styles.longButton}
                onClick={handleSetupExperiment}
                appearance="primary"
                disabled={!treatmentVariable || experimentCreating}
                icon={experimentCreating ? undefined : <Open20Regular />}
              >
                {experimentCreating ? (
                  <Spinner size="tiny" />
                ) : (
                  `Go to Control Tower and start`
                )}
              </Button>
              <Button onClick={() => onDismiss()} appearance="secondary">
                Cancel
              </Button>
            </DialogActions>
          </>
        )}
      </>
    );
  },
);
