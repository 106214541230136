import {
  Button,
  Link,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { store } from "@seval-portal/client-store";
import { observer } from "mobx-react-lite";
import React from "react";
import { updateSelectedContributionTab } from "../../mutators/updateContributions";
import { contributionStore } from "../../store/contributionStore";
import { makeResponsiveStyles } from "../Responsive/makeResponsiveStyles";

const useStyles = makeResponsiveStyles(
  {
    root: {
      boxSizing: "border-box",
      width: "100%",
      height: "52px",
      paddingLeft: "80px",
      paddingRight: "80px",
      display: "flex",
      flexDirection: "row",
      flexShrink: 0,
      justifyContent: "space-between",
      backgroundColor: tokens.colorNeutralBackground1,
      ...shorthands.borderBottom("1px", "solid", tokens.colorNeutralStroke1),
    },
    tab: {
      height: "52px",
      backgroundColor: "transparent",
      position: "relative",
      ...shorthands.border("none"),
      ":hover": {
        backgroundColor: "transparent",
      },
    },
    activeTab: {
      "::after": {
        content: "''",
        position: "absolute",
        bottom: "0px",
        left: "0px",
        right: "0px",
        width: "100%",
        height: "3px",
        backgroundColor: tokens.colorBrandBackground,
        transform: "matrix(1, 0, 0, 1, 0, 0)",
        transformOrigin: "0px 1.5px",
      },
    },
    inactiveTab: {
      ":hover:after": {
        content: "''",
        position: "absolute",
        bottom: "0px",
        left: "0px",
        width: "100%",
        height: "3px",
        backgroundColor: tokens.colorNeutralStroke1Hover,
        transform: "matrix(1, 0, 0, 1, 0, 0)",
        transformOrigin: "0px 1.5px",
      },
    },
    tabsContainer: {
      flexShrink: 0,
    },
    linksContainer: {
      color: "white",
      flexShrink: 0,
      display: "flex",
      flexDirection: "row",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      boxSizing: "border-box",
      width: "max-content",
      ...shorthands.gap("5px"),
    },
    linkText: {
      fontSize: "12px",
      fontWeight: 400,
      color: tokens.colorNeutralForeground1,
    },
    linkSeparator: {
      fontSize: "12px",
      fontWeight: 400,
      color: tokens.colorNeutralForeground1,
    },
  },
  {
    xs: {
      root: {
        paddingLeft: "0",
        paddingRight: "0",
      },
    },
    sm: {
      root: {
        paddingLeft: "0",
        paddingRight: "0",
      },
    },
    md: {
      root: {
        paddingLeft: "80px",
        paddingRight: "80px",
      },
    },
  },
);

export const AppSubTab = observer(() => {
  const styles = useStyles();
  const selectedContribution = contributionStore.selectedContribution;

  if (store.permission?.isUser !== true || selectedContribution === undefined) {
    return <></>;
  }

  const getStyle = (name: string) => {
    return contributionStore.selectedContributionSubTab?.name === name
      ? mergeClasses(styles.tab, styles.activeTab)
      : mergeClasses(styles.tab, styles.inactiveTab);
  };

  switch (selectedContribution.type) {
    case "single-page":
      return <></>;

    case "sub-tab":
      return (
        <div className={styles.root}>
          <div className={styles.tabsContainer}>
            {selectedContribution.subTabs.map((subTab) => {
              if (subTab.isEnabled !== undefined && !subTab.isEnabled()) {
                return <React.Fragment key={subTab.name} />;
              }
              return (
                <Button
                  shape="square"
                  className={getStyle(subTab.name)}
                  aria-label={subTab.name}
                  icon={subTab.icon()}
                  value={subTab.name}
                  key={subTab.name}
                  onClick={() => {
                    updateSelectedContributionTab(selectedContribution, subTab);
                  }}
                >
                  {subTab.name}
                </Button>
              );
            })}
          </div>
          <div className={styles.linksContainer}>
            {selectedContribution.subTabLinks?.map((subTabLink, index) => {
              return (
                <React.Fragment key={subTabLink.name}>
                  {index !== 0 && (
                    <span className={styles.linkSeparator}>·</span>
                  )}
                  <Link
                    key={index}
                    className={styles.linkText}
                    target="_blank"
                    href={subTabLink.link}
                  >
                    {subTabLink.name}
                  </Link>
                  {subTabLink.additionalView && subTabLink.additionalView()}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      );
  }
});
