import { setFeatures, setFeaturesAction } from "@seval-portal/client-store";
import { loginRequest, telemetryHelper } from "@seval-portal/client-utils";
import { orchestrator } from "satcheljs";
import { loginAction } from "../actions/loginAction";
import { logoutAction } from "../actions/logoutAction";

orchestrator(setFeaturesAction, ({ features }) => {
  setFeatures(features);
});

orchestrator(loginAction, ({ instance, actionType }) => {
  switch (actionType) {
    case "popup":
      instance.loginPopup({ ...loginRequest }).catch(() => undefined);
      break;

    case "redirect":
      instance.loginRedirect({ ...loginRequest }).catch(() => undefined);
      break;
  }
});

orchestrator(logoutAction, ({ instance, actionType }) => {
  telemetryHelper.logUserActionEvent("Logout", { actionType });

  switch (actionType) {
    case "popup":
      instance
        .logoutPopup({
          postLogoutRedirectUri: `/logout`,
          mainWindowRedirectUri: `/logout`,
        })
        .catch((e) => {
          telemetryHelper.logErrorEvent("UserLogoutError", {
            actionType,
            message: e.message,
          });
        });
      break;

    case "redirect":
      instance
        .logoutRedirect({
          postLogoutRedirectUri: `/logout`,
        })
        .catch((e) => {
          telemetryHelper.logErrorEvent("UserLogoutError", {
            actionType,
            message: e.message,
          });
        });
      break;
  }
});
