import { Button } from "@fluentui/react-button";
import { CopyAddRegular } from "@fluentui/react-icons";
import { isFeatureEnabled } from "@seval-portal/client-store";
import { observer } from "mobx-react-lite";
import { updateCurrentPath } from "../../../../../../mutators/updateContributions";
import { groupDetailStore } from "../../../../store/groupDetailStore";

export const DuplicateGroupButton = observer(() => {
  const isJobGroupStage2 = isFeatureEnabled("job-group-stage-2");

  if (!isJobGroupStage2) {
    return <></>;
  }

  const onDuplicateGroup = () => {
    updateCurrentPath(`/groupCreate?copyGroupId=${groupDetailStore.group?.ID}`);
  };
  return (
    <div>
      <Button icon={<CopyAddRegular />} onClick={onDuplicateGroup}>
        Duplicate
      </Button>
    </div>
  );
});
