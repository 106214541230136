import type { FeatureContribution } from "@seval-portal/client-models";
import { observer } from "mobx-react-lite";
import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { registeredContributions } from "../../contribution/register";
import { updateCurrentPath } from "../../mutators/updateContributions";
import { contributionStore } from "../../store/contributionStore";

export const RoutesProvider = observer(() => {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (contributionStore.currentPathState === undefined) {
      navigate(contributionStore.currentPath);
    } else {
      const state: unknown = JSON.parse(
        JSON.stringify(contributionStore.currentPathState),
      );
      navigate(contributionStore.currentPath, {
        state,
      });
    }
  }, [contributionStore.currentPath]);

  React.useEffect(() => {
    // Support browser operation like go back and go forward
    updateCurrentPath(`${location.pathname}${location.search}`);
  }, [location.pathname]);

  const title =
    contributionStore.selectedContributionPathRegistry?.title?.() ??
    "SEVAL Pro";
  document.title = title;

  const getPathRegistryPairs = (contribution: FeatureContribution) => {
    switch (contribution.type) {
      case "single-page":
        return Object.entries(contribution.pathRegistry);
      case "sub-tab":
        return contribution.subTabs.flatMap((_) =>
          Object.entries(_.pathRegistry),
        );
    }
  };

  return (
    <Routes>
      {registeredContributions
        .filter((contribution) => contribution.isEnabled())
        .flatMap((contribution) =>
          getPathRegistryPairs(contribution).map(([path, pathRegistry]) => (
            <Route key={path} path={path} element={pathRegistry.render()} />
          )),
        )}
      <Route
        path="*"
        element={<div>{`Invalid Path ${location.pathname}`}</div>}
      />
    </Routes>
  );
});
