import { Job } from "@seval-portal/client-models";
import { orchestrator } from "satcheljs";
import { findGroup, getGroupTemplates } from "../../../helpers/apiHelper";
import {
  loadGroupDetailAction,
  refreshGroupJobListAction,
  switchSelectedJobAction,
} from "../actions/groupDetailActions";
import {
  updateCurrentJob,
  updateDetailGroup,
  updateGroupJobs,
  updateGroupTemplate,
  updateIsLoadingGroup,
  updateIsLoadingJobs,
} from "../mutators/groupDetailMutators";
import { restJobDetail } from "../mutators/jobDetailsMutators";
import {
  resetResultStore,
  updateResultJob,
} from "../mutators/jobResultMutators";
import { initJobDetailPage } from "../selectors/jobDetailHelper";
import { groupDetailStore } from "../store/groupDetailStore";
import { initJobDetailStore } from "../store/jobDetailStore";
import { jobListStore } from "../store/jobListStore";
import { initJobResultStore } from "../store/resultStore";

orchestrator(loadGroupDetailAction, ({ groupId }) => {
  // if group infor is known
  getGroupTemplates().then((data) => {
    updateGroupTemplate(data);
  });
  const knownGroup = jobListStore.groups.find((group) => group.ID === groupId);
  if (knownGroup) {
    updateDetailGroup(knownGroup);
    const knownJobs = knownGroup.Jobs?.map((job) => Job(job, "GroupJob"));
    updateGroupJobs(knownJobs ?? []);
    const firstJob = knownJobs?.[0];

    switchSelectedJobAction(firstJob);
    updateIsLoadingGroup(false);
    return;
  } else {
    findGroup({ GroupId: groupId })
      .then((data) => {
        if (data.length > 0) {
          updateDetailGroup(data[0]);
          const knownJobs = data[0].Jobs?.map((job) => Job(job, "GroupJob"));
          updateGroupJobs(knownJobs ?? []);
          const firstJob = knownJobs?.[0];

          switchSelectedJobAction(firstJob);
          return;
        }
      })
      .finally(() => updateIsLoadingGroup(false));
  }
  //fetch group info
});

orchestrator(refreshGroupJobListAction, () => {
  const groupId = groupDetailStore.group?.ID;
  if (groupId === undefined) {
    updateIsLoadingGroup(false);
    return;
  }

  updateIsLoadingGroup(true);
  findGroup({ GroupId: groupId })
    .then((data) => {
      if (data.length > 0) {
        const knownJobs = data[0].Jobs?.map((job) => Job(job, "GroupJob"));
        updateGroupJobs(knownJobs ?? []);
        const firstJob = knownJobs?.[0];
        switchSelectedJobAction(firstJob);
        return;
      }
    })
    .finally(() => updateIsLoadingGroup(false));
});

orchestrator(switchSelectedJobAction, ({ job }) => {
  resetResultStore(initJobResultStore);
  restJobDetail(initJobDetailStore);
  updateCurrentJob(job);
  if (job === undefined) {
    updateIsLoadingJobs(false);
    return;
  }
  if (job?.ID) {
    updateIsLoadingJobs(true);
    updateResultJob(job);
    if (job.HasMetrics) {
      initJobDetailPage(job?.ID).finally(() => {
        updateIsLoadingJobs(false);
      });
    } else {
      updateIsLoadingJobs(false);
    }
  } else {
    updateIsLoadingJobs(false);
  }
});
