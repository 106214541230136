import { useMsal } from "@azure/msal-react";
import {
  Avatar,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Title3,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import {
  SignOutFilled,
  SignOutRegular,
  bundleIcon,
} from "@fluentui/react-icons";
import { store } from "@seval-portal/client-store";
import { getGraphAccessToken } from "@seval-portal/client-utils";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { logoutAction } from "../../actions/logoutAction";
import { getUserAvatarUrl } from "../../helpers/graphHelper";

export type TokenType = "3s" | "fake-tenant-3s";

const LogoutIcon = bundleIcon(SignOutFilled, SignOutRegular);

const useStyles = makeStyles({
  name: {
    color: "white",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
    textAlign: "end",
    ...shorthands.padding("10px"),
  },
});

export const AppBarUserAvatar = observer(() => {
  const { instance } = useMsal();
  const [imageUrl, setImageUrl] = React.useState<string | undefined>(undefined);

  const styles = useStyles();

  const handleLogoutClick = () => {
    logoutAction(instance, "redirect");
  };

  useEffect(() => {
    if (!store.account) {
      return;
    }

    getGraphAccessToken().then((accessToken) => {
      const username = store.account?.username;

      if (username === undefined) {
        return;
      }

      getUserAvatarUrl(username, accessToken).then((url) => {
        setImageUrl(url);
      });
    });
  }, [store.account]);

  const renderMenuList = () => {
    if (store.account === undefined) {
      return <></>;
    } else {
      return (
        <MenuPopover>
          <MenuList>
            <MenuItem onClick={handleLogoutClick} icon={<LogoutIcon />}>
              Logout
            </MenuItem>
          </MenuList>
        </MenuPopover>
      );
    }
  };

  return (
    <>
      <Title3 className={styles.name}>{store.account?.name}</Title3>
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <Avatar
            color="colorful"
            activeAppearance="shadow"
            active="active"
            name={store.account?.name}
            image={{
              src: imageUrl,
            }}
            size={28}
          />
        </MenuTrigger>
        {renderMenuList()}
      </Menu>
    </>
  );
});
