import {
  Body1,
  makeStyles,
  shorthands,
  type TextPresetProps,
} from "@fluentui/react-components";
import type { Job } from "@seval-portal/client-models";
import { observer } from "mobx-react";
import { Tip } from "../../../../../components/Shared/Tip";
import { formatCreateTime } from "../../../helpers/formatHelper";

type JobCreateDateCellProps = {
  job: Job;
};

const useStyles = makeStyles({
  body: {
    whiteSpace: "nowrap",
    ...shorthands.overflow("hidden"),
    textOverflow: "ellipsis",
  },
});

export const JobCreateDateCell = observer((props: JobCreateDateCellProps) => {
  const { job } = props;
  const styles = useStyles();

  const Body = (bodyProps: TextPresetProps) => (
    <Body1 {...bodyProps} className={styles.body} />
  );

  if (job.CreatedAt === undefined) {
    return <></>;
  }
  const formattedDateTime = formatCreateTime(job.CreatedAt);
  return (
    <Body>
      <Tip content={`${formattedDateTime}`} relationship="label">
        <div>{`${formattedDateTime}`}</div>
      </Tip>
    </Body>
  );
});
