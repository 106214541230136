import type {
  Job,
  SubstrateSingleUserInfor,
} from "@seval-portal/client-models";
import type { UserProfileResult } from "@seval-portal/shared";
import { mutatorAction } from "satcheljs";
import { jobShareStore } from "../store/jobShareStore";

export const updateUserProfile = mutatorAction(
  "updateUserProfile",
  (userProfile: UserProfileResult) => {
    jobShareStore.userProfile = userProfile;
  },
);

export const updateShareIsLoading = mutatorAction(
  "updateShareIsLoading",
  (isLoading: boolean) => {
    jobShareStore.isLoading = isLoading;
  },
);

export const setIsOpenShareDialog = mutatorAction(
  "setIsOpenShareDialog",
  (isOpen: boolean) => {
    if (isOpen) {
      jobShareStore.isGroupSharedNotificationShow = false;
    }
    jobShareStore.isDialogOpen = isOpen;
    if (!isOpen) {
      jobShareStore.isCopyLink = false;
    }
  },
);

export const updateIsCopyShareLink = mutatorAction(
  "updateIsCopyShareLink",
  (isCopy: boolean) => {
    jobShareStore.isCopyLink = isCopy;
  },
);

export const updateIsGroupSharedNotificationShow = mutatorAction(
  "updateIsGroupSharedNotificationShow",
  (isShared: boolean) => {
    jobShareStore.isGroupSharedNotificationShow = isShared;
  },
);

export const updateSelectedUser = mutatorAction(
  "updateSelectedUser",
  (users: SubstrateSingleUserInfor[]) => {
    jobShareStore.selectUser = users;
  },
);

export const updateJobPermission = mutatorAction(
  "updateJobPermission",
  (permission: SubstrateSingleUserInfor[]) => {
    jobShareStore.permissionList = permission;
  },
);
export const removeUserFromJobPermission = mutatorAction(
  "removeUserFromJobPermission",
  (userId: string) => {
    const oldPermission = jobShareStore.permissionList;
    jobShareStore.permissionList = oldPermission.filter(
      (item) => item.ObjectId !== userId,
    );
  },
);

export const updateSevalUserList = mutatorAction(
  "updateSevalUserList",
  (users: SubstrateSingleUserInfor[]) => {
    jobShareStore.sevalUserList = users;
  },
);

export const updateJobShareInvalidErrorMessage = mutatorAction(
  "updateJobShareInvalidErrorMessage",
  (message: string | undefined) => {
    jobShareStore.invalidErrorMessage = message;
  },
);

export const updateCurrentShareJob = mutatorAction(
  "updateCurrentShareJob",
  (value: Job | undefined) => {
    jobShareStore.currentJob = value;
  },
);
