import type {
  VirtualTenant,
  VirtualTenantWithVirtualUserIds,
} from "@seval-portal/client-models";
import { isFeatureEnabled, store } from "@seval-portal/client-store";
import { checkSyntheticTenantPatternValidation } from "@seval-portal/shared";
import { getSyntheticEphemeralAccount } from "../../../helpers/apiHelper";
import { updateUserIdToUPN } from "../mutators/updateCaches";
import { jobStore } from "../store/jobStore";
import { generateFormattedDateStringForSyntheticUser } from "./formatHelper";

export const getSysthenticIngestionDataFolderName = () =>
  "useruploads/" +
  (store.account?.username ?? "UnknownUser") +
  "/" +
  generateFormattedDateStringForSyntheticUser();

export const isSyntheticUserEnabled = () => {
  const selectedTemplateName = jobStore.selectedTemplate?.Name?.toLowerCase();
  const validTemplates = [
    "bizchat_evaluation_all_metrics_for_avalon_parallel_for_test_tenant",
    "bizchat_evaluation_all_metrics_for_gpt_for_test_tenant",
    "bizchat_evaluation_all_metrics_for_test_tenant",
    "bizchat_evaluation_all_metrics_multi_turn_for_test_tenant",
    "bizchat_evaluation_flight_review_for_3p_scenarios_for_test_tenant",
    "bizchat_shared_leo_only_for_test_tenant",
  ];
  return (
    isFeatureEnabled("select-synthetic-tenant-test-user") &&
    validTemplates.includes(selectedTemplateName ?? "")
  );
};

export const getSyntheticEphemeralAccountFromCache = async (userId: string) => {
  if (jobStore.caches.userIdToUPN.has(userId)) {
    return jobStore.caches.userIdToUPN.get(userId);
  }
  const response = await getSyntheticEphemeralAccount(userId);
  if (response && response.length > 0) {
    updateUserIdToUPN(userId, response);
    return response;
  }
  return undefined;
};

export const convertVirtualTenantToVirtualTenantWithVirtualUserIds = (
  virtualTenant: VirtualTenant,
): VirtualTenantWithVirtualUserIds => {
  const randomPyhsicalTenant =
    virtualTenant.physicalTenants[
      Math.floor(Math.random() * virtualTenant.physicalTenants.length)
    ];
  const virtualAccounts = randomPyhsicalTenant.virtualAccounts.flatMap((va) => {
    const randomPhysicalAccount =
      va.physicalAccounts[
        Math.floor(Math.random() * va.physicalAccounts.length)
      ];
    return {
      userId:
        randomPhysicalAccount.userName + "@" + randomPyhsicalTenant.tenantName,
      userName: va.userName,
      userTags: va.userTags,
    };
  });
  return {
    tenantId: virtualTenant.tenantId,
    tenantName: randomPyhsicalTenant.tenantName,
    tenantTags: virtualTenant.tenantTags,
    createdBy: virtualTenant.createdBy,
    ring: virtualTenant.ring,
    virtualAccounts,
  };
};

export const getTargetAccount = async (userId: string | undefined) => {
  if (
    userId &&
    checkSyntheticTenantPatternValidation(userId) &&
    isSyntheticUserEnabled()
  ) {
    return await getSyntheticEphemeralAccountFromCache(userId);
  }
  return userId;
};
