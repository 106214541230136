import {
  cleanFeatureFlagOverrides,
  setFeatureFlagOverride,
} from "@seval-portal/client-utils";
import { orchestrator } from "satcheljs";
import {
  resetFeatureFlagOverridesAction,
  setFeatureFlagOverrideAction,
} from "../actions/featureFlagActions";
import {
  resetFeatureFlagOverrides,
  updateIsFeatureEnabled,
} from "../mutators/updateFeatures";

orchestrator(setFeatureFlagOverrideAction, ({ featureFlag, isEnabled }) => {
  setFeatureFlagOverride(featureFlag, isEnabled);
  updateIsFeatureEnabled(featureFlag, isEnabled);
});

orchestrator(resetFeatureFlagOverridesAction, () => {
  cleanFeatureFlagOverrides();
  resetFeatureFlagOverrides();
});
