import {
  Field,
  Switch,
  Textarea,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { Warning16Regular } from "@fluentui/react-icons";
import { telemetryHelper } from "@seval-portal/client-utils";
import { observer } from "mobx-react-lite";
import React from "react";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { updateJsonStringAction } from "../../../actions/jobActions";
import { updateConfiguration } from "../../../mutators/jobMutators";
import { getJSONConfigError } from "../../../selectors/creationError/getJSONConfigError";
import { getJSONSchemaError } from "../../../selectors/creationError/getJSONSchemaError";
import { getJobConfigFromJSON } from "../../../selectors/getJobConfigFromJSON";
import { jobStore } from "../../../store/jobStore";

const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    field: {
      "& .fui-Field__validationMessage": {
        maxWidth: "100%",
        ...shorthands.overflow("hidden"),
        wordBreak: "break-word",
      },
    },
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    jsonPreviewer: {
      height: "600px",
      "> span": {
        ...shorthands.flex(1),
      },
      "> textarea": {
        maxHeight: "inherit",
      },
    },
    warningBox: {
      color: tokens.colorStatusWarningBorderActive,
    },
    warningIcon: {
      verticalAlign: "middle",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

export const JobJsonConfigurationView = observer(() => {
  const styles = useStyles();

  const [isJSONEditorEnabled, setIsJSONEditorEnabled] = React.useState(false);

  const errorMessage = getJSONConfigError.get() ?? getJSONSchemaError.get();
  const validationState = errorMessage === undefined ? "none" : "error";

  const jobConfig = getJobConfigFromJSON.get();

  React.useEffect(() => {
    if (jobConfig !== undefined) {
      updateConfiguration(jobConfig);
    }
  }, [jobConfig]);

  return (
    <div className={styles.block}>
      <div className={styles.blockTitle}>JSON Configuration</div>
      <div>
        <Switch
          checked={isJSONEditorEnabled}
          label={"Turn on JSON Editor"}
          onChange={(ev) => {
            setIsJSONEditorEnabled(ev.currentTarget.checked);
            telemetryHelper.logUserActionEvent("EnableJsonEditor");
          }}
        ></Switch>
        {isJSONEditorEnabled && (
          <div className={styles.warningBox}>
            <Warning16Regular className={styles.warningIcon} />
            Highly recommended to use template editor to update the
            configuration. It ensures that the configuration always matches the
            latest template version. You might encounter failures if modifying
            the JSON configuration wrongly.
          </div>
        )}
      </div>
      <Field
        className={styles.field}
        validationState={validationState}
        validationMessage={
          errorMessage?.split(";").map((error) => (
            <div key={"jobConfigError_" + error}>
              {error} <br />
            </div>
          )) ?? ""
        }
      >
        <Textarea
          aria-label="Template configuration editor."
          defaultValue={jobStore.configurationJsonString}
          className={styles.jsonPreviewer}
          value={jobStore.configurationJsonString}
          onChange={(_, data) => {
            updateJsonStringAction(data.value);
          }}
          disabled={!isJSONEditorEnabled}
        />
      </Field>
    </div>
  );
});
