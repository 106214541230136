import type { TagPickerProps } from "@fluentui/react-components";
import {
  Field,
  Input,
  Link,
  Tag,
  TagPicker,
  TagPickerControl,
  TagPickerGroup,
  TagPickerInput,
  TagPickerList,
  TagPickerOption,
} from "@fluentui/react-components";
import type { Job } from "@seval-portal/client-models";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { ResponsiveRow } from "../../../../components/Responsive/ResponsiveRow";
import { InforIconTip } from "../../../../components/Shared/Tip";
import { isSchedulerManager } from "../../../../selectors/userPermission";
import {
  get3MonthLaterDate,
  getCurrentTimeZone,
} from "../../helpers/formatHelper";
import {
  updateErrorMessage,
  updateSelectedScheduleJob,
} from "../../mutators/scheduleJobMutators";
import {
  getIntervalsError,
  getReferedJob,
  getScheduleJobNameError,
  getStartTimeError,
} from "../../selectors/scheduleJobSelector";
import { scheduleJobStore } from "../../store/scheduleJobStore";
import { DateTimeInput } from "./components/DateTimeInput";
import { JsonView } from "./components/JsonView";
import {
  emailListTip,
  intervalTip,
  maxInterval,
  minInterval,
  minIntervalForSchedulerManager,
  referJobTip,
  schedulerNameTip,
  startTimeTip,
} from "./constants";
import { sharedStyles } from "./sharedStyles";

export const ScheduleJobEditView = observer(() => {
  const styles = sharedStyles();
  const { selectedScheduledJob, pageMode, recentCompletedJobs } =
    scheduleJobStore;
  const [selectedJob, setSelectedJob] = React.useState<Job | undefined>(
    undefined,
  );

  useEffect(() => {
    const referJob = getReferedJob.get();
    referJob.then((job) => {
      if (job && job[0]) {
        setSelectedJob(job[0]);
        updateErrorMessage(undefined);
      }
    });
  }, []);
  if (!selectedScheduledJob) {
    return <></>;
  }

  const onOptionSelect: TagPickerProps["onOptionSelect"] = (e, data) => {
    const selectedOption = recentCompletedJobs.find(
      (job) => job.ID === Number(data.value),
    );
    if (selectedJob && selectedJob.ID === Number(data.value)) {
      setSelectedJob(undefined);
      updateSelectedScheduleJob({
        ...selectedScheduledJob,
        Settings: "",
        TemplateName: "",
        Message: "",
      });

      updateErrorMessage("Schedule Job should be based on a completed job.");
    } else if (selectedOption) {
      setSelectedJob(selectedOption);
      updateSelectedScheduleJob({
        ...selectedScheduledJob,
        Settings: selectedOption.Settings ?? "",
        TemplateName: selectedOption.ExperimentName,
        Message: JSON.stringify({
          referJobId: selectedOption.ID,
        }),
      });

      updateErrorMessage(undefined);
    }
  };

  const referJobOptions = () => {
    if (recentCompletedJobs.length > 0) {
      return recentCompletedJobs.map((job) => job.ID.toString());
    }
    return ["No Option"];
  };
  return (
    <div>
      <div className={styles.section}>
        <div className={styles.block}>
          <div className={styles.blockSectionTitle}>Basic Settings</div>
          <div>
            Search from your own completed jobs for setting up the scheduler.
          </div>
          <Field
            required
            validationMessage={
              selectedJob ? scheduleJobStore.errorMessage : undefined
            }
            validationState={
              selectedJob && scheduleJobStore.errorMessage ? "error" : undefined
            }
            label={
              <span>
                Load From Completed Job
                <InforIconTip content={referJobTip} />
              </span>
            }
          >
            <TagPicker
              onOptionSelect={onOptionSelect}
              selectedOptions={selectedJob ? [selectedJob.ID.toString()] : []}
            >
              <TagPickerControl>
                {selectedJob && (
                  <TagPickerGroup aria-label="Selected Reference Job">
                    <Tag
                      key={selectedJob.ID}
                      shape="rounded"
                      value={`${selectedJob.ID}`}
                    >
                      [{selectedJob.ID}] {selectedJob.JobName}
                    </Tag>
                  </TagPickerGroup>
                )}
                <TagPickerInput
                  data-testid="refer-job-id-input"
                  aria-label="Select Reference Job"
                />
              </TagPickerControl>
              <TagPickerList>
                {referJobOptions().map((jobId: string) => (
                  <TagPickerOption key={jobId} value={`${jobId}`}>
                    {jobId}{" "}
                    {recentCompletedJobs.find((job) => job.ID === Number(jobId))
                      ?.JobName ?? ""}
                  </TagPickerOption>
                ))}
              </TagPickerList>
            </TagPicker>
          </Field>
        </div>

        <div className={styles.block}>
          <div className={styles.blockSectionTitle}>Scheduler Settings</div>
          {pageMode === "Create" && (
            <Field
              required
              validationMessage={getScheduleJobNameError.get()}
              validationState={
                getScheduleJobNameError.get() ? "error" : undefined
              }
              label={
                <span>
                  Scheduler Name
                  <InforIconTip content={schedulerNameTip} />
                </span>
              }
            >
              <Input
                data-testid="job-name-input"
                size="medium"
                placeholder="Enter Job name"
                value={selectedScheduledJob.Name}
                onChange={(_, data) => {
                  updateSelectedScheduleJob({
                    ...selectedScheduledJob,
                    Name: data?.value as string,
                  });
                }}
              />
            </Field>
          )}
          <ResponsiveRow
            maxColumnCount={3}
            maxColumnCountSmall={1}
            columnGap={8}
          >
            <div>
              Start Time: {getCurrentTimeZone()}
              <InforIconTip content={startTimeTip} />
            </div>
            <div className={styles.endtimeLabel}>
              <div>End Time: {getCurrentTimeZone()}</div>
              <Link
                onClick={() => {
                  if (selectedScheduledJob.EndTime) {
                    updateSelectedScheduleJob({
                      ...selectedScheduledJob,
                      EndTime: undefined,
                    });
                  } else {
                    updateSelectedScheduleJob({
                      ...selectedScheduledJob,
                      EndTime: get3MonthLaterDate(
                        selectedScheduledJob.StartTime,
                      ),
                    });
                  }
                }}
              >
                {selectedScheduledJob.EndTime ? "No end time" : "Set end time"}
              </Link>
            </div>
            <div>
              Interval In Hours
              <InforIconTip content={intervalTip} />
            </div>
            <Field
              required
              validationMessage={getStartTimeError.get()}
              validationState={getStartTimeError.get() ? "error" : undefined}
            >
              <DateTimeInput
                isoDateTime={selectedScheduledJob.StartTime}
                onChange={(dateTime: string) => {
                  updateSelectedScheduleJob({
                    ...selectedScheduledJob,
                    StartTime: dateTime,
                  });
                }}
              />
            </Field>
            <Field>
              <DateTimeInput
                isDisabled={selectedScheduledJob.EndTime === undefined}
                isoDateTime={selectedScheduledJob.EndTime ?? ""}
                onChange={(dateTime: string) => {
                  updateSelectedScheduleJob({
                    ...selectedScheduledJob,
                    EndTime: dateTime,
                  });
                }}
              />
            </Field>

            <Field
              validationMessage={getIntervalsError.get()}
              validationState={getIntervalsError.get() ? "error" : undefined}
            >
              <Input
                size="medium"
                type="number"
                min={
                  isSchedulerManager.get()
                    ? minIntervalForSchedulerManager
                    : minInterval
                }
                max={maxInterval}
                value={selectedScheduledJob.IntervalInHours.toString()}
                onChange={(_, data) => {
                  updateSelectedScheduleJob({
                    ...selectedScheduledJob,
                    IntervalInHours: Number(data?.value),
                  });
                }}
              />
            </Field>
          </ResponsiveRow>

          <Field
            label={
              <span>
                Email Subscription List
                <InforIconTip content={emailListTip} />
              </span>
            }
          >
            <Input
              size="medium"
              placeholder="Enter Email Subscription List"
              value={selectedScheduledJob.EmailSubscriptionList ?? ""}
              onChange={(_, data) => {
                updateSelectedScheduleJob({
                  ...selectedScheduledJob,
                  EmailSubscriptionList: data?.value,
                });
              }}
            />
          </Field>
        </div>
        <div className={styles.block}>
          <div className={styles.blockSectionTitle}>
            Job template and configuration
          </div>
          <div>
            Template and JSON configuration will be loaded automatically after
            selecting a job.
          </div>
          <Field label={<span>Template Name</span>}>
            <Input
              size="medium"
              disabled
              placeholder="Select Template"
              value={selectedScheduledJob.TemplateName ?? ""}
            />
          </Field>

          <Field label={<span>Settings</span>}>
            <JsonView content={selectedScheduledJob.Settings} />
          </Field>
        </div>
      </div>
    </div>
  );
});
