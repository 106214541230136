import { store } from "@seval-portal/client-store";
import { observer } from "mobx-react-lite";
import { useToast } from "../../../../../components/Wrappers/ToasterProvider";
import type { FeatureSettingsDialogContributionProps } from "../../../../../contribution/FeatureSettingsContribution";
import { ingestSyntheticDataAction } from "../../../actions/ingestSyntheticDataAction";
import { getSysthenticIngestionDataFolderName } from "../../../helpers/jobSyntheticUserHelper";
import { UploadingSyntheticIngestionDataFolderDialog } from "../../Dialog/UploadingSyntheticIngestionDataFolderDialog";

export const JobUploadSyntheticIngestionDataFolderDialog = observer(
  (props: FeatureSettingsDialogContributionProps) => {
    const toast = useToast();

    const onFolderUploadStart = () => {
      toast.onToastStart("Uploading folder...");
    };

    const onFolderUploadSuccess = (folderName: string) => {
      toast.onToastStart(
        "Folder uploaded successfully, start data ingestion...",
      );

      const onSyntheticDataIngestionSuccess = (clientTrackingId: string) => {
        toast.onToastSuccess(
          `Data ingestion job launched successfully, files are uploaded to folder: useruploads/${folderName}, clientTrackingId: ${clientTrackingId}`,
        );
      };

      const onSyntheticDataIngestionFailure = (error: Error) => {
        toast.onToastFailure(
          `Data ingestion failed with message: ${error.message}`,
        );
      };

      ingestSyntheticDataAction(
        "useruploads",
        folderName,
        store.account?.username ?? "UnknownUser",
        onSyntheticDataIngestionSuccess,
        onSyntheticDataIngestionFailure,
      );
    };

    const onFolderUploadFailure = (error: Error) => {
      toast.onToastFailure(
        `Folder upload failed with message: ${error.message}`,
      );
    };

    return (
      <UploadingSyntheticIngestionDataFolderDialog
        folderName={getSysthenticIngestionDataFolderName()}
        isOpen={props.isOpen}
        onCancel={() => props.close()}
        onStart={onFolderUploadStart}
        onSuccess={(folderName) => {
          props.close();
          onFolderUploadSuccess(folderName);
        }}
        onFailure={(e) => {
          props.close();
          onFolderUploadFailure(e);
        }}
      />
    );
  },
);
