import { Button } from "@fluentui/react-components";
import React, { useEffect } from "react";
import { createJob } from "../../../../helpers/apiHelper";
import {
  updateStatusIsSubmitting,
  updateStatusIsTokenDialogOpen,
  updateStatusIsVerifying,
} from "../../mutators/jobCreationMutators";

import { isFeatureEnabled } from "@seval-portal/client-store";
import { telemetryHelper } from "@seval-portal/client-utils";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import { useToast } from "../../../../components/Wrappers/ToasterProvider";
import { updateCurrentPath } from "../../../../mutators/updateContributions";
import { contributionStore } from "../../../../store/contributionStore";
import {
  initCreationPageStore,
  updateInfoAndSendNotification,
} from "../../helpers/creationDataHelper";
import {
  confirmSBSValidator,
  isJobCreationEditDisabled,
  priorityJobValidator,
  servicePrevalidation,
  tokenPopupValidator,
} from "../../helpers/jobCreationHelper";
import { getJobCreationErrorMessage } from "../../selectors/creationError/getJobCreationError";
import { generateCurrentJob } from "../../selectors/generateCurrentJob";
import { jobCreationStore } from "../../store/jobCreationStore";
import { jobPriorityStore } from "../../store/jobPriorityStore";
import { jobStore } from "../../store/jobStore";
import { TokenUploadingDialog } from "../Dialog/TokenUploadingDialog";
import { ShareButton } from "../JobDetail/components/ShareButton";
import { JobSBSConfirmationDialog } from "./components/JobSBSConfirmationDialog";
import { useCreationStyles } from "./creationStyles";
import { JobCreatePageRender } from "./TemplatesRenders/JobCreatePageRender";
import { MainRender } from "./TemplatesRenders/MainRender";

export const JobCreationViewContent = observer(() => {
  const styles = useCreationStyles();
  const toast = useToast();
  const state: unknown = useLocation().state;

  const submitJob = async () => {
    toast.onToastProgressUpdate("Job Creation Verified, Creating Job...");
    updateStatusIsSubmitting(true);

    telemetryHelper.logUserActionEvent("SubmitJob");

    try {
      const job = await generateCurrentJob();
      createJob(job)
        .then(
          (result) => {
            telemetryHelper.logUserActionEvent("SubmitJobSuccess", {
              data: result,
            });
            toast.onToastSuccess("Create Job Succeeded");
            updateCurrentPath("/");
            if (
              isFeatureEnabled("job-priority") &&
              jobPriorityStore.isPriorityJob &&
              jobPriorityStore.managerInfo
            ) {
              updateInfoAndSendNotification(
                result.InsertedID,
                job.JobName,
                jobPriorityStore.jobPriorityDescription || "",
                jobPriorityStore.managerInfo.LeaderName,
                jobPriorityStore.managerInfo.LeaderAddress,
              );
            }
          },
          (error) => {
            telemetryHelper.logUserActionEvent("SubmitJobFailure", {
              message: error.message,
            });
            toast.onToastFailure(`Create Job Failed: ${error.message}`);
          },
        )
        .finally(() => updateStatusIsSubmitting(false));
    } catch (error) {
      if (error instanceof Error) {
        telemetryHelper.logUserActionEvent("SubmitJobFailure", {
          message: error.message,
        });
        toast.onToastFailure(`Create Job Failed: ${error.message}`);
      }
    }
  };

  const onSubmitClicked = React.useCallback(() => {
    if (jobCreationStore.isSubmitting || jobCreationStore.isVerifying) {
      return;
    }

    toast.onToastStart("Verifying Job Creation...");
    updateStatusIsVerifying(true);

    if (jobStore.selectedTemplate === undefined) {
      toast.onToastFailure("Please select a template");
      updateStatusIsVerifying(false);
      return;
    }

    const errorMessage = getJobCreationErrorMessage.get();
    if (errorMessage !== undefined) {
      toast.onToastFailure(errorMessage);
      updateStatusIsVerifying(false);
      return;
    }

    Promise.resolve()
      .then(() => confirmSBSValidator())
      .then(() => priorityJobValidator())
      .then(() => tokenPopupValidator())
      .then(() => servicePrevalidation())
      .then(() => {
        submitJob();
      })
      .catch((error) => {
        toast.onToastFailure(error.toString());
      })
      .finally(() => {
        updateStatusIsVerifying(false);
      });
  }, []);

  useEffect(() => {
    initCreationPageStore(state);
  }, []);

  const renderShareSubmitButton = () => {
    return (
      <div className={styles.operationButton}>
        <Button
          disabled={isJobCreationEditDisabled.get()}
          appearance={"primary"}
          onClick={onSubmitClicked}
        >
          Submit
        </Button>
        <ShareButton />
      </div>
    );
  };

  const isGroupScenario = contributionStore.currentPath.includes("group");
  const renderHeader = () => {
    if (isGroupScenario) {
      return <></>;
    }

    return (
      <div>
        <div>
          <div className={styles.headerTitleContainer}>
            <span className={styles.headerTitle}>Create a new evaluation</span>
          </div>
        </div>
        <div>{!jobCreationStore.isLoading && renderShareSubmitButton()}</div>
      </div>
    );
  };

  const renderTokenDialog = () => {
    return (
      <TokenUploadingDialog
        isOpen={jobCreationStore.isTokenDialogOpen}
        email={jobCreationStore.tokenDialogEmail}
        onSuccess={() => {
          updateStatusIsTokenDialogOpen(false);
          if (isGroupScenario) {
            return;
          }

          servicePrevalidation()
            ?.then(() => {
              submitJob();
            })
            .catch((error) => {
              toast.onToastFailure(error.toString());
            });
        }}
        onCancel={updateStatusIsTokenDialogOpen.bind(null, false)}
        shouldUploadSevalToken={jobCreationStore.sevalTokenVerifyFailed}
        shouldUploadSssToken={jobCreationStore.substrateTokenVerifyFailed}
        shouldUploadTeamsCliToken={jobCreationStore.teamsCliTokenVerifyFailed}
        canSkipTokenUploading={jobCreationStore.canSkipTokenUploading}
        tokenInitDialogType={jobCreationStore.tokenInitDialogType}
      />
    );
  };

  const createPageRender = () => {
    if (isFeatureEnabled("use-partner-render")) {
      return <JobCreatePageRender partnerName="example" />;
    } else {
      return <MainRender />;
    }
  };

  return (
    <>
      {renderHeader()}
      {createPageRender()}
      {renderTokenDialog()}
      <JobSBSConfirmationDialog />
    </>
  );
});
