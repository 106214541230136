import type { TagPickerProps } from "@fluentui/react-components";
import {
  Avatar,
  Field,
  Spinner,
  Tag,
  TagPicker,
  TagPickerControl,
  TagPickerGroup,
  TagPickerInput,
  TagPickerList,
  TagPickerOption,
} from "@fluentui/react-components";
import type { SubstrateSingleUserInfor } from "@seval-portal/client-models";
import { getRandomUUID, getSubstrateToken } from "@seval-portal/client-utils";
import { uniqByObject } from "@seval-portal/shared";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { getSGMembers } from "../../../../../helpers/apiHelper";
import {
  updateJobShareInvalidErrorMessage,
  updateSelectedUser,
} from "../../../mutators/jobShareMutators";
import { jobShareStore } from "../../../store/jobShareStore";

export const SevalUserSelector = observer(() => {
  const noOption = "We couldn't find any matches";
  const asSecurityGroup = "as security group?";
  const [query, setQuery] = React.useState<string>("");
  const [selectedOptions, setSelectedOptions] = React.useState<string[]>([]);
  const userList = jobShareStore.sevalUserList;
  const [availableOptions, setAvailableOptions] = React.useState<string[]>([]);
  const [isLoadingSG, setIsLoadingSG] = React.useState<boolean>(false);

  const onOptionSelect: TagPickerProps["onOptionSelect"] = async (e, data) => {
    if (data.value === noOption) {
      return;
    }
    if (data.value === asSecurityGroup) {
      setIsLoadingSG(true);
      const sgAlias = query.split("@")[0];
      const SGMembers = await getSubstrateToken()
        .then((_) => {
          return getSGMembers({
            SubstrateToken: _,
            CallerFileNameLine: "ShareButton",
            ClientRequestId: getRandomUUID(),
            ProcessName: "SEVAL",
            EmailAlias: sgAlias,
          });
        })
        .then((response) =>
          response.userlist.find(
            (user) => user.RecipientType === "MailUniversalSecurityGroup",
          ),
        )
        .catch(() => undefined);

      if (SGMembers) {
        updateSelectedUser([...jobShareStore.selectUser, SGMembers]);
        setSelectedOptions([...selectedOptions, sgAlias]);
      } else {
        setSelectedOptions([...selectedOptions, `${sgAlias}<Not Found!>`]);
      }

      setIsLoadingSG(false);
    } else {
      setSelectedOptions(data.selectedOptions);
      const selectedUser = userList.filter((user) =>
        data.selectedOptions.includes(convertDisplayText(user)),
      );
      updateSelectedUser(selectedUser);
    }
    setQuery("");
    setAvailableOptions([]);
  };

  const convertDisplayText = (user: SubstrateSingleUserInfor) => {
    return `${user.DisplayName}<${user.PrimarySmtpAddress}>`;
  };

  useEffect(() => {
    const hasInvalidUser =
      selectedOptions.length > 0 &&
      selectedOptions.some((option) => option.includes("<Not Found!>"));
    if (hasInvalidUser) {
      updateJobShareInvalidErrorMessage(
        "Some users are not found in the system. Please remove them before sharing.",
      );
    } else {
      updateJobShareInvalidErrorMessage(undefined);
    }
  }, [selectedOptions]);

  const convertUserAliasToUser = (alias: string[]) => {
    return alias.map((a) => {
      const user = userList.find(
        (u) =>
          u.PrimarySmtpAddress &&
          u.PrimarySmtpAddress.split("@")[0] === a.split("@")[0] &&
          a.trim().length > 0,
      );
      return user ? convertDisplayText(user) : `${a}<Not Found!>`;
    });
  };

  const getFilteredOption = (userInput: string) => {
    if (userInput.indexOf(",") > 0 || userInput.indexOf(";") > 0) {
      const inputArray =
        userInput.indexOf(",") > 0
          ? userInput.split(",")
          : userInput.split(";");
      const selected = convertUserAliasToUser(inputArray);
      const uniqueSelected = uniqByObject([...selectedOptions, ...selected]);
      setSelectedOptions(uniqueSelected);
      const selectedUser = userList.filter((user) =>
        selected.includes(convertDisplayText(user)),
      );
      updateSelectedUser(selectedUser);
      setQuery("");
      return [];
    } else {
      return userList
        .filter(
          (user) =>
            !selectedOptions.includes(convertDisplayText(user)) &&
            convertDisplayText(user)
              .toLowerCase()
              .includes(userInput.toLowerCase()),
        )
        .map((user) => convertDisplayText(user));
    }
  };

  const getOptionList = (userInput: string) => {
    if (userInput === "") {
      setAvailableOptions([]);
      return;
    }
    const filterOptions = getFilteredOption(userInput);

    if (filterOptions.length === 0) {
      setAvailableOptions([noOption, asSecurityGroup]);
    }
    setAvailableOptions([...filterOptions.slice(0, 3), asSecurityGroup]);
  };

  return (
    <Field>
      <TagPicker
        onOptionSelect={onOptionSelect}
        selectedOptions={selectedOptions}
        disabled={isLoadingSG}
      >
        <TagPickerControl
          secondaryAction={isLoadingSG ? <Spinner size="extra-tiny" /> : <></>}
        >
          <TagPickerGroup>
            {selectedOptions.map((option) => (
              <Tag
                key={option}
                shape="rounded"
                value={option}
                media={<Avatar aria-hidden name={option} color="colorful" />}
              >
                {option}
              </Tag>
            ))}
          </TagPickerGroup>
          <TagPickerInput
            aria-label="Select user"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
              getOptionList(e.target.value);
            }}
          />
        </TagPickerControl>

        <TagPickerList>
          {availableOptions.map((option) => (
            <TagPickerOption
              key={option}
              value={option}
              media={
                <Avatar
                  shape="square"
                  aria-hidden
                  name={option}
                  color="colorful"
                />
              }
            >
              {option}
            </TagPickerOption>
          ))}
        </TagPickerList>
      </TagPicker>
    </Field>
  );
});
