import { Dropdown, mergeClasses, Option } from "@fluentui/react-components";
import type { Job } from "@seval-portal/client-models";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import { switchSelectedJobAction } from "../../../actions/groupDetailActions";
import { groupDetailStore } from "../../../store/groupDetailStore";
import { useGroupDetailPageStyles } from "../sharedStyles";

export const JobResultHeader = observer(() => {
  const styles = useGroupDetailPageStyles();
  const { pathname } = useLocation();
  const currentJob = groupDetailStore.currentJob;

  if (!pathname.startsWith("/group/")) {
    return <></>;
  }

  const jobOption = (job: Job | undefined) => `[${job?.ID}] ${job?.JobName}`;
  return (
    <div className={mergeClasses(styles.jobHeader, styles.alignItems)}>
      <div className={styles.infoTitle}>Job</div>
      <Dropdown
        data-testid="display-job-list-dropdown"
        aria-label="Job List Dropdown"
        value={jobOption(currentJob) ?? ""}
        defaultSelectedOptions={[jobOption(currentJob) ?? "Unknown"]}
        onOptionSelect={(_, data) => {
          const selectedJob = groupDetailStore.jobs.find(
            (item) => jobOption(item) === data.optionText,
          );
          switchSelectedJobAction(selectedJob ?? undefined);
        }}
      >
        {groupDetailStore.jobs.map((job) => (
          <Option key={job.ID}>{jobOption(job)}</Option>
        ))}
      </Dropdown>
    </div>
  );
});
