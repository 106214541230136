import type { Job } from "@seval-portal/client-models";
import { Exp_Workspace_Id } from "@seval-portal/client-utils";
import type { ExperimentRequestData } from "@seval-portal/shared";
import {
  JobConfiguration,
  parseJsonStrWithDefault,
} from "@seval-portal/shared";

export const getNexusUrlByJobSettings = (job?: Job) => {
  // Nexus staging url
  // const baseUrl = `https://nexushub-staging2-fqh3a9fca6b6cgek.westus-01.azurewebsites.net/experiment-definition-generator`;
  // Nexus prod url
  const baseUrl = `https://nexushub-fehtaae0h0ewa0e2.westus-01.azurewebsites.net/experiment-definition-generator`;
  if (!job) {
    return baseUrl;
  }
  try {
    const jobSettingObject = parseJsonStrWithDefault(
      job.Settings,
      JobConfiguration,
      {},
    );
    const controlFlights =
      jobSettingObject?.configs_in_json?.conversations?.exp_configs?.find(
        (v) => v.exp_name === "control",
      )?.sydney?.variants;
    const treatmentFlight =
      jobSettingObject?.configs_in_json?.conversations?.exp_configs?.find(
        (v) => v.exp_name === "experiment",
      )?.sydney?.variants;
    const nexusParameters = {
      treatment_id: `ShadowAB_Treatment_DoubleForkControl_${
        job.JobName?.replace(/[^0-9|a-z|A-Z|.|\-|_]/g, "") ?? ""
      }`,
      control_id: `ShadowAB_Treatment_DoubleForkTreatment_${
        job.JobName?.replace(/[^0-9|a-z|A-Z|.|\-|_]/g, "") ?? ""
      }`,
      control_flights: controlFlights ?? "",
      treatment_flights: treatmentFlight ?? "",
    };
    return `${baseUrl}?${new URLSearchParams(nexusParameters).toString()}`;
  } catch (_e) {
    return baseUrl;
  }
};

export const getNewExperimentParams = (
  job: Job,
  id: string,
  owner: string,
  treatmentVariable: string,
): ExperimentRequestData => {
  const templateType =
    job.JobTemplateType?.replace(/[^0-9|a-z|A-Z|.|\-|_]/g, "") ?? "";
  const randomId = Math.random().toString(36).substring(8);
  const flightPrefix = `${templateType}_${job.ID}_${randomId}`;
  const treatmentVariableKey = `ExperimentDefinitions_${flightPrefix}_c`;
  return {
    workspaceId: Exp_Workspace_Id,
    featureId: id,
    "api-version": "apis2",
    payload: {
      Id: id,
      ManagementGroup: "/msaiexp/bizchatshadow",
      Name: job.JobName ?? "",
      Description: job.JobName ?? "",
      AssignmentUnit: null,
      Owners: [owner],
      isolationGroupIds: ["EchoIsolationGroup"],
      Flights: [
        {
          Description: `${flightPrefix}_t`.toLowerCase(),
          Name: `${flightPrefix}_t`.toLowerCase(),
          FlightType: "Treatment",
          IsolationGroups: ["EchoIsolationGroup"],
        },
        {
          Description: `${flightPrefix}_c`.toLowerCase(),
          Name: `${flightPrefix}_c`.toLowerCase(),
          FlightType: "CustomControl",
          IsolationGroups: ["EchoIsolationGroup"],
          TreatmentVariables: [
            {
              Key: treatmentVariableKey,
              Namespace: "BizchatServiceShadow",
              Type: "string",
              value: treatmentVariable,
            },
          ],
        },
      ],
      WorkspaceId: Exp_Workspace_Id,
    },
  };
};
