import type { SearchUserSuccessResponse } from "@seval-portal/client-models";
import { mutatorAction } from "satcheljs";
import { substrateStore } from "../store/substrateStore";

export const updateCachedUserSuccess = mutatorAction(
  "updateCachedUserSuccess",
  (id: string, user: SearchUserSuccessResponse) => {
    substrateStore.userCache[id] = {
      status: "success",
      data: user,
    };
  },
);

export const updateCachedUserLoading = mutatorAction(
  "updateCachedUserLoading",
  (id: string) => {
    substrateStore.userCache[id] = {
      status: "loading",
    };
  },
);

export const cleanCachedUser = mutatorAction(
  "cleanCachedUser",
  (id: string) => {
    delete substrateStore.userCache[id];
  },
);
