import { Button, Field, Input, Textarea } from "@fluentui/react-components";
import React, { useEffect } from "react";
import {
  getMetricsChangeLog,
  setMetricsChangeLog,
} from "../../../../helpers/apiHelper";

import { store } from "@seval-portal/client-store";
import { BasicDialog } from "../../../../components/Shared/BasicDialog";
import { useToast } from "../../../../components/Wrappers/ToasterProvider";
import type { FeatureDebugDialogContributionProps } from "../../../../contribution/FeatureDebugContribution";
import type { MetricsChangeLog } from "../../models/MetricsChangeLog";

export const MetricsChangeDialog = (
  props: FeatureDebugDialogContributionProps,
) => {
  const [title, setTitle] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [metricsLog, setMetricsLog] = React.useState<
    MetricsChangeLog[] | undefined
  >([]);
  const toaster = useToast();

  useEffect(() => {
    setTitle("");
    setDescription("");
  }, [props.isOpen]);

  React.useEffect(() => {
    getMetricsChangeLog().then((res) => {
      setMetricsLog(res);
    });
  }, []);

  const renderContent = () => (
    <>
      <Field
        required
        label={"Metrics Change Title"}
        validationMessage={
          title.trim().length === 0
            ? "Change title cannot be empty."
            : undefined
        }
      >
        <Input
          style={{ width: "100%" }}
          size="medium"
          value={title}
          onChange={(_, data) => {
            setTitle(data.value);
          }}
        />
      </Field>
      <Field
        required
        label={"Metrics Change Description"}
        validationMessage={
          description.trim().length === 0
            ? "Metrics Change description cannot be empty."
            : undefined
        }
      >
        <Textarea
          style={{ width: "100%" }}
          size="medium"
          value={description}
          onChange={(_, data) => {
            setDescription(data.value);
          }}
        />
      </Field>
    </>
  );

  const renderButtons = () => (
    <>
      <Button
        disabled={title.trim().length === 0 || description.trim().length === 0}
        appearance="primary"
        onClick={() => {
          toaster.onToastStart("Add Metrics Change Log...");
          props.close();
          const prevLogs = metricsLog ?? [];
          setMetricsChangeLog({
            MetricsChange: [
              {
                title: title,
                time: new Date().toUTCString(),
                description: description,
                author: store.account?.username ?? "Unknown",
              },
              ...prevLogs,
            ],
          })
            .then(() => {
              toaster.onToastSuccess("Metrics change log added successfully.");
              props.close();
            })
            .catch((error) => {
              toaster.onToastFailure(error.message);
              props.close();
            });
        }}
      >
        Add Metrics Change
      </Button>
      <Button
        appearance="secondary"
        onClick={() => {
          props.close();
        }}
      >
        Cancel
      </Button>
    </>
  );

  return (
    <BasicDialog
      isOpen={props.isOpen}
      close={props.close}
      title={"Add Metrics Change"}
      renderContent={renderContent}
      renderButtons={renderButtons}
    />
  );
};
